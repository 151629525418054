import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { isAdminOrManagerSelector } from 'atoms/user';
import CommonInputCustom from 'components/common/CommonInputCustom/CommonInputCustom';
import GoBackButton from 'components/common/GoBackButton/GoBackButton';
import { PageContainer } from 'components/common/PageContainer';
import SelectCustom from 'components/common/SelectCustom/SelectCustom';
import TextFieldCustom from 'components/common/TextFieldCustom/TextFieldCustom';
import { AlarmBoundDirection } from 'constants/AlarmBoundDirection';
import { AlarmSeverityLevel } from 'constants/AlarmSeverityLevel';
import { ALARMS } from 'constants/routes';
import get from 'lodash/get';
import IsEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import keys from 'lodash/keys';
import AlarmTrigger from 'models/AlarmTrigger';
import GenericData from 'models/GenericData';
import Measurer from 'models/Measurer';
import MeasurerLink from 'models/MesurerLink';
import { useCallback, useEffect, useState } from 'react';
import { useValidation } from 'react-class-validator';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import AlarmTriggerRequest from 'services/commons/AlarmTriggerRequest';
import AlarmTriggerService from 'services/commons/AlarmTriggerService';
import MeasurerService from 'services/commons/MeasurerService';

import { floatToText, textToFloat } from 'utils/floatToTextConverters';
import AutocompleteCustom from '../../../components/common/AutocompleteCustom';

export default function AlarmTriggerDetails(): JSX.Element {
  const { id: alarmTriggerId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isAdminOrManager = useRecoilValue<Boolean>(isAdminOrManagerSelector);
  const [alarmTriggerState, setAlarmTriggerState] = useState<AlarmTrigger>(
    AlarmTrigger.newEmpty,
  );
  const [thresholdValueState, setThresholdValueState] = useState<GenericData>(
    GenericData.newEmpty,
  );
  const [hysteresisValueState, setHysteresisValueState] = useState<GenericData>(
    GenericData.newEmpty,
  );
  const [isAlarmSynchronizedOnSave, setIsAlarmSynchronizedOnSave] =
    useState<boolean>(false);
  const measurerService = new MeasurerService();
  const alarmTriggerService = new AlarmTriggerService();
  const alarmTriggerRequest = new AlarmTriggerRequest();
  const [validateForm, validationErrors] = useValidation(AlarmTrigger);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [validateThresholdValueForm, validationThresholdValueErrors] =
    useValidation(GenericData);
  const [validateHysteresisValueForm, validationHysteresisValueErrors] =
    useValidation(GenericData);
  const [
    isErrorBetweenThresholdAndHysteresis,
    setIsErrorBetweenThresholdAndHysteresis,
  ] = useState<boolean>(false);

  const { data: measurersList } = useQuery('measurersLink', () =>
    measurerService
      .getAll()
      .then((res) => res.map((m: Measurer) => m.toMeasurerLink())),
  );

  const getCurrentAlarmTrigger = ({ queryKey }: any) => {
    const alarmTriggerId = queryKey[1];
    alarmTriggerService
      .getOne(alarmTriggerId)
      .then((alarmTrigger: AlarmTrigger) => {
        const alarmId = alarmTrigger?.measurer?.id;
        const measurerLink = measurersList?.find(
          (measurerLink) => measurerLink.id === alarmId,
        );
        const newAlarmTrigger = {
          ...alarmTrigger,
          measurer: measurerLink as MeasurerLink,
        };
        setAlarmTriggerState(newAlarmTrigger);
        setHysteresisValueState(alarmTrigger.hysteresisValue);
        setThresholdValueState(alarmTrigger.thresholdValue);
        return alarmTrigger;
      });
  };

  useQuery({
    queryKey: alarmTriggerId && ['currentAlarmTrigger', alarmTriggerId],
    queryFn: getCurrentAlarmTrigger,
    cacheTime: 5,
    enabled: !!alarmTriggerId && !!measurersList,
  });

  useEffect(() => {
    setAlarmTriggerState({
      ...alarmTriggerState,
      hysteresisValue: hysteresisValueState,
      thresholdValue: thresholdValueState,
    });
  }, [hysteresisValueState, thresholdValueState]);

  const getKeyNameOfSinglePropertyObject = (updatedPropertyObject: any) => {
    return keys(updatedPropertyObject)[0];
  };

  const handleChange = (updatedPropertyObject: any) => {
    const name = getKeyNameOfSinglePropertyObject(updatedPropertyObject);
    const value = get(updatedPropertyObject, name);
    setAlarmTriggerState({
      ...alarmTriggerState,
      ...updatedPropertyObject,
    });
    if (IsEqual(name, 'measurer') && value) {
      const measurer = value as Measurer;
      setThresholdValueState({
        ...thresholdValueState,
        fieldType: measurer.measurementDataType,
      });
      setHysteresisValueState({
        ...hysteresisValueState,
        fieldType: measurer.measurementDataType,
      });
    }
  };

  const handleCheckChange = () => {
    setIsAlarmSynchronizedOnSave(!isAlarmSynchronizedOnSave);
  };

  const handleThresholdValueChange = (updatedPropertyObject: any) => {
    const name = getKeyNameOfSinglePropertyObject(updatedPropertyObject);
    const value = get(updatedPropertyObject, name);
    if (!isNull(value)) {
      updatedPropertyObject[name] = parseFloat(value);
    }
    setThresholdValueState({
      ...thresholdValueState,
      ...updatedPropertyObject,
    });
  };

  const handleHysteresisValueChange = (updatedPropertyObject: any) => {
    const name = getKeyNameOfSinglePropertyObject(updatedPropertyObject);
    const value = get(updatedPropertyObject, name);
    if (!isNull(value)) {
      updatedPropertyObject[name] = parseFloat(value);
    }
    setHysteresisValueState({
      ...hysteresisValueState,
      ...updatedPropertyObject,
    });
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    alarmTriggerRequest.withSynchronizeAlarm(isAlarmSynchronizedOnSave);
    if (await validateForm(alarmTriggerState)) {
      alarmTriggerService
        .upsertWithParameters(alarmTriggerState, alarmTriggerRequest)
        .then(() => {
          navigate(ALARMS);
        });
    }
  };

  const handleErrorBetweenThresholdAndHysteresis = () => {
    if (
      alarmTriggerState.boundDirection === AlarmBoundDirection.LOWER_BOUND &&
      !isNil(thresholdValueState.fieldValue) &&
      !isNil(hysteresisValueState.fieldValue) &&
      !isNil(alarmTriggerState.boundDirection) &&
      !isNil(alarmTriggerState.severityLevel)
    ) {
      setIsErrorBetweenThresholdAndHysteresis(
        thresholdValueState.fieldValue < hysteresisValueState.fieldValue,
      );
    }
    if (
      alarmTriggerState.boundDirection === AlarmBoundDirection.UPPER_BOUND &&
      !isNil(thresholdValueState.fieldValue) &&
      !isNil(hysteresisValueState.fieldValue) &&
      !isNil(alarmTriggerState.boundDirection) &&
      !isNil(alarmTriggerState.severityLevel)
    ) {
      setIsErrorBetweenThresholdAndHysteresis(
        thresholdValueState.fieldValue > hysteresisValueState.fieldValue,
      );
    }
  };

  useEffect(() => {
    handleErrorBetweenThresholdAndHysteresis();
  }, [
    alarmTriggerState.boundDirection,
    alarmTriggerState.severityLevel,
    thresholdValueState.fieldValue,
    hysteresisValueState.fieldValue,
  ]);

  const resetValue = useCallback(() => {
    setAlarmTriggerState({
      ...alarmTriggerState,
      measurer: undefined,
    });
  }, [alarmTriggerState]);

  useEffect(() => {
    setDisabled(alarmTriggerState.measurer === undefined);
  }, [alarmTriggerState.measurer]);

  return (
    <PageContainer
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {t('navigationMenu.alarmTriggerDetails')}
          </Typography>
        </Box>
      }
    >
      <GoBackButton />
      <Container
        sx={{
          mt: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <AutocompleteCustom
              options={measurersList || ([] as any)}
              label={t('alarmTrigger.measurer')!}
              value={(alarmTriggerState?.measurer as any) || undefined}
              onChange={(newValue: any) => {
                handleChange({ measurer: newValue });
              }}
              disabled={!isAdminOrManager}
              resetValue={resetValue}
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'triggerAlarm'}
                    checked={isAlarmSynchronizedOnSave}
                    onChange={handleCheckChange}
                  />
                }
                label={t('alarmTrigger.synchronize')}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={thresholdValueState}
              editedProperty={'fieldValue'}
              labelBaseName={'alarmTrigger.thresholdValue'}
              validateFunction={validateThresholdValueForm}
              errorsArray={validationThresholdValueErrors}
              updateFunction={handleThresholdValueChange}
              valueToFieldFormatter={floatToText}
              fieldToValueFormatter={textToFloat}
              updateOnBlur={true}
            >
              <TextFieldCustom type={'number'} />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={hysteresisValueState}
              editedProperty={'fieldValue'}
              labelBaseName={'alarmTrigger.hysteresisValue'}
              validateFunction={validateHysteresisValueForm}
              errorsArray={validationHysteresisValueErrors}
              updateFunction={handleHysteresisValueChange}
              valueToFieldFormatter={floatToText}
              fieldToValueFormatter={textToFloat}
              updateOnBlur={true}
            >
              <TextFieldCustom type={'number'} />
            </CommonInputCustom>
          </Grid>
        </Grid>
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={alarmTriggerState}
              editedProperty={'boundDirection'}
              labelBaseName={'alarmTrigger'}
              updateFunction={handleChange}
              errorsArray={validationErrors}
            >
              <SelectCustom<AlarmBoundDirection>
                values={
                  Object.keys(AlarmBoundDirection) as [AlarmBoundDirection]
                }
                valueToLabel={(key: AlarmBoundDirection) =>
                  t(
                    `alarmBoundDirection.${AlarmBoundDirection[
                      key as keyof typeof AlarmBoundDirection
                    ]
                      .toString()
                      .toLowerCase()}`,
                  )
                }
                valueToId={(key: AlarmBoundDirection) => key}
              />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={alarmTriggerState}
              editedProperty={'severityLevel'}
              labelBaseName={'alarmTrigger'}
              updateFunction={handleChange}
              errorsArray={validationErrors}
            >
              <SelectCustom<AlarmSeverityLevel>
                values={Object.keys(AlarmSeverityLevel) as [AlarmSeverityLevel]}
                valueToLabel={(key: AlarmSeverityLevel) =>
                  t(
                    `alarmSeverityLevel.${AlarmSeverityLevel[
                      key as keyof typeof AlarmSeverityLevel
                    ]
                      .toString()
                      .toLowerCase()}`,
                  )
                }
                valueToId={(key: AlarmSeverityLevel) => key}
              />
            </CommonInputCustom>
          </Grid>
        </Grid>

        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={alarmTriggerState}
              editedProperty={'notifiedEmails'}
              labelBaseName={'alarm'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={alarmTriggerState}
              editedProperty={'notifiedPhoneNumbers'}
              labelBaseName={'alarm'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
        </Grid>

        {isAdminOrManager && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'right',
              mb: theme.spacing(2),
            }}
          >
            <Button
              sx={{ textTransform: 'none' }}
              variant="contained"
              type="submit"
              onClick={submitForm}
              disabled={disabled || !isErrorBetweenThresholdAndHysteresis}
            >
              {t('navigation.validate')}
            </Button>
          </Box>
        )}
      </Container>
    </PageContainer>
  );
}
