import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Theme,
} from '@mui/material';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import { useCallback } from 'react';

interface PropsSelectedCustom<TValue> {
  value?: any;
  label?: string;
  name?: string;
  values: TValue[];
  valueToId: (value: TValue) => string | number;
  valueToLabel: (value: TValue) => string;
  errors?: any;
  onChange?: any;
  error?: boolean;
  disabled?: boolean;
  onBlur?: any;
  errorProperty?: any;
  validateFunction?: Function;
  sx?: SxProps<Theme>;
}
export default function SelectCustom<TValue>(
  props: PropsSelectedCustom<TValue>,
): JSX.Element {
  const {
    values,
    valueToId,
    name,
    label,
    value,
    valueToLabel,
    onChange,
    errors,
    error,
    disabled,
    onBlur,
    errorProperty,
    validateFunction,
    sx,
  } = props;
  const size = 'small';

  const handleChange = useCallback(
    (e: any) => {
      let id = e.target.value;
      let newValue = find(values, (v) => valueToId(v) === id);
      const newEvent = e;
      newEvent.target.value = newValue;
      onChange(newEvent);
    },
    [onChange],
  );

  const helperTextError = () => {
    const value = errorProperty ? errorProperty : name;
    return errors?.[value!];
  };

  const getIsErrorForCustomErrorProperty = () => {
    return !isNil(errors?.[errorProperty]);
  };

  const handleBlurForCustomErrorProperty = () => {
    if (validateFunction && errorProperty) {
      validateFunction({ [errorProperty]: value![errorProperty] }, [
        errorProperty,
      ]);
    }
  };

  return (
    <FormControl sx={sx ? sx : { minWidth: 'calc(100%)' }}>
      <InputLabel id={name + 'label'} size={size}>
        {label!}
      </InputLabel>
      <Select
        sx={{ backgroundColor: '#fff' }}
        error={errorProperty ? getIsErrorForCustomErrorProperty() : error!}
        size={size}
        id={name}
        labelId={name + 'label'}
        onChange={handleChange}
        disabled={disabled}
        name={name}
        label={label!}
        defaultValue={null}
        onBlur={
          errorProperty && validateFunction
            ? handleBlurForCustomErrorProperty
            : onBlur
        }
        value={value ? valueToId(value) : ''}
      >
        {values?.map((v: any) => (
          <MenuItem key={valueToId(v)} value={valueToId(v)}>
            {valueToLabel(v)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>{helperTextError()}</FormHelperText>
    </FormControl>
  );
}
