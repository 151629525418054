import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MEASUREMENT } from '../../constants/routes';
import { Structure } from '../../models/Structure';
import { generateStructureFullName } from '../../utils/MeasurerUtils';
import ButtonCustom from '../common/ButtonCustom/ButtonCustom';

interface MapHeaderBannerProps {
  sitesCount: number | undefined;
  structuresCount: number | undefined;
  alertsCount: number | undefined;
  structuresArrays: Array<Structure> | undefined;
  structureSelector: Dispatch<SetStateAction<Structure | null>>;
  structureSelected: any;
}

export const MapHeaderBanner = ({
  sitesCount,
  structuresCount,
  alertsCount,
  structuresArrays,
  structureSelector,
  structureSelected,
}: MapHeaderBannerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [lastMeasurementPath, setLastMeasurementPath] = useState<string>('');
  const [lastMeasurementQuery, setLastMeasurementQuery] = useState<string>('');

  useEffect(() => {
    try {
      const localStorageMeasurementPathObjectJson =
        localStorage.getItem('measurementPath');
      if (localStorageMeasurementPathObjectJson) {
        const localStorageMeasurementPathObject = JSON.parse(
          localStorageMeasurementPathObjectJson,
        );
        if (
          localStorageMeasurementPathObject &&
          localStorageMeasurementPathObject.pathname
        ) {
          setLastMeasurementPath(localStorageMeasurementPathObject.pathname);
        }
        if (
          localStorageMeasurementPathObject &&
          localStorageMeasurementPathObject.search
        ) {
          setLastMeasurementQuery(localStorageMeasurementPathObject.search);
        }
      }
    } catch (error) {
      console.error('Error parsing localStorage data:', error);
    }
  }, []);

  const goToLastMeasurement = useCallback(() => {
    if (lastMeasurementQuery) {
      navigate(`${lastMeasurementPath}${lastMeasurementQuery}`);
    } else {
      navigate(`${MEASUREMENT}`);
    }
  }, [lastMeasurementPath, lastMeasurementQuery]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        gap: 5,
      }}
    >
      <Typography
        sx={{
          color: 'black',
          fontWeight: 'bold',
          width: 'max-content',
        }}
      >
        {t('mapHeaderBanner.sitesCount', { sitesCount: sitesCount })},{' '}
        {t('mapHeaderBanner.structuresCount', {
          structuresCount: structuresCount,
        })}
        , {t('mapHeaderBanner.alertsCount', { alertsCount: alertsCount })}
      </Typography>
      <FormControl
        sx={{
          width: '250px',
        }}
      >
        <Autocomplete
          disablePortal
          size={'small'}
          sx={{
            width: '100%',
          }}
          options={structuresArrays || []}
          value={structureSelected || null}
          onChange={(event: any, newValue: Structure | null) => {
            structureSelector(newValue);
          }}
          getOptionLabel={(structure: Structure) =>
            generateStructureFullName(structure, t)
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('mapHeaderBanner.structureSelection')}
            />
          )}
        />
      </FormControl>
      <Box>
        <ButtonCustom
          onClick={() => goToLastMeasurement()}
          label={
            !isEmpty(lastMeasurementQuery)
              ? t('mapHeaderBanner.lastMeasurementSeen')
              : t('mapHeaderBanner.btnNoMeasurementSeen')
          }
        />
      </Box>
    </Box>
  );
};
