import { Box } from '@mui/material';
import { PwaCustomListButton } from 'components/PwaCustomListButton/PwaCustomListButton';

export const PwaCustomList = ({ dataArray, onItemClick }: any) => {
  return (
    <Box
      sx={{
        mt: 4,
      }}
    >
      {dataArray.map((data: any) => {
        return (
          <PwaCustomListButton
            key={data?.id}
            data={data}
            onItemClick={onItemClick}
          />
        );
      })}
    </Box>
  );
};
