import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import i18next from 'i18next';

export default class Document {
  public id: number;
  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('alert.documentId'),
      }),
  })
  public documentId: number;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('alert.name'),
      }),
  })
  public name: string;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('alert.filePath'),
      }),
  })
  public filePath: string;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('alert.type'),
      }),
  })
  public mediaType: string;

  @IsString()
  @IsOptional()
  public width: string;

  @IsString()
  @IsOptional()
  public height: string;
}
