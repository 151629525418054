import { Divider, Grid, useTheme } from '@mui/material';
import { isAdminSelector } from 'atoms/user';
import CommonInputCustom from 'components/common/CommonInputCustom/CommonInputCustom';
import SelectCustom from 'components/common/SelectCustom/SelectCustom';
import TextFieldCustom from 'components/common/TextFieldCustom/TextFieldCustom';
import { LocationType } from 'constants/LocationType';
import isNil from 'lodash/isNil';
import { LocationLink } from 'models/LocationLink';
import { useRecoilValue } from 'recoil';
import GeoLocationForm from '../GeoLocationForm/GeoLocationForm';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ValidationErrorMap<T, U> {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function LocationForm<T>({
  handleChange,
  locationState,
  validationErrors,
  validateForm,
  setLocationState,
  locationList,
  validationErrorsLocationLink,
  validateFormLocationLink,
                                          forcedTranslationKey,
}: {
  handleChange: Function;
  locationState: any;
  validationErrors: ValidationErrorMap<T, keyof T>;
  validateForm: Function;
  setLocationState: Function;
  locationList: LocationLink[];
  validationErrorsLocationLink?: ValidationErrorMap<T, keyof T>;
  validateFormLocationLink?: Function;
  parentLocation?: string;
  forcedTranslationKey?: string;
}): JSX.Element {
  const theme = useTheme();
  const isAdmin = useRecoilValue<Boolean>(isAdminSelector);

  return (
    <>
      <Grid container sx={{ mb: theme.spacing(2) }}>
        <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
          <CommonInputCustom
            editedObject={locationState}
            editedProperty={'locationName'}
            disabled={!isAdmin}
            labelBaseName={'location'}
            validateFunction={validateForm}
            errorsArray={validationErrors}
            updateFunction={handleChange}
          >
            <TextFieldCustom />
          </CommonInputCustom>
        </Grid>
        <Grid item xs={6}>
          {isAdmin && locationState.locationType !== LocationType.COMPANY && (
            <CommonInputCustom
              editedObject={locationState}
              editedProperty={'parentLocation'}
              labelBaseName={'location'}
              updateFunction={handleChange}
              errorsArray={validationErrorsLocationLink}
              forcedTranslationKey={forcedTranslationKey}
            >
              <SelectCustom<LocationLink>
                values={locationList}
                valueToLabel={(location: LocationLink) => location.locationName}
                valueToId={(location: LocationLink) =>
                  isNil(location?.id) ? '' : location.id
                }
                errorProperty={'id'}
                validateFunction={validateFormLocationLink}
              />
            </CommonInputCustom>
          )}
        </Grid>
      </Grid>
      <Grid container sx={{ mb: theme.spacing(2) }}>
        <Divider sx={{ width: '100%', mb: theme.spacing(2) }} />
        <GeoLocationForm
          location={locationState}
          setLocation={setLocationState}
        />
      </Grid>
    </>
  );
}
