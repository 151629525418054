export function generateBreadCrumbs(location: any): any[] {
  if (!location) {
    return [];
  }

  const breadcrumbs: any[] = generateBreadCrumbs(location.parentLocation);

  const path =
    location.hierarchyPath && location.hierarchyPath !== ''
      ? location.hierarchyPath
      : `/locations/${location.id}`;

  breadcrumbs.push({
    id: location.id,
    locationType: location.locationType,
    locationName: location.locationName,
    path,
  });

  return breadcrumbs;
}

export const convertLocationTypeIntoRoutes = (locationType: string): string => {
  switch (locationType) {
    case 'SITE':
      return '/sites';
    case 'COMPANY':
      return '/companies';
    case 'STRUCTURE':
      return '/structures';
    case 'MEASURER':
      return '/measurers';
    case 'MEASURE':
      return '/measures';
    case 'DEVICE':
      return '/devices';
    default:
      return '';
  }
};
