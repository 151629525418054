import {
  CircularProgress,
  Container,
  Typography,
  useTheme,
} from '@mui/material';
import { config } from 'constants/config';
import { includes, isEmpty } from 'lodash';
import { Account } from 'models/Account';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { CRUDService } from 'services/commons/CRUDService';
import { currentUserState } from '../../atoms/user';
import { ADMIN, MANAGER } from '../../constants/authorities';
import User from '../../models/User';

type Props = {
  children?: React.ReactNode;
};

class AccountService extends CRUDService<Account> {
  constructor() {
    super('account');
  }
  newInstance(): Account {
    return new Account();
  }
  getAccount(): Promise<Account> {
    return this.castToInstance(
      this.decorateWithInterceptor(this.apiInstance.get(`${this.endPoint}`)),
    );
  }
}

export const AuthenticationManager = ({ children }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const accountService = new AccountService();
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const { error, data } = useQuery(
    'account',
    () => accountService.getAccount().then((res) => res),
    { retry: false } as any,
  );

  useEffect(() => {
    if (!isEmpty(data)) {
      let userInfo = data as User;
      const isAdmin = includes(userInfo.authorities, ADMIN);
      const isManager = includes(userInfo.authorities, MANAGER);
      const isAdminOrManager = isAdmin || isManager;

      setCurrentUser((oldState) => {
        return {
          ...oldState,
          user: data,
          isAdmin: isAdmin,
          isManager: isManager,
          isAdminOrManager: isAdminOrManager,
        };
      });
    }
  }, [data]);

  if (error) {
    const redirectUrl: string = config.UNAUTHENTICATED_REDIRECT_URL;
    if (!isEmpty(redirectUrl)) {
      window.location.href = redirectUrl;
    }
  }

  return (
    <>
      {isEmpty(currentUser.user) ? (
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress
            sx={{
              margin: theme.spacing(2),
            }}
            size={80}
            thickness={5}
          />
          <Typography variant="h5">
            {t('authentication.checkAuthentication')}
          </Typography>
        </Container>
      ) : (
        children
      )}
    </>
  );
};
