import EditIcon from '@mui/icons-material/Edit';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { FormControlLabel, FormGroup, IconButton, Switch } from '@mui/material';
import { PageContainer } from 'components/common/PageContainer';
import { EDIT, MEASUREMENT, SITES } from 'constants/routes';
import L, { LatLng } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import { map, filter } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import AlarmService from 'services/commons/AlarmService';
import { CustomCardContainerDisplay } from '../../components/CustomCardContainerDisplay/CustomCardContainerDisplay';
import { MapHeaderBanner } from '../../components/MapHeaderBanner/MapHeaderBanner';
import { LocationType } from '../../constants/LocationType';
import { Site } from '../../models/Site';
import { Structure } from '../../models/Structure';
import LocationRequest from '../../services/commons/LocationRequest';
import { LocationService } from '../../services/commons/LocationService';

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const defaultIcon = (L.Marker.prototype.options.icon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  }));

  const selectedIcon = (L.Marker.prototype.options.icon = L.icon({
    iconUrl: '/assets/marker-selected-icon.png',
    shadowUrl: iconShadow,
  }));

  const siteService = new LocationService<Site>();
  let SiteLocationRequest = new LocationRequest().withLocationType(
    LocationType.SITE,
  );

  const structureService = new LocationService<Structure>();
  let locationRequest = new LocationRequest().withLocationType(
    LocationType.STRUCTURE,
  );

  const alarmService = new AlarmService();

  const [mapCenter] = useState<LatLng>(new LatLng(48.115929, -1.7234628));

  const [showSatellite, setShowSatellite] = useState<boolean>(true);

  const [selectedStructure, setSelectedStructure] = useState<any>();

  const mapRef: any = useRef(null);
  const popupRefs = useRef<Map<number, L.Popup>>(new Map());

  const { data: geolocationSites, refetch: refetchSites }: any = useQuery(
    'sites',
    () =>
      siteService
        .getFilteredLocationRequest<Site>(SiteLocationRequest)
        .then((res) => {
          return map(res);
        }),
  );

  const { data: structures }: any = useQuery(
    ['structures', locationRequest],
    () =>
      structureService
        .getFilteredLocationRequest<Structure>(locationRequest)
        .then((res) => res),
    { refetchOnMount: 'always' } as any,
  );

  const { data: alarms }: any = useQuery(
    'alarms',
    () => alarmService.getAll().then((res) => filter(res, (alarm)=> alarm.alarmState !== 'OK_NO_ALARM')),
    { refetchOnMount: 'always' } as any,
  );

  const centerMapViewShowingAllPoints = useCallback(() => {
    if (mapRef.current && structures && structures.length > 0) {
      closeAllPopups();

      if (structures.length > 1) {
        const bounds =
          structures &&
          structures.reduce(
            (acc: any, marker: any) =>
              acc.extend([marker.geoLocation.yLat, marker.geoLocation.xLon]),
            L.latLngBounds([
              [48.115929, -1.7234628],
              [48.115929, -1.7234628],
            ]),
          );

        mapRef.current.fitBounds(bounds);
      } else {
        mapRef.current.setView(
          new LatLng(
            structures[0].geoLocation.yLat,
            structures[0].geoLocation.xLon,
          ),
          9,
        );
      }
    }
  }, [structures]);

  const centerMapViewShowingSelectedPoint = useCallback(() => {
    if (mapRef.current && selectedStructure) {
      const { yLat, xLon } = selectedStructure.geoLocation;

      mapRef.current.setView(new LatLng(yLat, xLon), 9);
    }
  }, [selectedStructure]);

  const closeAllPopups = () => {
    if (mapRef.current) {
      mapRef.current.eachLayer((layer: { closePopup: () => void }) => {
        if (layer instanceof L.Marker) {
          layer.closePopup();
        }
      });
    }
  };

  const handleItemClick = useCallback(
    (siteId: any) => {
      navigate(`${SITES}/${EDIT}/${siteId}`);
    },
    [navigate],
  );

  const gotoMeasure = useCallback(
    (siteId: any) => {
      navigate(`${MEASUREMENT}?site=${siteId}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (selectedStructure) {
      centerMapViewShowingSelectedPoint();

      const popup = popupRefs.current.get(selectedStructure.id);

      if (popup) {
        popup.openOn(mapRef.current);
      }
    } else {
      centerMapViewShowingAllPoints();
    }
  }, [
    selectedStructure,
    structures,
    centerMapViewShowingAllPoints,
    centerMapViewShowingSelectedPoint,
  ]);

  useEffect(() => {
    refetchSites();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSatellite(event.target.checked);
  };

  return (
    <PageContainer
      home
      bgColor="transparent"
      elevation
      headerContent={
        <MapHeaderBanner
          sitesCount={geolocationSites && geolocationSites.length}
          alertsCount={alarms && alarms.length}
          structuresCount={structures && structures.length}
          structuresArrays={structures}
          structureSelector={setSelectedStructure}
          structureSelected={selectedStructure}
        />
      }
    >
      <CustomCardContainerDisplay
        sx={{
          height: '100vh',
        }}
      >
        <div style={{ height: '100vh', position: 'relative' }}>
          <MapContainer
            center={mapCenter}
            zoom={9}
            style={{ height: '100%' }}
            ref={mapRef}
          >
            {!showSatellite && (
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
            )}
            {showSatellite && (
              <TileLayer
                url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                maxZoom={20}
                subdomains={['mt1', 'mt2', 'mt3']}
              />
            )}

            {structures &&
              structures.map((structure: any) => (
                <Marker
                  key={structure.id}
                  icon={
                    structure.id === selectedStructure?.id
                      ? selectedIcon
                      : defaultIcon
                  }
                  position={[
                    structure.geoLocation.yLat,
                    structure.geoLocation.xLon,
                  ]}
                  eventHandlers={{
                    click: () => {
                      setSelectedStructure(structure);
                    },
                  }}
                >
                  <Popup
                    offset={[10, 0]}
                    ref={(popup) => {
                      if (popup) {
                        popupRefs.current.set(structure.id, popup);
                      }
                    }}
                  >
                    <div>
                      <h3>{structure.locationName}</h3>
                      <IconButton onClick={() => gotoMeasure(structure.id)}>
                        <SsidChartIcon />
                      </IconButton>
                      <IconButton onClick={() => handleItemClick(structure.id)}>
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Popup>
                </Marker>
              ))}
          </MapContainer>
          <FormGroup
            style={{
              padding: '0 4px 0 10px',
              background: 'white',
              position: 'absolute',
              top: 10,
              right: 10,
              zIndex: 1000,
              borderRadius: 4,
              border: '1px solid #ccc',
            }}
          >
            <FormControlLabel
              style={{ marginRight: 6 }}
              control={
                <Switch checked={showSatellite} onChange={handleChange} />
              }
              label={t('googleMap.satellite')}
            />
          </FormGroup>
        </div>
      </CustomCardContainerDisplay>
    </PageContainer>
  );
}

export default Home;
