import { notificationsAtom } from 'atoms/notificationsAtom';
import { forEach, get, takeRight } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { usePrevious } from 'utils/usePrevious';
import { getNotificationTranslation } from './translationHelper';

function NotificationManager() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const notificationsList = useRecoilValue(notificationsAtom);
  const lastNotifications = usePrevious(notificationsList);

  useEffect(() => {
    const lastNotificationLength = get(lastNotifications, 'length', 0);
    const notificationLength = get(notificationsList, 'length', 0);
    if (notificationLength > lastNotificationLength) {
      const diff = notificationLength - lastNotificationLength;
      const newNotifications = takeRight(notificationsList, diff);
      forEach(newNotifications, (item) => {
        enqueueSnackbar(getNotificationTranslation(t, item));
      });
    }
  }, [notificationsList.length]);

  return <></>;
}

export default NotificationManager;
