import columnGenerator from 'components/DataGridCustom/ColumnGenerator';
import DataGridCustom from 'components/DataGridCustom/DataGridCustom';
import MeasurementStatistic from 'models/MeasurementStatistic';
import moment from 'moment';

function Chronicles({
  measurements,
}: {
  measurements: MeasurementStatistic[];
}): JSX.Element {
  const columns = [
    columnGenerator('measurement', 'deviceName'),
    columnGenerator('measurement', 'measurer'),
    {
      ...columnGenerator('measurement', 'measurementDate'),
      type: 'datetime',
      valueFormatter: (params: any) =>
        moment(params?.value).format('DD/MM/YYYY hh:mm'),
    },
    { ...columnGenerator('measurement', 'measurerValue'), type: 'number' },
  ];

  return (
    <DataGridCustom
      columns={columns}
      rows={measurements || []}
      getRowId={(row: any) =>
        `${row.deviceName}-${row.measurer}-${row.measurementDate}`
      }
    />
  );
}

export default Chronicles;
