class OfflineService {
  protected callStack: any = [];

  public callAPI = async (thisObject: any, callFunction: any, params: any) => {
    if (navigator.onLine) {
      await callFunction.call(thisObject, params);
    } else {
      this.callStack.push({ thisObject, fnc: callFunction, params: params });
    }
  };

  public launchAll = async () => {
    await Promise.allSettled(
      this.callStack.map((item: any) =>
        item.fnc.call(item.thisObject, item.params),
      ),
    );
    this.callStack = [];
  };
}

export const offlineService = new OfflineService();
