import { IsNotEmpty } from 'class-validator';
import i18next from 'i18next';

export default class DeviceLink {
  location(location: any) {
    throw new Error('Method not implemented.');
  }

  public id: number;

  @IsNotEmpty({
    message: () => i18next.t('validator.selectDeviceLink'),
  })
  public deviceIdentifier: string;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('device.name'),
      }),
  })
  public deviceName: string;

  constructor() {
    this.deviceIdentifier = '';
    this.deviceName = '';
  }
}
