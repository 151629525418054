import { Box, Paper } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import { SCREEN_HEIGHT } from '../../../constants/ScreenHeight';
import { CustomBreadcrumb } from '../../CustomBreadcrumb/CustomBreadcrumb';
import { NavDrawer } from '../NavDrawer/NavDrawer';

export const PageContainer = ({
  home,
  children,
  bgColor,
  elevation,
  headerContent,
  breadCrumbs,
}: {
  home?: boolean;
  headerContent?: React.ReactNode;
  children?: React.ReactNode;
  bgColor?: string;
  elevation?: boolean;
  breadCrumbs?: any;
}) => {
  const [scrollPosition, setPosition] = useState(0);

  const handleScroll = (e: SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement;
    setPosition(target.scrollTop);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
      }}
    >
      <NavDrawer
        home={home}
        content={headerContent}
        scrollPosition={scrollPosition}
      />
      <CustomBreadcrumb breadCrumbs={breadCrumbs} />
      <Paper
        onScroll={handleScroll}
        variant={elevation ? 'elevation' : 'outlined'}
        elevation={0}
        sx={{
          border: 'none',
          height: `calc(100vh - ${SCREEN_HEIGHT.HEADER}px)`,
          flexGrow: 1,
          overflowX: 'hidden',
          overflowY: home ? 'hidden' : 'unset',
          backgroundColor: bgColor && bgColor,
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};
