import { Box, Typography, useTheme } from '@mui/material';
import { isAdminOrManagerSelector } from 'atoms/user';
import ActionColumnGenerator from 'components/DataGridCustom/ActionColumnGenerator';
import columnGenerator from 'components/DataGridCustom/ColumnGenerator';
import DataGridCustom from 'components/DataGridCustom/DataGridCustom';
import { LinkCustom } from 'components/common/LinkCustom/LinkCustom';
import { PageContainer } from 'components/common/PageContainer';
import { ADD, ALARMS, EDIT } from 'constants/routes';
import { concat } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import AlarmTriggerService from 'services/commons/AlarmTriggerService';
import Measurer from '../../models/Measurer';
import MeasurerLink from '../../models/MesurerLink';
import MeasurerService from '../../services/commons/MeasurerService';
import { handleUnit } from '../../utils/UnitsConverter';

function AlarmTriggers() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const measurerService = new MeasurerService();
  const alarmTriggerService = new AlarmTriggerService();
  const isAdminOrManager = useRecoilValue<Boolean>(isAdminOrManagerSelector);

  const { data: measurersList } = useQuery('measurersLink', () =>
    measurerService
      .getAll()
      .then((res) => res.map((m: Measurer) => m.toMeasurerLink())),
  );

  const {
    isLoading,
    data: alarmTriggers,
    refetch: refetchAlarms,
  } = useQuery(
    'alarmTrigger',
    () =>
      alarmTriggerService.getAll().then((res) => {
        return res.map((alarm) => {
          return {
            ...alarm,
            measurer: measurersList?.find(
              (measurerLink: MeasurerLink) =>
                measurerLink.id === alarm?.measurer?.id,
            ),
          };
        });
      }),
    { refetchOnMount: 'always', enabled: !!measurersList },
  );

  const deleteClickHandler = async (itemId: number) => {
    await alarmTriggerService.delete(itemId);
    await refetchAlarms();
  };

  let columns = [
    {
      ...columnGenerator('alarmTrigger', 'device'),
      valueGetter: (value: any) => {
        return value.row.measurer.deviceName;
      },
    },
    {
      ...columnGenerator('alarmTrigger', 'measurer'),
      valueGetter: (value: any) => {
        const measurerLabel = value.row.measurer.measurerLabel;
        return measurerLabel
          ? measurerLabel
          : t(
              'measurementType.' +
                value.row.measurer.measurementType.toLowerCase(),
            );
      },
    },
    {
      ...columnGenerator('alarmTrigger', 'thresholdValue.fieldValue', 1),
      valueGetter: (value: any) => {
        const type = value?.row?.measurer?.measurementUnit;
        return value.row.thresholdValue.fieldValue + handleUnit(type);
      },
    },
    {
      ...columnGenerator('alarmTrigger', 'hysteresisValue.fieldValue', 1),
      valueGetter: (value: any) => {
        const type = value?.row?.measurer?.measurementUnit;
        return value.row.hysteresisValue.fieldValue + handleUnit(type);
      },
    },
    {
      ...columnGenerator('alarmTrigger', 'boundDirection'),
      valueGetter: (value: any) => {
        return t(
          `alarmBoundDirection.${value.row.boundDirection.toLowerCase()}`,
        );
      },
    },
    {
      ...columnGenerator('alarmTrigger', 'severityLevel'),
      valueGetter: (value: any) => {
        return t(`alarmSeverityLevel.${value.row.severityLevel.toLowerCase()}`);
      },
    },
  ];
  if (isAdminOrManager) {
    const columnAction = [...ActionColumnGenerator(deleteClickHandler)];
    columns = concat(columns, columnAction as any);
  }

  const sortModel = [{ field: 'measurer', sort: 'asc' }];

  const filterModel = state?.measurer
    ? [{ field: 'measurer', operator: 'contains', value: state.measurer }]
    : [];
  const handleRowClick = (clickedRow: any) => {
    navigate(`${ALARMS}/${EDIT}/${clickedRow.id}`);
  };

  return (
    <PageContainer
      bgColor={theme.palette.background.default}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography color={'primary'} variant="h6">
            {t('navigationMenu.alarms')}
          </Typography>
          {isAdminOrManager && (
            <LinkCustom to={`${ALARMS}/${ADD}`}>{t('alarm.add')}</LinkCustom>
          )}
        </Box>
      }
    >
      <DataGridCustom
        columns={columns}
        rows={alarmTriggers || []}
        loading={isLoading}
        sortModel={sortModel}
        onRowClick={handleRowClick}
        getRowId={(row: any) => row.id}
        filterModel={filterModel}
      />
    </PageContainer>
  );
}

export default AlarmTriggers;
