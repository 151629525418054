import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MeasurementType } from 'constants/MeasurementType';
import { cloneDeep, find, get, set } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MeasurerService from 'services/commons/MeasurerService';
import { offlineService } from 'services/commons/OfflineService';
import DeviceService from '../../services/commons/DeviceService';

type CustomCounterProps = {
  value: number;
  onChange: (value: number) => void;
  minValue?: number;
  maxValue?: number;
};

const CustomButton = ({
  onClick,
  icon,
  variantSelected = false,
}: {
  onClick: () => void;
  icon: React.ReactNode;
  variantSelected?: boolean;
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variantSelected ? 'contained' : 'outlined'}
    >
      {icon}
    </Button>
  );
};

function roundedWell(value: number): number {
  return Math.round(value * 10) / 10;
}

const CustomCounter = ({
  value,
  onChange,
  minValue,
  maxValue,
}: CustomCounterProps) => {
  const onInternalChange = (value: number) => {
    let realValue = value;
    if (minValue !== undefined && !isNaN(minValue)) {
      realValue = Math.max(minValue, value);
    }
    if (maxValue !== undefined && !isNaN(maxValue)) {
      realValue = Math.max(maxValue, value);
    }
    onChange(realValue);
  };

  return (
    <>
      <ButtonGroup>
        <CustomButton
          onClick={() => onInternalChange(roundedWell(value - 0.1))}
          icon={<RemoveIcon />}
        />
        <TextField
          value={value}
          onChange={(e) => onInternalChange(parseInt(e.target.value) || 0)}
        />
        <CustomButton
          onClick={() => onInternalChange(roundedWell(value + 0.1))}
          icon={<AddIcon />}
        />
      </ButtonGroup>
    </>
  );
};

const RestartIconButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <CustomButton
      onClick={onClick}
      icon={<RestartAltIcon />}
      variantSelected={true}
    />
  );
};

function PwaMeasurersDetails({ measureData }: { measureData: any }) {
  const { t } = useTranslation();

  const [cadenceValue, setCadenceValue] = useState<number>(0);
  const deviceService = new DeviceService();
  const deviceId = measureData.id;

  const [calibrationDate, setCalibrationDate] = useState<Moment | null>(
    moment(),
  );
  const [calibrationValue, setCalibrationValue] = useState<number>(0);
  const measurerService = new MeasurerService();

  const waterLevelMeasurer = useMemo(() => {
    let waterDepthMeasurer = find(measureData.measurers, {
      measurementType: MeasurementType.WATER_DEPTH_RELATIVE,
    });
    if (waterDepthMeasurer) return waterDepthMeasurer;
    waterDepthMeasurer = find(measureData.measurers, {
      measurementType: MeasurementType.WATER_HEIGHT_RELATIVE,
    });
    return waterDepthMeasurer;
  }, [measureData]);

  useEffect(() => {
    if (waterLevelMeasurer) {
      let property =
        'specificParameters.REFERENCE_WATER_DEPTH_METER.fieldValue';
      if (
        waterLevelMeasurer.measurementType ===
        MeasurementType.WATER_HEIGHT_RELATIVE
      ) {
        property = 'specificParameters.REFERENCE_WATER_HEIGHT_METER.fieldValue';
      }
      setCalibrationDate(
        moment(
          waterLevelMeasurer?.specificParameters.CALIBRATION_DATE.fieldValue,
        ),
      );
      setCalibrationValue(get(waterLevelMeasurer, property));
    }
  }, [waterLevelMeasurer]);

  const handleDeviceReboot = async (deviceId: number) => {
    await offlineService.callAPI(
      deviceService,
      deviceService.rebootDevice,
      deviceId,
    );
  };

  const updateCalibration = async () => {
    if (calibrationDate && !isNaN(calibrationValue)) {
      let clone = cloneDeep(waterLevelMeasurer);
      let property =
        'specificParameters.REFERENCE_WATER_DEPTH_METER.fieldValue';
      if (
        waterLevelMeasurer.measurementType ===
        MeasurementType.WATER_HEIGHT_RELATIVE
      ) {
        property = 'specificParameters.REFERENCE_WATER_HEIGHT_METER.fieldValue';
      }
      set(
        clone,
        'specificParameters.CALIBRATION_DATE.fieldValue',
        calibrationDate,
      );
      set(clone, property, calibrationValue);
      await offlineService.callAPI(
        measurerService,
        measurerService.update,
        clone,
      );
      await offlineService.callAPI(measurerService, measurerService.calibrate, {
        measurerId: waterLevelMeasurer.id,
        dateString: moment
          .tz(calibrationDate, moment.tz.guess())
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      });
    }
  };

  const handleCadence = (value: number) => {
    setCadenceValue(value);
  };

  const handleUpdateFrequency = async () => {
    let clone = cloneDeep(measureData);
    set(clone, 'emittingFrequencyHours', cadenceValue);
    await offlineService.callAPI(deviceService, deviceService.update, clone);
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '300px',
          margin: 'auto',
          gap: 2,
        }}
      >
        <Card>
          <CardContent>
            <Typography
              sx={{
                fontSize: '1.2srem',
                fontWeight: 'bold',
              }}
            >
              {t('pwa.updateFrequency')}
            </Typography>
            <CustomCounter
              value={cadenceValue}
              minValue={0}
              onChange={handleCadence}
            />
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={() => handleUpdateFrequency()}>
              {t('navigation.validate')}
            </Button>
          </CardActions>
        </Card>
        {waterLevelMeasurer && (
          <Card>
            <CardContent>
              <Typography
                sx={{
                  fontSize: '1.2srem',
                  fontWeight: 'bold',
                }}
              >
                {t('pwa.calibration_date') ?? ''}
              </Typography>
              <DateTimePicker
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
                value={calibrationDate}
                onChange={(newValue) => setCalibrationDate(newValue)}
              />
              <CustomCounter
                value={calibrationValue}
                onChange={(value) => setCalibrationValue(value)}
              />
            </CardContent>
            <CardActions>
              <Button variant="contained" onClick={() => updateCalibration()}>
                {t('navigation.validate')}
              </Button>
            </CardActions>
          </Card>
        )}

        <RestartIconButton onClick={() => handleDeviceReboot(deviceId)} />
      </Box>
    </div>
  );
}

export default PwaMeasurersDetails;
