import { IsEnum, IsNotEmpty } from 'class-validator';
import { AlarmBoundDirection } from 'constants/AlarmBoundDirection';
import { AlarmSeverityLevel } from 'constants/AlarmSeverityLevel';
import i18next from 'i18next';
import GenericData from 'models/GenericData';
import MeasurerLink from 'models/MesurerLink';

export default class AlarmTrigger {
  public id: number;
  public measurer?: MeasurerLink;
  public thresholdValue: GenericData;
  public hysteresisValue: GenericData;
  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('alarmTrigger.boundDirection'),
      }),
  })
  @IsEnum(Object.keys(AlarmBoundDirection))
  public boundDirection: AlarmBoundDirection;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('alarmTrigger.alarmSeverityLevel'),
      }),
  })
  @IsEnum(Object.keys(AlarmSeverityLevel))
  public severityLevel: AlarmSeverityLevel;

  static newEmpty(): AlarmTrigger {
    let alarmTrigger = new AlarmTrigger();
    alarmTrigger.boundDirection = AlarmBoundDirection.UPPER_BOUND;
    alarmTrigger.severityLevel = AlarmSeverityLevel.MINOR;
    alarmTrigger.measurer = new MeasurerLink();
    return alarmTrigger;
  }
}
