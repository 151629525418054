import { IsEnum, IsNotEmpty } from 'class-validator';
import i18next from 'i18next';
import { LocationType } from '../constants/LocationType';

export class LocationLink {
  @IsNotEmpty({
    message: () => i18next.t('validator.selectLocationLink'),
  })
  public id: number;

  @IsNotEmpty()
  @IsEnum(Object.keys(LocationType))
  public locationType: LocationType;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('location.locationName'),
      }),
  })
  public locationName: string;

  public parentLocation:LocationLink;

  constructor() {
    this.locationType = LocationType.COMPANY;
    this.locationName = '';
  }
}
