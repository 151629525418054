import { AxiosResponse } from 'axios';
import { LOCATION } from 'constants/endPoint';
import { get } from 'lodash';
import { Location } from 'models/Location';
import { REQUEST_TYPE } from 'utils/RequestType';
import { CRUDService } from './CRUDService';
import LocationRequest from './LocationRequest';

export class LocationService<
  TGenericLocationDTO extends Location,
> extends CRUDService<TGenericLocationDTO> {
  constructor() {
    super(LOCATION);
  }

  createInstanceFromDataObject<TGenericLocationDTO extends Location>(
    data: TGenericLocationDTO,
  ): TGenericLocationDTO {
    const objectInstance = this.newInstance();
    return Object.assign(objectInstance, data);
  }

  async castToInstance<TGenericLocationDTO extends Location>(
    result: Promise<AxiosResponse>,
  ): Promise<TGenericLocationDTO> {
    const dataObject = await get(await result, 'data', {});
    return this.createInstanceFromDataObject(dataObject);
  }

  async castToInstanceArray<TGenericLocationDTO extends Location>(
    result: Promise<AxiosResponse>,
  ): Promise<TGenericLocationDTO[]> {
    let dataObjectList = await get(await result, 'data', []);
    if (dataObjectList.hasOwnProperty('elements')) {
      let tempDataObjectList = get(dataObjectList, 'elements', []);
      dataObjectList = tempDataObjectList;
    }

    return dataObjectList.map((item: TGenericLocationDTO) =>
      this.createInstanceFromDataObject(item),
    );
  }

  async getFilteredLocationRequest<TGenericLocationDTO extends Location>(
    locationRequest: LocationRequest,
  ): Promise<TGenericLocationDTO[]> {
    return this.castToInstanceArray(
      this.decorateWithInterceptor(
        this.apiInstance.get(`${this.endPoint}`, { params: locationRequest }),
      ),
    );
  }

  async create<TGenericLocationDTO extends Location>(
    payload: TGenericLocationDTO,
  ): Promise<TGenericLocationDTO> {
    return this.castToInstance(
      this.decorateWithInterceptor(
        this.apiInstance.post(`${this.endPoint}`, payload),
        REQUEST_TYPE.CREATE,
      ),
    );
  }

  async update<TGenericLocationDTO extends Location>(
    payload: TGenericLocationDTO,
  ): Promise<TGenericLocationDTO> {
    return this.castToInstance(
      this.decorateWithInterceptor(
        this.apiInstance.put(`${this.endPoint}/${payload.id}`, payload),
        REQUEST_TYPE.UPDATE,
      ),
    );
  }

  async upsert<TGenericLocationDTO extends Location>(
    payload: TGenericLocationDTO,
  ): Promise<TGenericLocationDTO> {
    if (payload.id) {
      return this.update(payload);
    } else {
      return this.create(payload);
    }
  }

  newInstance<TGenericLocationDTO extends Location>(): TGenericLocationDTO {
    return new Location() as TGenericLocationDTO;
  }
}
