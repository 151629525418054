import { AxiosError } from 'axios';
import { get, toLower } from 'lodash';
import { ALERT_SEVERITY } from '../../utils/AlertSeverity';
import { HttpEvent } from '../../utils/HttpEvent';

const HTTP_TO_ERROR = {
  401: 'unauthorized_error',
  403: 'forbidden_error',
  404: 'notfound_error',
  409: 'conflict_error',
};

function getSpecificErrorFromHTTPCode(
  translateFunction: any,
  error: AxiosError,
  fallBackString: string = '',
) {
  const httpCodeString =
    'notification.http_error.' +
    get(HTTP_TO_ERROR, get(error, 'response.status', 0), 'unknown_error');
  return translateFunction(httpCodeString, fallBackString);
}

function getErrorTranslation(
  translateFunction: any,
  error: AxiosError,
  fallBackString: string = '',
) {
  const errorTranslationKey = 'notification.http_error.' + toLower(error.code);
  return translateFunction(
    errorTranslationKey,
    getSpecificErrorFromHTTPCode(translateFunction, error, fallBackString),
  );
}

function getTranslationFormCommonGeneralKey(
  translateFunction: any,
  item: HttpEvent,
  fallBackString: string = '',
) {
  const commonGeneralKey = ['notification', item.severity].join('.');
  return translateFunction(commonGeneralKey, fallBackString);
}

function getTranslationFormCommonTarget(
  translateFunction: any,
  item: HttpEvent,
  fallBackString: string = '',
) {
  const commonTargetKey = [
    'notification',
    item.requestType,
    item.severity,
  ].join('.');
  return translateFunction(commonTargetKey, fallBackString);
}

export function getNotificationTranslation(
  translateFunction: any,
  item: HttpEvent,
) {
  const componentTargetKey = [
    item.component,
    item.requestType,
    item.severity,
  ].join('.');
  const translation = translateFunction(
    componentTargetKey,
    getTranslationFormCommonTarget(
      translateFunction,
      item,
      getTranslationFormCommonGeneralKey(translateFunction, item),
    ),
  );
  if (item.severity === ALERT_SEVERITY.ERROR) {
    return `${translation} ${getErrorTranslation(
      translateFunction,
      item.data,
    )}`;
  }
  return translation;
}
