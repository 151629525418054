import { MEASURER } from 'constants/endPoint';
import Measurer from 'models/Measurer';
import { REQUEST_TYPE } from 'utils/RequestType';
import { CRUDService } from './CRUDService';

export default class MeasurerService extends CRUDService<Measurer> {
  constructor() {
    super(MEASURER);
  }
  newInstance(): Measurer {
    return new Measurer();
  }

  async calibrate({
    measurerId,
    dateString,
  }: {
    measurerId: number;
    dateString: string;
  }): Promise<Measurer> {
    return this.castToInstance(
      this.decorateWithInterceptor(
        this.apiInstance.put(
          `${this.endPoint}/${measurerId}/calibrate?forcedCalibrationDate=${dateString}`,
        ),
        REQUEST_TYPE.UPDATE,
      ),
    );
  }
}
