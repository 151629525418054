import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { isAdminSelector } from 'atoms/user';
import CommonInputCustom from 'components/common/CommonInputCustom/CommonInputCustom';
import GoBackButton from 'components/common/GoBackButton/GoBackButton';
import { PageContainer } from 'components/common/PageContainer';
import SelectCustom from 'components/common/SelectCustom/SelectCustom';
import TextFieldCustom from 'components/common/TextFieldCustom/TextFieldCustom';
import LocationForm from 'components/LocationForm/LocationForm';
import { LocationType } from 'constants/LocationType';
import { DEVICES, STRUCTURES } from 'constants/routes';
import { StructureType } from 'constants/StructureType';
import { Location } from 'models/Location';
import { LocationLink } from 'models/LocationLink';
import { Site } from 'models/Site';
import { Structure } from 'models/Structure';
import { useState } from 'react';
import { useValidation } from 'react-class-validator';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LocationRequest from 'services/commons/LocationRequest';
import { LocationService } from 'services/commons/LocationService';
import { generateBreadCrumbs } from 'utils/generateBreadCrumbs';
export default function StructureDetails(): JSX.Element {
  const { id: structureId } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isAdmin = useRecoilValue<Boolean>(isAdminSelector);
  const structureService = new LocationService<Structure>();
  const siteService = new LocationService<Site>();
  const siteRequest = new LocationRequest().withLocationType(LocationType.SITE);
  const [structureState, setStructureState] = useState<Structure>(
    Structure.newEmpty(),
  );
  const [validateForm, validationErrors] = useValidation(Structure);
  const [validateFormLocationLink, validationErrorsLocationLink] =
    useValidation(LocationLink);

  const { data: sites } = useQuery('sites', () =>
    siteService
      .getFilteredLocationRequest<Location>(siteRequest)
      .then((res) => res.map((v: Location) => v.toLocationLink())),
  );

  const getCurrentStructure = ({ queryKey }: any) => {
    const structureId = queryKey[1];
    structureService.getOne(structureId).then((structure: Structure) => {
      setStructureState(structure);
      return structure;
    });
  };

  useQuery({
    queryKey: structureId && ['currentStructure', structureId],
    queryFn: getCurrentStructure,
    cacheTime: 5,
    enabled: !!structureId,
  });

  const handleChange = (updatedPropertyObject: any) => {
    const newStructureObject = {
      ...structureState,
      ...updatedPropertyObject,
    };
    setStructureState(newStructureObject);
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    if (await validateFormLocationLink(structureState?.parentLocation)) {
      if (await validateForm(structureState)) {
        structureService.upsert(structureState).then(() => {
          navigate(STRUCTURES);
        });
      }
    }
  };

  const goToChildrenStructures = () => {
    navigate(DEVICES, {
      state: {
        parentLocationName: structureState.locationName,
      },
    });
  };

  const breadCrumbs = generateBreadCrumbs(
    !!structureId
      ? structureState
      : {
          locationName: t('navigationMenu.structures'),
        },
  );

  return (
    <PageContainer
      breadCrumbs={breadCrumbs}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {t('navigationMenu.structuresDetails')}
          </Typography>
        </Box>
      }
    >
      <GoBackButton />
      <Container
        sx={{
          mt: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={structureState}
              editedProperty={'structureCode'}
              disabled={!isAdmin}
              labelBaseName={'structure'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={structureState}
              editedProperty={'structureType'}
              disabled={!isAdmin}
              labelBaseName={'structure'}
              updateFunction={handleChange}
              errorsArray={validationErrors}
              validateFunction={validateForm}
            >
              <SelectCustom<StructureType>
                values={Object.keys(StructureType) as [StructureType]}
                valueToId={(key: StructureType) => key}
                valueToLabel={(key: StructureType) =>
                  t(
                    `structureType.${StructureType[
                      key as keyof typeof StructureType
                    ]
                      .toString()
                      .toLowerCase()}`,
                  )
                }
              />
            </CommonInputCustom>
          </Grid>
        </Grid>
        <LocationForm<Structure>
          handleChange={handleChange}
          locationState={structureState}
          validationErrors={validationErrors}
          validateForm={validateForm}
          setLocationState={setStructureState}
          locationList={sites || []}
          validateFormLocationLink={validateFormLocationLink}
          validationErrorsLocationLink={validationErrorsLocationLink}
          forcedTranslationKey={"locationType.site"}
        />
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <CommonInputCustom
            editedObject={structureState}
            editedProperty={'comment'}
            disabled={!isAdmin}
            labelBaseName={'structure'}
            validateFunction={validateForm}
            errorsArray={validationErrors}
            updateFunction={handleChange}
          >
            <TextFieldCustom rows={4} multiline={true} />
          </CommonInputCustom>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            mb: theme.spacing(2),
          }}
        >
          {structureState.id && (
            <Button
              sx={{ textTransform: 'none' }}
              variant="contained"
              type="submit"
              onClick={goToChildrenStructures}
            >
              {t('structure.goToChildren')}
            </Button>
          )}
          {isAdmin && (
            <Button
              sx={{ textTransform: 'none', ml: theme.spacing(2) }}
              variant="contained"
              type="submit"
              onClick={submitForm}
            >
              {t('navigation.validate')}
            </Button>
          )}
        </Box>
      </Container>
    </PageContainer>
  );
}
