import { CustomEvent } from './CustomEvent';

export class HttpEvent extends CustomEvent {
  public requestType: string;

  constructor(
    severity: string,
    component: string,
    requestType: string,
    data: any = null,
  ) {
    super(severity, component, requestType, data);
    this.requestType = requestType;
  }
}
