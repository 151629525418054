import { Button } from '@mui/material';

export default function ButtonCustom({
  type,
  onClick,
  label,
  fullWidth,
  disabled,
}: {
  type?: any;
  onClick: any;
  label: string;
  fullWidth?: boolean;
  disabled?: any;
}): JSX.Element {
  return (
    <Button
      sx={{ textTransform: 'none' }}
      variant="contained"
      type={type}
      onClick={onClick}
      fullWidth={fullWidth ? fullWidth : false}
      disabled={disabled ? disabled : false}
    >
      {label}
    </Button>
  );
}
