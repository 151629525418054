export const config = {
  //staging
  UNAUTHENTICATED_REDIRECT_URL:
    process.env.REACT_APP_UNAUTHENTICATED_REDIRECT_URL || '',
  API_URL: process.env.REACT_APP_API_URL || '',
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY || '',

  //local
  // UNAUTHENTICATED_REDIRECT_URL:'http://dev.solices.etiskapp.com/oauth2/authorization/oidc?theme=client',
  // API_URL:'http://dev.solices.etiskapp.com/api'
};
