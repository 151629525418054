import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import columnGenerator from './ColumnGenerator';

export default function ActionColumnGenerator(deleteClickHandler: any) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleClickOpen = (event: any, id: any) => {
    event.stopPropagation();
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    if (selectedId !== null) {
      await deleteClickHandler(selectedId);
    }
    setOpen(false);
  };

  const columnAction = [
    {
      ...columnGenerator('dataGrid', 'action', 1, 75, false),
      renderCell: (params: any) => {
        return (
          <Box>
            <IconButton
              onClick={(event) => handleClickOpen(event, params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
            <Dialog
              open={open}
              onClose={handleClose}
              BackdropProps={{
                style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
              }}
            >
              <Box
                sx={{
                  padding: theme.spacing(2),
                }}
              >
                <DialogTitle> {t('modal.deleteTitle')}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('modal.confirmDelete')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    sx={{ textTransform: 'none' }}
                    variant="contained"
                    type="submit"
                    onClick={handleClose}
                  >
                    {t('user.permission.cancel')}
                  </Button>
                  <Button
                    sx={{ textTransform: 'none' }}
                    variant="contained"
                    type="submit"
                    onClick={handleDelete}
                  >
                    {t('user.permission.delete')}
                  </Button>
                </DialogActions>
              </Box>
            </Dialog>
          </Box>
        );
      },
    },
  ];

  return columnAction;
}
