import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { LogoutService } from 'services/commons/LogoutService';
import { isAdminSelector, isLoggedInState } from '../../atoms/user';
import { USERS } from '../../constants/routes';

export const CustomRightMenuDropDown: React.FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const navigate = useNavigate();
  const setIsLoggedIn = useSetRecoilState(isLoggedInState);
  const isAdmin = useRecoilValue<Boolean>(isAdminSelector);

  const logoutService = new LogoutService();

  const handleMenuDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    logoutService.logout().then((res) => {
      const { logoutUrl } = res.data;
      handleMenuDropdownClose();
      document.cookie =
        'session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      if (logoutUrl) {
        window.location.href = logoutUrl;
      }
    });
  };

  return (
    <>
      <Button
        sx={{
          m: 1,
          borderRadius: '8px',
          fontWeight: 400,
          maxHeight: '40px',
          minWidth: 'unset',
          color: 'black',
          '@media (max-width:650px)': {
            display: 'none',
          },
        }}
        onClick={handleMenuDropdownOpen}
      >
        <AccountCircleIcon fontSize={'small'} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuDropdownClose}
        sx={{ '& .MuiMenu-paper': { mt: 2, width: 200 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isAdmin && (
          <MenuItem
            sx={{ py: 1 }}
            onClick={() => {
              navigate(USERS);
              handleMenuDropdownClose();
            }}
          >
            {t('navigationMenu.accountDropDown.accountManagement')}
          </MenuItem>
        )}

        <MenuItem sx={{ py: 1 }} onClick={handleLogout}>
          {t('navigationMenu.accountDropDown.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};
