import {
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsPhoneNumber,
  IsUrl,
  Length,
} from 'class-validator';
import { USER } from 'constants/authorities';
import { GenericDTO } from 'types/GenericDTO';
import { LocationLink } from './LocationLink';

export default class User extends GenericDTO {
  public id: number;

  @IsNotEmpty()
  @Length(1, 50)
  public login: string;

  @Length(0, 50)
  public firstName: string;

  @Length(0, 50)
  public lastName: string;

  @IsEmail()
  @Length(5, 254)
  public email: string;

  @IsUrl()
  @Length(0, 256)
  @IsOptional()
  public imageUrl: string;

  @Length(2, 10)
  public langKey: string;

  public code: string;

  @IsPhoneNumber('FR')
  @IsOptional()
  public fixedPhoneNumber: string;

  @IsPhoneNumber('FR')
  @IsOptional()
  public mobilePhoneNumber: string;

  @IsEmail()
  @Length(5, 254)
  @IsOptional()
  public secondEmail: string;

  public company: LocationLink;

  public createdBy: string;

  public createdDate: Date;

  public lastModifiedBy: string;

  public lastModifiedDate: Date;

  public authorities: string[] = [USER];

  public activated: boolean;

  static newEmpty(): User {
    let user = new User();
    user.company = new LocationLink();

    return user;
  }
}
