import { SxProps, TextField, Theme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import Measurer from 'models/Measurer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AutocompleteCustomProps {
  options: Measurer[];
  label: string;
  value: Measurer | undefined;
  sx?: SxProps<Theme>;
  onChange: any;
  resetValue: any;
  disabled?: any;
}

const useStyle = makeStyles(() => ({
  noOptions: {
    display: 'none',
  },
}));

export default function AutocompleteCustom({
  options,
  label,
  value,
  sx,
  onChange,
  resetValue,
  disabled,
}: AutocompleteCustomProps) {
  const classes = useStyle();
  const { t } = useTranslation();
  const [key, setKey] = useState('0');

  const onChangeHandler = (event: any, newValue: any) => {
    onChange(newValue);
    if (newValue === null || newValue === undefined) {
      resetValue();
    }
    setKey(Math.random().toString(36).slice(2, 7));
  };

  return (
    <Autocomplete
      key={key}
      sx={sx}
      value={value}
      classes={{
        noOptions: classes.noOptions,
      }}
      filterSelectedOptions
      onChange={onChangeHandler}
      disabled={disabled}
      options={options}
      getOptionLabel={(measurer: any) => {
        if (measurer.deviceIdentifier) {
          const label = measurer.measurerLabel
            ? measurer.measurerLabel
            : t('measurementType.' + measurer?.measurementType?.toLowerCase());
          return `${measurer.deviceName} > ` + label;
        } else {
          return '';
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" size="small" />
      )}
    />
  );
}
