import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import CommonInputCustom from 'components/common/CommonInputCustom/CommonInputCustom';
import GoBackButton from 'components/common/GoBackButton/GoBackButton';
import { PageContainer } from 'components/common/PageContainer';
import SelectCustom from 'components/common/SelectCustom/SelectCustom';
import TextFieldCustom from 'components/common/TextFieldCustom/TextFieldCustom';
import { LocationType } from 'constants/LocationType';
import { ADMIN, MANAGER, USER } from 'constants/authorities';
import { EN, FR } from 'constants/languages';
import { USERS } from 'constants/routes';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import remove from 'lodash/remove';
import { Company } from 'models/Company';
import { Location } from 'models/Location';
import { LocationLink } from 'models/LocationLink';
import User from 'models/User';
import { useEffect, useState } from 'react';
import { useValidation } from 'react-class-validator';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import LocationRequest from 'services/commons/LocationRequest';
import { LocationService } from 'services/commons/LocationService';
import UserService from 'services/commons/UserService';
import UserLocationForm from './UserLocationForm';

export default function UserDetails(): JSX.Element {
  const { id: userId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const userService = new UserService();
  const companyService = new LocationService<Company>();
  const companyRequest = new LocationRequest().withLocationType(
    LocationType.COMPANY,
  );
  const subLocationService = new LocationService();
  const subLocationRequest = new LocationRequest();
  const [validateForm, validationErrors] = useValidation(User);
  const [validateFormLocationLink, validationErrorsLocationLink] =
    useValidation(LocationLink);
  const [userState, setUserState] = useState<User>(new User());

  const { data: companies } = useQuery(
    'companies',
    () =>
      companyService
        .getFilteredLocationRequest<Location>(companyRequest)
        .then((res) => res.map((v: Location) => v.toLocationLink())),
    { refetchOnMount: 'always' },
  );
  const { data: subLocations, refetch } = useQuery(
    ['subLocations', subLocationRequest],
    () =>
      subLocationService
        .getFilteredLocationRequest<Location>(subLocationRequest)
        .then((res) => res.map((v: Location) => v.toLocationLink())),
    { refetchOnMount: 'always' },
  );

  const getCurrentUser = ({ queryKey }: any) => {
    const userId = queryKey[1];
    userService.getOne(userId).then((user: User) => {
      setUserState(user);
      const authorities = user.authorities;
      if (authorities.length > 1) {
        remove(authorities, (role) => role === USER);
      }
      return user;
    });
  };

  useQuery({
    queryKey: userId && ['currentUser', userId],
    queryFn: getCurrentUser,
    cacheTime: 5,
    enabled: !!userId,
  });

  const refetchSubLocations = async () => {
    await refetch();
  };

  useEffect(() => {
    if (userState.company?.id) {
      subLocationRequest.withWithinParentLocationId(userState.company!.id);
      refetchSubLocations();
    }
  }, [userState]);

  const handleChange = (updatedPropertyObject: any) => {
    setUserState({
      ...userState,
      ...updatedPropertyObject,
    });
  };

  const handleAuthoritiesChange = (e: any) => {
    const name = e.authorities.toLowerCase();
    const authoritiesArray: string[] = userState.authorities;
    if (isEqual(name, 'role_admin')) {
      authoritiesArray.push(ADMIN);
      remove(authoritiesArray, (role) => role === MANAGER || role === USER);
    }
    if (isEqual(name, 'role_manager')) {
      authoritiesArray.push(MANAGER);
      remove(authoritiesArray, (role) => role === ADMIN || role === USER);
    }
    if (isEqual(name, 'role_user')) {
      authoritiesArray.push(USER);
      remove(authoritiesArray, (role) => role === ADMIN || role === MANAGER);
    }
  };

  const extractRole = (role: string) => {
    return role.replace('ROLE_', '').toLowerCase();
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    if (!userState.authorities.includes(USER)) {
      userState.authorities.push(USER);
    }
    if (await validateForm(userState)) {
      userService.upsert(userState).then(() => {
        navigate(USERS);
      });
    }
  };

  return (
    <PageContainer
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {t('navigationMenu.usersDetails')}
          </Typography>
        </Box>
      }
    >
      <GoBackButton />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={userState}
              editedProperty={'code'}
              labelBaseName={'user'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={userState}
              editedProperty={'login'}
              labelBaseName={'user'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
        </Grid>
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={userState}
              editedProperty={'firstName'}
              labelBaseName={'user'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={userState}
              editedProperty={'lastName'}
              labelBaseName={'user'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
        </Grid>
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={userState}
              editedProperty={'email'}
              labelBaseName={'user'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
        </Grid>
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={userState}
              editedProperty={'mobilePhoneNumber'}
              labelBaseName={'user'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={userState}
              editedProperty={'fixedPhoneNumber'}
              labelBaseName={'user'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
        </Grid>
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={userState}
              editedProperty={'langKey'}
              labelBaseName={'user'}
              updateFunction={handleChange}
              errorsArray={validationErrors}
              validateFunction={validateForm}
            >
              <SelectCustom
                values={[EN, FR]}
                valueToLabel={(key: string) =>
                  t(`availableLanguages.${key.toLowerCase()}`)
                }
                valueToId={(key: string) => key}
              />
            </CommonInputCustom>
          </Grid>
        </Grid>
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={userState}
              editedProperty={'company'}
              labelBaseName={'user'}
              updateFunction={handleChange}
              errorsArray={validationErrorsLocationLink}
            >
              <SelectCustom<LocationLink>
                values={companies || []}
                valueToLabel={(location: LocationLink) => location.locationName}
                valueToId={(location: LocationLink) =>
                  isNil(location?.id) ? '' : location.id
                }
                errorProperty={'id'}
                validateFunction={validateFormLocationLink}
              />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={userState}
              editedProperty={'authorities'}
              labelBaseName={'user'}
              updateFunction={handleAuthoritiesChange}
              errorsArray={validationErrors}
              validateFunction={validateForm}
            >
              <SelectCustom
                values={[USER, ADMIN, MANAGER]}
                valueToLabel={(key: string) => t(`user.${extractRole(key)}`)}
                valueToId={(key: string) => key}
                value={userState.authorities[0]}
              />
            </CommonInputCustom>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            sx={{ textTransform: 'none' }}
            variant="contained"
            type="submit"
            onClick={submitForm}
          >
            {t('navigation.validate')}
          </Button>
        </Box>
        {userState.company?.id && userId && (
          <UserLocationForm locations={subLocations || []} userId={userId} />
        )}
      </Container>
    </PageContainer>
  );
}
