import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CommonInputCustomProps {
  editedObject: any;
  editedProperty: string;
  updateFunction?: any;
  disabled?: boolean;
  labelBaseName: string;
  validateFunction?: Function;
  valueToFieldFormatter?: (value: any) => string;
  fieldToValueFormatter?: (value: string) => any;
  updateOnBlur?: boolean;
  errorsArray?: any;
  children: any;
  preFilledValue?: string;
  forcedTranslationKey?: string;
}

export default function CommonInputCustom(
  props: CommonInputCustomProps,
): JSX.Element {
  const { t } = useTranslation();
  const [fieldValue, setFieldValue] = useState<any>('');

  const getEmptyOrNull = (value: any) => {
    return isNil(value) ? '' : value;
  };

  const getEditedObjectValue = (
    {
      editedObject,
      editedProperty,
      valueToFieldFormatter,
    }: CommonInputCustomProps,
    preFilledValue?: string,
  ) => {
    let value = get(editedObject, editedProperty, '');
    if (valueToFieldFormatter) {
      value = valueToFieldFormatter(value);
    }
    if (preFilledValue && isNil(value)) {
      return preFilledValue;
    }
    return getEmptyOrNull(value);
  };

  useEffect(() => {
    setFieldValue(getEditedObjectValue(props));
  }, [getEditedObjectValue(props)]);

  const getUpdatedObjectValue = (
    value: any,
    editedProperty: string,
    fieldToValueFormatter: ((value: string) => any) | undefined,
  ) => {
    if (fieldToValueFormatter) {
      return { [editedProperty]: getEmptyOrNull(fieldToValueFormatter(value)) };
    }
    return { [editedProperty]: setNullWhenEmpty(value) };
  };

  const setNullWhenEmpty = (value: any) => {
    if (isEmpty(value)) return null;
    return value;
  };

  const getIsError = ({
    errorsArray,
    editedProperty,
  }: CommonInputCustomProps) => {
    return !isNil(errorsArray?.[editedProperty]);
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
      const {
        updateFunction,
        editedProperty,
        fieldToValueFormatter,
        updateOnBlur,
      } = props;
      const updatedObject = getUpdatedObjectValue(
        e.target.value,
        editedProperty,
        fieldToValueFormatter,
      );
      if (!updateOnBlur) {
        updateFunction(updatedObject);
      }
      setFieldValue(e.target.value);
    },
    [props],
  );

  const handleBlur = (props: CommonInputCustomProps) => {
    const {
      validateFunction,
      editedProperty,
      updateOnBlur,
      updateFunction,
      fieldToValueFormatter,
    } = props;
    const updateObject = getUpdatedObjectValue(
      fieldValue,
      editedProperty,
      fieldToValueFormatter,
    );
    if (updateOnBlur) {
      updateFunction(updateObject);
    }
    if (validateFunction) {
      validateFunction(updateObject, [editedProperty]);
    }
  };

  const getLabel = ({
    labelBaseName,
    editedProperty,
  }: CommonInputCustomProps) => {
    if(props.forcedTranslationKey) return t(props.forcedTranslationKey)
    return t(`${labelBaseName}.${editedProperty}`);
  };

  const createCustomProps = () => {
    const { editedProperty, disabled, errorsArray } = props;
    return {
      value: fieldValue,
      label: getLabel(props),
      name: editedProperty,
      disabled: disabled ? disabled : false,
      onBlur: () => handleBlur(props),
      onChange: handleChange,
      error: getIsError(props),
      errors: errorsArray,
    };
  };

  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child as any, createCustomProps());
    });
  };

  return <>{renderChildren()}</>;
}
