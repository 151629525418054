import { ChevronRight } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { get } from 'lodash';
import { useMemo } from 'react';

export const PwaCustomListButton = ({ data, onItemClick }: any) => {
  const itemLabel = useMemo(() => {
    return get(data, 'locationName', get(data, 'deviceName', ''));
  }, [data]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        margin: 'auto',
      }}
    >
      <Button
        sx={{ mb: 1 }}
        endIcon={<ChevronRight />}
        onClick={() => {
          onItemClick(data);
        }}
        variant="contained"
      >
        {itemLabel}
      </Button>
    </Box>
  );
};
