import { AxiosInstance, AxiosResponse } from 'axios';
import { get } from 'lodash';
import { GenericDTO } from 'types/GenericDTO';
import { REQUEST_TYPE } from 'utils/RequestType';
import { apiServiceManager } from './APIServiceManager';
import { IComponentResponseInterceptor } from './IComponentResponseInterceptor';
import { NotificationInterceptor } from './NotificationInterceptor';

export abstract class CRUDService<TGenericDTO extends GenericDTO> {
  protected readonly endPoint: string;
  protected apiInstance: AxiosInstance;
  private _axiosResponseManager: IComponentResponseInterceptor =
    new NotificationInterceptor();
  protected component: string = 'generic';
  private _notifyOnGet: Boolean;

  set notifyOnGet(value: Boolean) {
    this._notifyOnGet = value;
  }

  protected constructor(
    endPoint: string,
    axiosResponseManager: IComponentResponseInterceptor | undefined = undefined,
  ) {
    this.apiInstance = apiServiceManager.instance;
    this.endPoint = endPoint;
    if (axiosResponseManager) {
      this._axiosResponseManager = axiosResponseManager;
    }
  }

  abstract newInstance(): TGenericDTO;

  async decorateWithInterceptor(
    request: Promise<AxiosResponse>,
    requestType: string = REQUEST_TYPE.GET,
  ) {
    try {
      const result = await request;
      if (requestType !== REQUEST_TYPE.GET) {
        return this._axiosResponseManager.handleResponse(
          result,
          this.component,
          requestType,
        );
      }
      if (this._notifyOnGet) {
        return this._axiosResponseManager.handleResponse(
          result,
          this.component,
          requestType,
        );
      }
      return result;
    } catch (error) {
      let parsedError = this._axiosResponseManager.handleError(
        error,
        this.component,
        requestType,
      );
      throw parsedError;
    }
  }

  createInstanceFromDataObject(data: any): TGenericDTO {
    const objectInstance = this.newInstance();
    return Object.assign(objectInstance, data);
  }

  async castToInstance(result: Promise<AxiosResponse>): Promise<TGenericDTO> {
    const dataObject = await get(await result, 'data', {});
    return this.createInstanceFromDataObject(dataObject);
  }

  async castToInstanceArray(
    result: Promise<AxiosResponse>,
  ): Promise<TGenericDTO[]> {
    let dataObjectList = await get(await result, 'data', []);
    if (dataObjectList.hasOwnProperty('elements')) {
      let tempDataObjectList = get(dataObjectList, 'elements', []);
      dataObjectList = tempDataObjectList;
    }

    return dataObjectList.map((item: TGenericDTO) =>
      this.createInstanceFromDataObject(item),
    );
  }

  async getAll(): Promise<TGenericDTO[]> {
    return this.castToInstanceArray(
      this.decorateWithInterceptor(this.apiInstance.get(`${this.endPoint}`)),
    );
  }

  async getFiltered(data: any): Promise<TGenericDTO[]> {
    return this.castToInstanceArray(
      this.decorateWithInterceptor(
        this.apiInstance.get(`${this.endPoint}`, { params: data }),
      ),
    );
  }

  async getOne(id: string): Promise<TGenericDTO> {
    return this.castToInstance(
      this.decorateWithInterceptor(
        this.apiInstance.get(`${this.endPoint}/${id}`),
      ),
    );
  }

  async create(payload: TGenericDTO): Promise<TGenericDTO> {
    return this.castToInstance(
      this.decorateWithInterceptor(
        this.apiInstance.post(`${this.endPoint}`, payload),
        REQUEST_TYPE.CREATE,
      ),
    );
  }

  async update(payload: TGenericDTO): Promise<TGenericDTO> {
    return this.castToInstance(
      this.decorateWithInterceptor(
        this.apiInstance.put(`${this.endPoint}/${payload.id}`, payload),
        REQUEST_TYPE.UPDATE,
      ),
    );
  }

  async upsert(payload: TGenericDTO): Promise<TGenericDTO> {
    if (payload.id) {
      return this.update(payload);
    } else {
      return this.create(payload);
    }
  }

  async delete(id: number): Promise<AxiosResponse> {
    return this.decorateWithInterceptor(
      this.apiInstance.delete(`${this.endPoint}/${id}`),
      REQUEST_TYPE.DELETE,
    );
  }
}
