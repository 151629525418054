export class CustomEvent {
  public timestamp: number;
  public severity: string;
  public component: string;
  public data: any;

  constructor(
    severity: string,
    component: string,
    requestType: string,
    data: any = null,
  ) {
    this.timestamp = new Date().getTime();
    this.severity = severity;
    this.component = component;
    this.data = data;
  }
}
