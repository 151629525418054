export const BASE_URL = 'https://api.solices.etiskapp.com/api/';
export const LOGIN = 'login';
export const MEASUREMENT_STATISTICS = 'measurement/statistics';
export const PUBLIC_COMPANIES = 'public/companies';
export const DEVICE = 'device';
export const MEASUREMENT = 'measurement';
export const MEASURER = 'measurer';
export const LOCATION = 'location';
export const ALARM = 'alarm';
export const USER = 'users';
export const AUTHORITIES = 'authorities';
export const USER_LOCATION = 'userlocation';
export const ALARM_TRIGGER = 'alarmtrigger';
export const AVAILABLE_MEASURERS_PACKAGES = 'availableMeasurersPackages';
export const PREPARE_MEASURERS_PACKAGES = 'prepareMeasurersPackage';
export const INSTALL_MEASURERS_PACKAGES = 'installMeasurersPackage';
export const AVAILABLE_DEVICES = 'availableGprsAddresses';
export const ACTIVATE_SYNCHRONISE_DEVICE =
  'activateOrRefreshIotDataSynchronization';
export const REBOOT_DEVICE = 'reboot';
export const IMPORT_DEVICE = 'import';
export const DOCUMENT = 'documentfile';
