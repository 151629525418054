import { Box, Typography, useTheme } from '@mui/material';
import { isAdminSelector } from 'atoms/user';
import { LinkCustom } from 'components/common/LinkCustom/LinkCustom';
import { PageContainer } from 'components/common/PageContainer';
import ActionColumnGenerator from 'components/DataGridCustom/ActionColumnGenerator';
import columnGenerator from 'components/DataGridCustom/ColumnGenerator';
import DataGridCustom from 'components/DataGridCustom/DataGridCustom';
import { LocationType } from 'constants/LocationType';
import { ADD, EDIT, STRUCTURES } from 'constants/routes';
import { concat } from 'lodash';
import { Structure } from 'models/Structure';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LocationRequest from 'services/commons/LocationRequest';
import { LocationService } from 'services/commons/LocationService';

function Structures(): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const isAdmin = useRecoilValue<Boolean>(isAdminSelector);
  const structureService = new LocationService<Structure>();
  let locationRequest = new LocationRequest().withLocationType(
    LocationType.STRUCTURE,
  );
  const {
    isLoading,
    data: structures,
    refetch: refetchStructures,
  } = useQuery(
    ['structures', locationRequest],
    () =>
      structureService
        .getFilteredLocationRequest<Structure>(locationRequest)
        .then((res) => res),
    { refetchOnMount: 'always' },
  );

  const deleteClickHandler = async (itemId: number) => {
    await structureService.delete(itemId);
    await refetchStructures();
  };

  let columns = [
    {
      ...columnGenerator('location', 'site'),
      valueGetter: (value?: any) => {
        return value?.row?.parentLocation?.locationName;
      },
    },
    columnGenerator('location', 'locationName'),
  ];

  if (isAdmin) {
    const columnAction = [...ActionColumnGenerator(deleteClickHandler)];
    columns = concat(columns, columnAction as any);
  }

  const sortModel = [{ field: 'locationName', sort: 'asc' }];

  const filterModel = state?.parentLocationName
    ? [
        {
          field: 'site',
          operator: 'contains',
          value: state.parentLocationName,
        },
      ]
    : [];

  const handleRowClick = (clickedRow: any) => {
    navigate(`${STRUCTURES}/${EDIT}/${clickedRow.id}`);
  };

  return (
    <PageContainer
      bgColor={theme.palette.background.default}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography color={'primary'} variant="h6">
            {t('navigationMenu.structures')}
          </Typography>
          {isAdmin && (
            <LinkCustom to={`${STRUCTURES}/${ADD}`}>
              {t('structure.add')}
            </LinkCustom>
          )}
        </Box>
      }
    >
      <DataGridCustom
        columns={columns}
        rows={structures || []}
        loading={isLoading}
        sortModel={sortModel}
        onRowClick={handleRowClick}
        filterModel={filterModel}
      />
    </PageContainer>
  );
}

export default Structures;
