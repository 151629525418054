import { Container } from '@mui/material';

export const PwaLayout = ({ children, ...props }: any) => {
  return (
    <Container
      sx={{
        maxWidth: '500px',
      }}
    >
      {children}
    </Container>
  );
};
