import { MeasurementType } from '../../constants/MeasurementType';
import { PagedRequest } from './PagedRequest';

export default class MeasurementRequest extends PagedRequest<MeasurementRequest> {
  deviceIdentifier: string | null = null;
  measurementType: MeasurementType | null = null;
  measurerId: number | null = null;
  beginDate: string | null = null;
  endDate: string | null = null;
}
