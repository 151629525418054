import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';
import { isEmpty } from 'class-validator';
import { PwaCustomList } from 'components/PwaCustomList/PwaCustomList';
import { PwaLayout } from 'components/PwaLayout/PwaLayout';
import { get, last, slice } from 'lodash';
import PwaMeasurersDetails from 'pages/PWA/PwaMeasurersDetails';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { offlineService } from 'services/commons/OfflineService';
import { LocationType } from '../../constants/LocationType';
import Device from '../../models/Device';
import { Site } from '../../models/Site';
import { Structure } from '../../models/Structure';
import DeviceService from '../../services/commons/DeviceService';
import LocationRequest from '../../services/commons/LocationRequest';
import { LocationService } from '../../services/commons/LocationService';

export const Pwa = () => {
  const [allDataLocalStorage, setAllDataLocalStorage] = useState<any>(() => {
    const allDataLocalStorageSaved: any = localStorage.getItem(
      'allDataLocalStorage',
    );
    const initialAllDataLocalStorage = JSON.parse(allDataLocalStorageSaved);
    return initialAllDataLocalStorage || [];
  });

  const [structures, setStructures] = useState<Structure[]>(
    allDataLocalStorage?.sites || [],
  );
  const [devices, setDevices] = useState<Device[]>(
    allDataLocalStorage?.sites || [],
  );
  const [sites, setSites] = useState<Site[]>(allDataLocalStorage?.sites || []);

  const [currentSelection, setCurrentSelection] = useState<any>(() => {
    const currentSelectionSaved: any = localStorage.getItem('currentSelection');
    const initialCurrentSelection = JSON.parse(currentSelectionSaved);
    return initialCurrentSelection || [];
  });

  const siteService = new LocationService<Site>();
  const deviceService = new DeviceService();

  let locationRequest = new LocationRequest().withLocationType(
    LocationType.SITE,
  );
  let structureRequest = new LocationRequest().withLocationType(
    LocationType.STRUCTURE,
  );

  const isOnlineRef = useRef<boolean>(navigator.onLine);

  const {
    isLoading,
    data: allData,
    refetch: refetchAllData,
  } = useQuery('allData', () => {
    return Promise.allSettled([
      siteService
        .getFilteredLocationRequest<Site>(locationRequest)
        .then((res) => {
          setSites(res);
          return res;
        }),
      siteService
        .getFilteredLocationRequest<Structure>(structureRequest)
        .then((res) => {
          setStructures(res);
          return res;
        }),
      deviceService.getAll().then((res) => {
        setDevices(res);
        return res;
      }),
    ]);
  });

  useEffect(() => {
    if (allData) {
      setAllDataLocalStorage(allData);
      localStorage.setItem('allDataLocalStorage', JSON.stringify(allData));
    }
  }, [allData]);

  const currentData = useMemo(() => {
    if (isLoading) return [];
    const currentSelectionLength = currentSelection.length;
    if (currentSelectionLength === 1) {
      const lastItem: any = last(currentSelection);
      return structures.filter(
        (structure: Structure) => structure.parentLocation?.id === lastItem.id,
      );
    }
    if (currentSelectionLength === 2) {
      const lastItem: any = last(currentSelection);
      return devices.filter(
        (device: Device) => device.location?.id === lastItem.id,
      );
    }
    return sites;
  }, [isLoading, currentSelection, devices, structures, sites]);

  const updateSelection = useCallback(
    (newSelection: any) => {
      setCurrentSelection(newSelection);
      localStorage.setItem('currentSelection', JSON.stringify(newSelection));
    },
    [currentSelection],
  );

  const onItemClickHandler = useCallback(
    (item: any) => {
      updateSelection([...currentSelection, item]);
    },
    [updateSelection],
  );

  const goBack = useCallback(() => {
    if (isEmpty(currentSelection)) return;
    updateSelection(slice(currentSelection, 0, currentSelection.length - 1));
  }, [updateSelection]);

  const currentName = useMemo(() => {
    if (isEmpty(currentSelection)) return '';
    const lastItem: any = last(currentSelection);
    return get(lastItem, 'locationName', get(lastItem, 'deviceName', ''));
  }, [currentSelection]);

  const hasSelection = useMemo(() => {
    return currentSelection.length > 0;
  }, [currentSelection]);

  const showDeviceView = useMemo(() => {
    return currentSelection.length > 2;
  }, [currentSelection]);

  const handleOnlineStatusChange = () => {
    isOnlineRef.current = navigator.onLine;
    if (isOnlineRef.current) {
      refetchAllData();
      offlineService.launchAll();
    }
  };

  useEffect(() => {
    window.addEventListener('online', handleOnlineStatusChange);
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
    };
  }, []);

  return (
    <PwaLayout>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {hasSelection && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '300px',
                margin: 'auto',
              }}
            >
              <Button
                sx={{
                  mt: 2,
                }}
                endIcon={<ChevronLeft />}
                onClick={() => {
                  goBack();
                }}
                variant="contained"
              >
                {currentName}
              </Button>
            </Box>
          )}
          {!showDeviceView && (
            <PwaCustomList
              dataArray={currentData}
              onItemClick={onItemClickHandler}
            />
          )}
          {showDeviceView && (
            <PwaMeasurersDetails measureData={last(currentSelection)} />
          )}
        </>
      )}
    </PwaLayout>
  );
};
