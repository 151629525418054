import { ChevronLeft } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function GoBackButton(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Button
      sx={{
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        p: 0,
        m: '10px',
      }}
      onClick={goBack}
    >
      <ChevronLeft />
      {t('navigation.goBack')}
    </Button>
  );
}
