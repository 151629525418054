import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

const FR = 'fr';
const ES = 'es';
const EN = 'en';

export const CustomLanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleLangDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLangDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleLangItemClick = (lang: string) => {
    i18n.changeLanguage(lang);
    handleLangDropdownClose();
  };

  return (
    <>
      <Button
        sx={{
          m: 1,
          borderRadius: '8px',
          fontWeight: 400,
          maxHeight: '40px',
          minWidth: 'unset',
          color: 'black',
          '@media (max-width:650px)': {
            display: 'none',
          },
        }}
        onClick={handleLangDropdownOpen}
      >
        {i18n.resolvedLanguage}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleLangDropdownClose}
        sx={{ '& .MuiMenu-paper': { mt: 2, width: 150 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          sx={{ py: 1 }}
          selected={i18n.language === ES}
          onClick={() => {
            handleLangItemClick(ES);
          }}
        >
          {'Español'}
        </MenuItem>
        <MenuItem
          sx={{ py: 1 }}
          selected={i18n.language === FR}
          onClick={() => {
            handleLangItemClick(FR);
          }}
        >
          {'Français'}
        </MenuItem>
        <MenuItem
          sx={{ py: 1 }}
          selected={i18n.language === EN}
          onClick={() => {
            handleLangItemClick(EN);
          }}
        >
          {'English'}
        </MenuItem>
      </Menu>
    </>
  );
};
