import * as am5 from '@amcharts/amcharts5';

import { CreateAxiosDefaults } from 'axios';
import { config } from 'constants/config';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import App from 'pages/App/App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import i18n from 'translations/i18n';
import reportWebVitals from './reportWebVitals';
import { apiServiceManager } from './services/commons/APIServiceManager';
import { RedirectAuthInterceptor } from './services/commons/RedirectAuthInterceptor';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
    },
  },
});

const apiConfig: CreateAxiosDefaults = {
  baseURL: config.API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    accept: 'application/json',
    //'X-CSRFToken': readCookie('csrftoken'),
  },
  withCredentials: true,
  timeout: 30000,
};

apiServiceManager.configure(
  apiConfig,
  new RedirectAuthInterceptor(config.UNAUTHENTICATED_REDIRECT_URL),
);

dayjs.locale('fr');

am5.addLicense("AM5C-1911-7573-5028-1965");

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </I18nextProvider>
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
);

reportWebVitals();
