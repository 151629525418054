import { PagedRequest } from './PagedRequest';

export default class AlarmTriggerRequest extends PagedRequest<AlarmTriggerRequest> {
  measurerId: string | undefined = undefined;
  synchronizeAlarm: boolean = false;

  withMeasurerId(measurerId: string | undefined) {
    this.measurerId = measurerId;
    return this;
  }

  withSynchronizeAlarm(isSynchronizeAlarm: boolean) {
    this.synchronizeAlarm = isSynchronizeAlarm;
    return this;
  }
}
