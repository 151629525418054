import { DOCUMENT } from 'constants/endPoint';
import { get } from 'lodash';
import Document from 'models/Document';
import { CRUDService } from './CRUDService';

export default class DocumentService extends CRUDService<Document> {
  constructor() {
    super(DOCUMENT);
  }

  newInstance(): Document {
    return new Document();
  }

  async getDocumentByMeasurerIds(ids: number[]): Promise<Document[]> {
    const promises = ids.map((id) =>
      this.apiInstance.get(`${this.endPoint}/measurer/${id}`),
    );
    const results = await Promise.all(promises);
    return results.flatMap((result) => get(result, 'data', []));
  }

  async getDocumentByPublicationFileId(id: string): Promise<string | null> {
    try {
      const response = await this.apiInstance.get(`${this.endPoint}/${id}`, {
        responseType: 'blob',
      });
      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error('Error fetching document:', error);
      throw error;
    }
  }

  async uploadDocument(file: File, measurerId: number): Promise<Document> {
    const formData = new FormData();
    formData.append('file', file);

    const fileInfo = JSON.stringify({
      measurerId: measurerId,
      fileName: file.name,
      mediaType: file.type,
      directory: true,
      fileUidWithExtension: `${file.name}`,
    });
    formData.append(
      'fileInfo',
      new Blob([fileInfo], { type: 'application/json' }),
    );
    try {
      const response = await this.apiInstance.post(
        `${this.endPoint}/file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return get(response, 'data');
    } catch (error) {
      console.error('Error uploading document:', error);
      throw error;
    }
  }

  async deleteDocument(documentId: number): Promise<void> {
    try {
      await this.apiInstance.delete(`${this.endPoint}/${documentId}`);
    } catch (error) {
      console.error('Error deleting document:', error);
      throw error;
    }
  }
}
