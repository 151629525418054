import { CrudPermission } from 'constants/CrudPermission';
import { GenericDTO } from 'types/GenericDTO';
import { LocationLink } from './LocationLink';

export class UserLocation extends GenericDTO {
  public user: {
    id: string;
  };

  public location: LocationLink;

  public crudPermission: CrudPermission[];
}
