import { IsEnum, IsNotEmpty, ValidateNested } from 'class-validator';
import i18next from 'i18next';
import { AlarmState } from 'constants/AlarmState';
import { MeasurerCategory } from 'constants/MeasurerCategory';
import { MeasurementType } from 'constants/MeasurementType';
import DeviceLink from './DeviceLink';
import { GenericDTO } from 'types/GenericDTO';
import { MeasurementUnit } from 'constants/MeasurementUnit';
import GenericData from 'models/GenericData';
import MeasurerLink from 'models/MesurerLink';

export default class Measurer extends GenericDTO{

  public id: number;

  @IsNotEmpty({
    message: () => i18next.t('validator.empty', {
      param: i18next.t('measurer.measurerCategory')
    })
  })
  @IsEnum(Object.keys(MeasurerCategory), {
    message: () => i18next.t('validator.enum')
  })
  public measurerCategory: MeasurerCategory;

  @IsNotEmpty({
    message: () => i18next.t('validator.empty', {
      param: i18next.t('measurer.measurementType')
    })
  })
  @IsEnum(Object.keys(MeasurementType), {
    message: () => i18next.t('validator.enum')
  })
  public measurementType: MeasurementType;

  @ValidateNested({each:true})
  public device: DeviceLink;

  @IsNotEmpty({
    message: () => i18next.t('validator.empty', {
      param: i18next.t('measurer.specificParameters')
    })
  })
  public specificParameters: {
    [fieldKey: string]: GenericData
  }

  public lastAlarmDate: string;

  public alarmState: AlarmState;

  public lastMeasurement: {};

  public measurerLabel: string;

  public lastMeasurementDurationDetails: {};

  public measurementDataType: string;

  public measurementUnit: MeasurementUnit;

  public toMeasurerLink = (): MeasurerLink => {
    let measurerLink = new MeasurerLink();
    measurerLink.id = this.id;
    measurerLink.deviceName = this.device.deviceName;
    measurerLink.deviceIdentifier = this.device.deviceIdentifier;
    measurerLink.measurementType = this.measurementType;
    measurerLink.measurementUnit = this.measurementUnit;
    measurerLink.measurementDataType = this.measurementDataType;
    measurerLink.measurerLabel = this.measurerLabel;
    return measurerLink;
  }

  static newEmpty(): Measurer {
    let measurer = new Measurer();
    measurer.device = new DeviceLink();
    measurer.alarmState = AlarmState.OK_NO_ALARM;

    return measurer;
  }
}
