import { IsEnum, IsOptional, IsString, MaxLength } from 'class-validator';
import { LocationType } from '../constants/LocationType';
import { StructureType } from '../constants/StructureType';
import { Location } from './Location';

export class Structure extends Location {
  @IsOptional()
  @IsEnum(Object.keys(StructureType))
  public structureType: StructureType;

  @IsString()
  @IsOptional()
  public structureCode: string;

  @MaxLength(255)
  @IsOptional()
  public comment: string;

  static newEmpty(): Structure {
    let structure = Location.newEmpty() as Structure;
    structure.locationType = LocationType.STRUCTURE;
    structure.structureCode = '';
    return structure;
  }
}
