import { Periodicity } from 'constants/Periodicity';
import { PagedRequest } from './PagedRequest';

export default class MeasurementStatisticsRequest extends PagedRequest<MeasurementStatisticsRequest> {
  requestType: string = 'BASIC';
  periodicity: Periodicity = Periodicity.HOUR;
  beginPeriod: number | null = null;
  endPeriod: number | null = null;
  filters: object = {};
  // groupBys: string[] | null = null;

  withFilter(filterName: string, filterValue: string) {
    this.filters = {
      ...this.filters,
      filterName: filterValue,
    };
    return this;
  }

  withMeasurerIdInsideFilters(measurerId: string) {
    this.filters = {
      ...this.filters,
      MEASURER_ID: measurerId,
    };
    return this;
  }
}
