export const handleUnit = (type: string) => {
  switch (type) {
    case 'LONG':
      return 's';
    case 'DEGREE':
      return '°C';
    case 'HECTOPASCAL':
      return 'hPa';
    case 'PERCENT':
      return '%';
    case 'VOLT':
      return 'V';
    case 'LUX':
      return 'lx';
    case 'MILLIMETER':
      return 'mm';
    case 'METER':
      return 'm';
    case 'CUBIC_METER_PER_SECOND':
      return 'm³/s';
    case 'SECOND':
      return 's';
    case 'LATITUDE_LONGITUDE':
      return '°';
    case 'PPM':
      return 'ppm';
    case 'MILLIAMPERE':
      return 'mA';
    case 'AMPERE':
      return 'A';
    case 'ON_OFF':
      return '';
    default:
      return '';
  }
};
