import { Box, Typography, useTheme } from '@mui/material';
import { isAdminOrManagerSelector } from 'atoms/user';
import { LinkCustom } from 'components/common/LinkCustom/LinkCustom';
import { PageContainer } from 'components/common/PageContainer';
import ActionColumnGenerator from 'components/DataGridCustom/ActionColumnGenerator';
import DataGridCustom from 'components/DataGridCustom/DataGridCustom';
import { ADD, EDIT, MEASURERS } from 'constants/routes';
import { concat } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import MeasurerService from 'services/commons/MeasurerService';
import useMeasurerTable from '../../hooks/useMeasurerTable';
import { filterMaintenanceMeasurers } from 'utils/MeasurerUtils';

function Measurers(): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation();
  const { state } = useLocation();
  const isAdminOrManager = useRecoilValue<Boolean>(isAdminOrManagerSelector);
  const navigate = useNavigate();
  const measurerService = new MeasurerService();
  const {
    isLoading,
    data: measurers,
    refetch: refetchMeasurers,
  } = useQuery('measurers', () => measurerService.getAll().then((res) => filterMaintenanceMeasurers(res)), {
    refetchOnMount: 'always',
  });
  let { columns, sortModel, filterModel, columnVisibilityModel } =
    useMeasurerTable(state);

  const deleteClickHandler = async (itemId: number) => {
    await measurerService.delete(itemId);
    await refetchMeasurers();
  };

  if (isAdminOrManager) {
    const columnAction = [...ActionColumnGenerator(deleteClickHandler)];
    columns = concat(columns, columnAction as any);
  }

  const handleRowClick = (clickedRow: any) => {
    navigate(`${MEASURERS}/${EDIT}/${clickedRow.id}`);
  };

  return (
    <PageContainer
      bgColor={theme.palette.background.default}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography color={'primary'} variant="h6">
            {t('navigationMenu.measurers')}
          </Typography>
          {isAdminOrManager && (
            <LinkCustom to={`${MEASURERS}/${ADD}`}>
              {t('measurer.add')}
            </LinkCustom>
          )}
        </Box>
      }
    >
      <DataGridCustom
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        rows={measurers || []}
        loading={isLoading}
        sortModel={sortModel}
        onRowClick={handleRowClick}
        filterModel={filterModel}
      />
    </PageContainer>
  );
}
export default Measurers;
