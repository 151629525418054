import moment from 'moment';
import { useTranslation } from 'react-i18next';
import columnGenerator from '../components/DataGridCustom/ColumnGenerator';

interface SortModel {
  field: string;
  sort: 'asc' | 'desc';
}

interface FilterModel {
  field: string;
  operator: string;
  value: any;
}

const useStateTable = (
  state: any,
  isParcStatus: boolean = false,
  renderCellMethod?: any,
) => {
  const { t } = useTranslation();

  let columns: any = [
    {
      ...columnGenerator('measurer', 'device'),
      headerName: t('measurer.device'),
      valueGetter: (value: any) => {
        return value.row.device.deviceName;
      },
    },
    {
      ...columnGenerator('measurer', 'deviceIdentifier'),
      headerName: t('device.deviceIdentifier'),
      valueGetter: (value: any) => {
        return value.row.device.deviceIdentifier;
      },
    },
    {
      ...columnGenerator('measurer', 'measurementType'),
      headerName: t('measurer.measurementType'),
      valueGetter: (value: any) => {
        return t(`measurementType.${value.row.measurementType.toLowerCase()}`);
      },
    },
    {
      ...columnGenerator('measurer', 'measurerLabel'),
      headerName: t('measurer.label'),
    },

    {
      ...columnGenerator('measurer', 'alarmState'),
      headerName: t('measurer.alarmState'),
      ...(isParcStatus
        ? {
            renderCell: (params: any) => renderCellMethod(params),
          }
        : {
            valueGetter: (value: any) => {
              return t(`alarmState.${value.row.alarmState.toLowerCase()}`);
            },
          }),
    },

    {
      ...columnGenerator('measurer', 'lastAlarmDate'),
      headerName: t('measurer.lastAlarmDate'),
      valueGetter: (value: any) => {
        return value.row.lastAlarmDate
          ? moment(value.row.lastAlarmDate).format('DD/MM/YYYY HH:mm')
          : null;
      },
    },
  ];

  const sortModel: SortModel[] = [{ field: 'deviceIdentifier', sort: 'asc' }];
  const filterModel: FilterModel[] = state?.deviceIdentifier
    ? [
        {
          field: 'deviceIdentifier',
          operator: 'contains',
          value: state.deviceIdentifier,
        },
      ]
    : [];

  const columnVisibilityModel = {
    // Hide columns status and traderName, the other columns will remain visible
    deviceIdentifier: false,
  };

  // Other logic or side effects can be added here

  return {
    columns,
    sortModel,
    filterModel,
    columnVisibilityModel,
  };
};

export default useStateTable;
