import { AxiosResponse } from 'axios';
import { MEASUREMENT, MEASUREMENT_STATISTICS } from 'constants/endPoint';
import { get, isNull } from 'lodash';
import Measurement from 'models/Measurement';
import MeasurementStatistic from '../../models/MeasurementStatistic';
import { CRUDService } from './CRUDService';

export default class MeasurementService extends CRUDService<Measurement> {
  constructor() {
    super(MEASUREMENT);
  }
  newInstance(): Measurement {
    return new Measurement();
  }

  createStatisticsInstanceFromDataObject(data: any): MeasurementStatistic {
    const objectInstance = new MeasurementStatistic();
    return Object.assign(objectInstance, data);
  }

  async castStatistics(
    result: Promise<AxiosResponse>,
  ): Promise<MeasurementStatistic[]> {
    let dataObjectList = await get(await result, 'data', []);
    let period: string[] = Object.getOwnPropertyNames(dataObjectList);
    let data: MeasurementStatistic[] = [];
    period.forEach((item) => {
      if (!isNull(get(dataObjectList, item)[0])) {
        data.push(get(dataObjectList, item)[0]);
      }
    });
    return data.map((item: MeasurementStatistic) =>
      this.createStatisticsInstanceFromDataObject(item),
    );
  }

  async getStatistics(payload: any): Promise<MeasurementStatistic[]> {
    return this.castStatistics(
      this.decorateWithInterceptor(
        this.apiInstance.post(`${MEASUREMENT_STATISTICS}`, payload),
      ),
    );
  }

  async getChronicles(payload: any): Promise<Measurement[]> {
    return this.castToInstanceArray(
      this.decorateWithInterceptor(
        this.apiInstance.get(`${MEASUREMENT}`, { params: payload }),
      ),
    );
  }
}
