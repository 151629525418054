import { IsOptional, IsString } from 'class-validator';
import { Location } from './Location';

export class Site extends Location {
  @IsString()
  @IsOptional()
  public code: string;

  static NewEmpty(): Site {
    let site = Location.newEmpty() as Site;
    site.code = '';
    return site;
  }
}
