export enum MeasurementType {
  PRESSURE_ANALOG = 'PRESSURE_ANALOG',
  PRESSURE = 'PRESSURE',
  WATER_LEVEL = 'WATER_LEVEL',
  TEMPERATURE = 'TEMPERATURE',
  TEMPERATURE_ANALOG = 'TEMPERATURE_ANALOG',
  HUMIDITY = 'HUMIDITY',
  BATTERY = 'BATTERY',
  BATTERY_PERCENT = 'BATTERY_PERCENT',
  NETWORK_COORDINATES = 'NETWORK_COORDINATES',
  KEEP_ALIVE = 'KEEP_ALIVE',
  CO2 = 'CO2',
  COV = 'COV',
  EXTERNAL_TEMPERATURE_CHANNEL_1 = 'EXTERNAL_TEMPERATURE_CHANNEL_1',
  EXTERNAL_TEMPERATURE_CHANNEL_2 = 'EXTERNAL_TEMPERATURE_CHANNEL_2',
  VOID_LEVEL = 'VOID_LEVEL',
  WATER_LEVEL_ANALOG = 'WATER_LEVEL_ANALOG',
  WATER_HEIGHT = 'WATER_HEIGHT',
  WATER_DEPTH_RELATIVE = 'WATER_DEPTH_RELATIVE',
  WATER_HEIGHT_RELATIVE = 'WATER_HEIGHT_RELATIVE',
  WATER_HEIGHT_NGF = 'WATER_HEIGHT_NGF',
  WATER_DEPTH_NGF = 'WATER_DEPTH_NGF',
  FLOW = 'FLOW',
  WATER_FLOW = 'WATER_FLOW',
}
