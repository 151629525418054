import { IsNumber, IsOptional } from 'class-validator';
import { GoogleAddress } from './GoogleAddress';

export class GeoLocation {
  @IsNumber()
  @IsOptional()
  public xLon: number;

  @IsNumber()
  @IsOptional()
  public yLat: number;

  public address: GoogleAddress | undefined;

  constructor() {
    this.xLon = 0;
    this.yLat = 0;
  }
}
