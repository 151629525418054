import { AxiosResponse } from 'axios';
import { ALARM } from 'constants/endPoint';
import { get } from 'lodash';
import Alarm from 'models/Alarm';
import { CRUDService } from './CRUDService';

export default class AlarmService extends CRUDService<Alarm> {
  constructor() {
    super(ALARM);
  }

  newInstance(): Alarm {
    return new Alarm();
  }

  async getAllAlarms(id: string): Promise<any[]> {
    return this.castToStringArray(
      this.decorateWithInterceptor(
        this.apiInstance.get(`${this.endPoint}/${id}`),
      ),
    );
  }

  async castToStringArray(result: Promise<AxiosResponse>): Promise<Alarm[]> {
    return get(await result, 'data', []);
  }
}
