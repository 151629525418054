import DeleteIcon from '@mui/icons-material/Delete';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material';
import ButtonCustom from 'components/common/ButtonCustom/ButtonCustom';
import CommonInputCustom from 'components/common/CommonInputCustom/CommonInputCustom';
import SelectCustom from 'components/common/SelectCustom/SelectCustom';
import { isNil } from 'lodash';
import filter from 'lodash/filter';
import some from 'lodash/some';
import { LocationLink } from 'models/LocationLink';
import { UserLocation } from 'models/UserLocation';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import UserLocationRequest from 'services/commons/UserLocationRequest';
import { UserLocationService } from 'services/commons/UserLocationService';

export default function UserLocationForm({
  locations,
  userId,
}: {
  locations: LocationLink[];
  userId: string;
}): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation();
  const userLocationService = new UserLocationService();
  const [userLocationToAddState, setUserLocationToAddState] =
    useState<UserLocation>(new UserLocation());
  const userLocationRequestToAdd = new UserLocationRequest().withUserId(userId);
  const userLocationRequestToDelete = new UserLocationRequest().withUserId(
    userId,
  );

  const { data: currentUserLocationList, refetch }: any = useQuery(
    ['currentUserLocationList', userId],
    () =>
      userLocationService.getUserLocationForOneUser(userId).then((res) => res),
    { refetchOnMount: 'always' } as any,
  );

  const refetchUserLocationList = async () => {
    await refetch();
  };

  const locationListFiltered = useMemo(() => {
    return filter(locations, (item) => {
      return !some(
        currentUserLocationList,
        (entry) => entry.location.id === item.id,
      );
    });
  }, [locations, currentUserLocationList]);

  const handleChange = (updatedPropertyObject: any) => {
    setUserLocationToAddState({
      ...userLocationToAddState,
      ...updatedPropertyObject,
    });
  };

  const addUserLocation = () => {
    userLocationRequestToAdd.withLocationId(
      userLocationToAddState.location.id.toString(),
    );
    // userLocationRequestToAdd.crudPermission = crudPermissionState;
    userLocationService
      .createUserLocation(userLocationRequestToAdd)
      .then(() => {
        refetchUserLocationList();
      });
    setUserLocationToAddState(new UserLocation());
  };

  const deleteUserLocation = (id: number) => () => {
    userLocationRequestToDelete.withLocationId(id.toString());
    userLocationService
      .deleteUserLocation(userLocationRequestToDelete)
      .then(() => {
        refetchUserLocationList();
      });
  };

  return (
    <>
      <Grid container sx={{ mt: theme.spacing(2) }}>
        <Grid item xs={3} sx={{ pr: theme.spacing(2) }}>
          <CommonInputCustom
            editedObject={userLocationToAddState}
            editedProperty={'location'}
            labelBaseName={'user'}
            updateFunction={handleChange}
          >
            <SelectCustom<LocationLink>
              values={locationListFiltered}
              valueToId={(location: LocationLink) =>
                isNil(location?.id) ? '' : location.id
              }
              valueToLabel={(location: LocationLink) => location.locationName}
            />
          </CommonInputCustom>
        </Grid>
        {/*<Grid item xs={3} sx={{pr: theme.spacing(2)}}>*/}
        {/*  <SelectCustom*/}
        {/*    onChange={(e: any) => setCrudPermissionState(e.target.value)}*/}
        {/*    label={'permissions'}*/}
        {/*    name={'crudPermission'}*/}
        {/*    value={crudPermissionState}*/}
        {/*    values={Object.keys(CrudPermission) as [CrudPermission]}*/}
        {/*    valueToLabel={(key: CrudPermission) => t(`user.permission.${CrudPermission[key as keyof typeof CrudPermission].toString().toLowerCase()}`)}*/}
        {/*    valueToId={(key: CrudPermission) => key}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={3}>
          <ButtonCustom
            onClick={addUserLocation}
            label={t('user.addUserLocation')}
          />
        </Grid>
      </Grid>
      <List>
        {currentUserLocationList?.map((userLocation: UserLocation) => {
          return (
            <ListItem key={userLocation.location.id}>
              <Grid container>
                <Grid item xs={3} sx={{ pr: theme.spacing(2) }}>
                  <ListItemText primary={userLocation.location.locationName} />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={deleteUserLocation(userLocation.location.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
