import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Box, Typography, useTheme } from '@mui/material';
import useStateTable from 'hooks/useStateTable';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { PageContainer } from '../../components/common/PageContainer';
import DataGridCustom from '../../components/DataGridCustom/DataGridCustom';
import MeasurerService from '../../services/commons/MeasurerService';

function ParcStatus(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const { state } = useLocation();

  const measurerService = new MeasurerService();

  const GenerateCircularAlarmStatement = (value: any) => {
    const alarmState = value.value;
    if (alarmState === 'OK_NO_ALARM') {
      return (
        <>
          <CircleIcon sx={{ color: 'green' }} />
          <CircleOutlinedIcon sx={{ color: 'grey' }} />
          <CircleOutlinedIcon sx={{ color: 'grey' }} />
        </>
      );
    }
    if (
      alarmState === 'LOWER_BOUND_MINOR' ||
      alarmState === 'UPPER_BOUND_MINOR'
    ) {
      return (
        <>
          <CircleIcon sx={{ color: 'grey' }} />
          <CircleOutlinedIcon sx={{ color: 'orange' }} />
          <CircleOutlinedIcon sx={{ color: 'grey' }} />
        </>
      );
    }
    if (
      alarmState === 'UPPER_BOUND_MAJOR' ||
      alarmState === 'LOWER_BOUND_MAJOR'
    ) {
      return (
        <>
          <CircleIcon sx={{ color: 'grey' }} />
          <CircleIcon sx={{ color: 'grey' }} />
          <CircleOutlinedIcon sx={{ color: 'red' }} />
        </>
      );
    }
  };

  const { columns, sortModel, filterModel } = useStateTable(
    state,
    true,
    GenerateCircularAlarmStatement,
  );

  const { isLoading, data: measurers } = useQuery(
    'measurers',
    () =>
      measurerService
        .getAll()
        .then((res) =>
          res.filter(
            (measurer) =>
              measurer.measurementType === 'KEEP_ALIVE' ||
              measurer.measurementType === 'BATTERY_PERCENT',
          ),
        ),
    { refetchOnMount: 'always' },
  );

  return (
    <PageContainer
      bgColor={theme.palette.background.default}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography color={'primary'} variant="h6">
            {t('navigationMenu.parcStatus')}
          </Typography>
        </Box>
      }
    >
      {!isLoading && (
        <DataGridCustom
          columns={columns}
          rows={measurers || []}
          loading={isLoading}
          sortModel={sortModel}
          filterModel={filterModel}
        />
      )}
    </PageContainer>
  );
}

export default ParcStatus;
