import { IsNotEmpty } from 'class-validator';
import { AlarmState } from 'constants/AlarmState';
import { MeasurementType } from 'constants/MeasurementType';
import i18next from 'i18next';

export default class Alarm {
  public id: number;
  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('alert.measurerId'),
      }),
  })
  public measurerId: number;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('alert.deviceIdentifier'),
      }),
  })
  public deviceIdentifier: string;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('alert.measurementType'),
      }),
  })
  public measurementType: MeasurementType;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('alert.eventDate'),
      }),
  })
  public eventDate: Date;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('alert.alarmState'),
      }),
  })
  public alarmState: AlarmState;
}
