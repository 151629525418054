import { AxiosError, AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { LogoutService } from 'services/commons/LogoutService';
import { isUnauthorized } from 'utils/isUnauthorized';
import { IResponseInterceptor } from './IResponseInterceptor';

export class RedirectAuthInterceptor implements IResponseInterceptor {
  private readonly redirectUrl: string | undefined;

  private readonly logoutService = new LogoutService();

  constructor(redirectUrl: string | undefined) {
    this.redirectUrl = redirectUrl;
  }

  public handleResponse(response: AxiosResponse): AxiosResponse {
    if (isUnauthorized(response.status)) {
      this.doRedirect();
    }
    return response;
  }

  public handleError(error: AxiosError): Promise<never> {
    if (isUnauthorized(error.response?.status)) {
      this.doRedirect();
    }
    return Promise.reject(error);
  }

  private doRedirect() {
    this.logoutService
      .logout()
      .catch((e) => {
        console.log('Logout failed');
      })
      .finally(() => {
        document.cookie =
          'session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        const redirectUrlOrEmpty = this.redirectUrl || '';
        if (!isEmpty(redirectUrlOrEmpty)) {
          window.location.href = `${redirectUrlOrEmpty}&referer=${encodeURIComponent(
            window.location.href,
          )}`;
        }
      });
  }
}
