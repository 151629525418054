import { AxiosResponse } from 'axios';
import { USER_LOCATION } from '../../constants/endPoint';
import { UserLocation } from '../../models/UserLocation';
import { REQUEST_TYPE } from '../../utils/RequestType';
import { CRUDService } from './CRUDService';
import UserLocationRequest from './UserLocationRequest';

export class UserLocationService extends CRUDService<UserLocation> {
  constructor() {
    super(USER_LOCATION);
  }

  async createUserLocation(
    userLocationRequest: UserLocationRequest,
  ): Promise<UserLocation> {
    return this.castToInstance(
      this.decorateWithInterceptor(
        this.apiInstance.post(
          `${this.endPoint}/${userLocationRequest.userId}/${userLocationRequest.locationId}`,
        ),
      ),
    );
    // return this.castToInstance(this.decorateWithInterceptor(this.apiInstance.post(`${this.endPoint}/${userLocationRequest.userId}/${userLocationRequest.locationId}?crudPermission=${userLocationRequest.crudPermission}`)))
  }

  async deleteUserLocation(
    userLocationRequest: UserLocationRequest,
  ): Promise<AxiosResponse> {
    return this.decorateWithInterceptor(
      this.apiInstance.delete(
        `${this.endPoint}/${userLocationRequest.userId}/${userLocationRequest.locationId}`,
      ),
      REQUEST_TYPE.DELETE,
    );
  }

  async getUserLocationForOneUser(id: string): Promise<UserLocation[]> {
    return this.castToInstanceArray(
      this.decorateWithInterceptor(
        this.apiInstance.get(`${this.endPoint}/${id}`),
      ),
    );
  }

  newInstance(): UserLocation {
    return new UserLocation();
  }
}
