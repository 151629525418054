import { CrudPermission } from '../../constants/CrudPermission';
import { PagedRequest } from './PagedRequest';

export default class UserLocationRequest extends PagedRequest<UserLocationRequest> {
  userId: string | null = null;
  locationId: string | null = null;
  crudPermission: CrudPermission;

  withUserId(userId: string) {
    this.userId = userId;
    return this;
  }

  withLocationId(locationId: string) {
    this.locationId = locationId;
    return this;
  }
}
