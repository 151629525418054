import { Button } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const LinkCustom = ({
  to,
  children,
}: {
  to: string;
  children?: React.ReactNode;
}) => {
  return (
    <Button
      sx={{ textTransform: 'none' }}
      component={RouterLink}
      to={to}
      variant="contained"
      color="primary"
    >
      {children}
    </Button>
  );
};
