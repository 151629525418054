import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { isAdminSelector } from 'atoms/user';
import CommonInputCustom from 'components/common/CommonInputCustom/CommonInputCustom';
import GoBackButton from 'components/common/GoBackButton/GoBackButton';
import { PageContainer } from 'components/common/PageContainer';
import TextFieldCustom from 'components/common/TextFieldCustom/TextFieldCustom';
import LocationForm from 'components/LocationForm/LocationForm';
import { COMPANIES } from 'constants/routes';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import omit from 'lodash/omit';
import replace from 'lodash/replace';
import startsWith from 'lodash/startsWith';
import { Company } from 'models/Company';
import { LocationLink } from 'models/LocationLink';
import { useState } from 'react';
import { useValidation } from 'react-class-validator';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { LocationService } from 'services/commons/LocationService';
import { generateBreadCrumbs } from '../../../utils/generateBreadCrumbs';

export default function CompanyDetails(): JSX.Element {
  const { id: companyId } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const companyService = new LocationService<Company>();
  const isAdmin = useRecoilValue<Boolean>(isAdminSelector);
  const [companyState, setCompanyState] = useState<Company>(Company.NewEmpty());
  const [validateForm, validationErrors] = useValidation(Company);
  const [validateFormLocationLink, validationErrorsLocationLink] =
    useValidation(LocationLink);
  const prefixWebsite = 'https://';

  const getCurrentCompany = ({ queryKey }: any) => {
    const companyId = queryKey[1];
    companyService.getOne(companyId).then((company: Company) => {
      if (startsWith(company.website, prefixWebsite)) {
        company.website = replace(company.website, prefixWebsite, '');
      }
      setCompanyState(company);
      return company;
    });
  };

  useQuery({
    queryKey: companyId && ['currentUser', companyId],
    queryFn: getCurrentCompany,
    cacheTime: 5,
    enabled: !!companyId,
  });

  const handleChange = (updatedPropertyObject: any) => {
    const newCompanyObject = {
      ...companyState,
      ...updatedPropertyObject,
    };
    setCompanyState(newCompanyObject);
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    let companyToUpsert: Company;
    if (await validateForm(companyState)) {
      if (
        get(companyState, 'website.length', 0) > 0 &&
        !startsWith(companyState.website, prefixWebsite)
      ) {
        companyState.website = prefixWebsite + companyState.website;
      }
      if (
        isNull(companyState.parentLocation) ||
        companyState.parentLocation.locationName === ''
      ) {
        companyToUpsert = omit(companyState, ['parentLocation']) as Company;
      } else {
        companyToUpsert = companyState;
      }

      companyService.upsert(companyToUpsert).then(() => {
        navigate(COMPANIES);
      });
    }
  };

  const breadCrumbs = generateBreadCrumbs(
    !!companyId
      ? companyState
      : {
          locationName: t('navigationMenu.companies'),
        },
  );

  return (
    <PageContainer
      breadCrumbs={breadCrumbs}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {!!companyId
              ? t('navigationMenu.companiesDetails')
              : t('navigationMenu.addCompany')}
          </Typography>
        </Box>
      }
    >
      <GoBackButton />
      <Container
        sx={{
          mt: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={companyState}
              editedProperty={'identificationNumber'}
              disabled={!isAdmin}
              labelBaseName={'company'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={companyState}
              editedProperty={'companyCode'}
              disabled={!isAdmin}
              labelBaseName={'company'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
        </Grid>

        <LocationForm<Company>
          handleChange={handleChange}
          locationState={companyState}
          validationErrors={validationErrors}
          validateForm={validateForm}
          setLocationState={setCompanyState}
          locationList={[]}
          validateFormLocationLink={validateFormLocationLink}
          validationErrorsLocationLink={validationErrorsLocationLink}
        />
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={companyState}
              editedProperty={'legalForm'}
              disabled={!isAdmin}
              labelBaseName={'company'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={companyState}
              editedProperty={'phoneNumber'}
              disabled={!isAdmin}
              labelBaseName={'company'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
        </Grid>

        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={companyState}
              editedProperty={'email'}
              disabled={!isAdmin}
              labelBaseName={'company'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={companyState}
              editedProperty={'website'}
              disabled={!isAdmin}
              labelBaseName={'company'}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom prefix={prefixWebsite} />
            </CommonInputCustom>
          </Grid>
        </Grid>
        {isAdmin && (
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              sx={{ textTransform: 'none' }}
              variant="contained"
              type="submit"
              onClick={submitForm}
            >
              {t('navigation.validate')}
            </Button>
          </Box>
        )}
      </Container>
    </PageContainer>
  );
}
