import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ALARMS,
  COMPANIES,
  DEVICES,
  MEASURERS,
  SITES,
  STRUCTURES,
} from '../../constants/routes';

const listItemPage = [
  { id: 'companies', to: COMPANIES },
  { id: 'sites', to: SITES },
  { id: 'structures', to: STRUCTURES },
  { id: 'devices', to: DEVICES },
  { id: 'measurers', to: MEASURERS },
  { id: 'alarms', to: ALARMS },
];

export const CustomNavigationDropDown: React.FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const navigate = useNavigate();

  const handleMenuDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuDropdownClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={{
          m: 1,
          borderRadius: '8px',
          fontWeight: 400,
          maxHeight: '40px',
          minWidth: 'unset',
          color: 'black',
          // backgroundColor: '#3f51b5',
          '@media (max-width:650px)': {
            display: 'none',
          },
        }}
        onClick={handleMenuDropdownOpen}
      >
        <DeviceHubIcon fontSize={'small'} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuDropdownClose}
        sx={{ '& .MuiMenu-paper': { mt: 2, width: 150 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {listItemPage.map((item) => {
          return (
            <MenuItem
              key={item.id}
              sx={{ py: 1 }}
              onClick={() => {
                navigate(item.to);
              }}
            >
              {t(`navigationMenu.${item.id}`)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
