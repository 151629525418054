import SyncIcon from '@mui/icons-material/Sync';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { currentConfiguredDevice } from 'atoms/currentConfiguredDevice';
import { isAdminOrManagerSelector } from 'atoms/user';
import CommonConfirmationDialog from 'components/common/CommonConfirmationDialog';
import CommonInputCustom from 'components/common/CommonInputCustom/CommonInputCustom';
import GoBackButton from 'components/common/GoBackButton/GoBackButton';
import { PageContainer } from 'components/common/PageContainer';
import SelectCustom from 'components/common/SelectCustom/SelectCustom';
import TextFieldCustom from 'components/common/TextFieldCustom/TextFieldCustom';
import { LocationType } from 'constants/LocationType';
import { DEVICES, MEASURERS } from 'constants/routes';
import { isNil } from 'lodash';
import Device from 'models/Device';
import { LocationLink } from 'models/LocationLink';
import { Structure } from 'models/Structure';
import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import { useValidation } from 'react-class-validator';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import DeviceService from 'services/commons/DeviceService';
import LocationRequest from 'services/commons/LocationRequest';
import { LocationService } from 'services/commons/LocationService';
import columnGenerator from '../../../components/DataGridCustom/ColumnGenerator';
import DataGridCustom from '../../../components/DataGridCustom/DataGridCustom';
import Alarm from '../../../models/Alarm';
import AlarmService from '../../../services/commons/AlarmService';
import { generateBreadCrumbs } from '../../../utils/generateBreadCrumbs';

function DeviceDetails(): JSX.Element {
  const { id: deviceId } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isAdminOrManager = useRecoilValue<Boolean>(isAdminOrManagerSelector);
  const structureService = new LocationService<Structure>();
  const alarmService = new AlarmService();
  const deviceService = new DeviceService();
  const currentConfiguredDeviceState = useRecoilValue<Device | null>(
    currentConfiguredDevice,
  );
  const structureRequest = new LocationRequest().withLocationType(
    LocationType.STRUCTURE,
  );
  const [deviceState, setDeviceState] = useState<Device>(Device.newEmpty());
  const [validateForm, validationErrors] = useValidation(Device);
  const [validateFormLocationLink, validationErrorsLocationLink] =
    useValidation(LocationLink);

  const [synchroniseLoading, setSynchroniseLoading] = useState<boolean>(false);

  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  const { data: structures } = useQuery('structures', () =>
    structureService
      .getFilteredLocationRequest<Structure>(structureRequest)
      .then((res) => res.map((v: Structure) => v.toLocationLink())),
  );

  const getCurrentDevice = ({ queryKey }: any) => {
    const deviceId = queryKey[1];
    deviceService.getOne(deviceId).then((device: Device) => {
      setDeviceState(device);
      return device;
    });
  };

  useQuery({
    queryKey: deviceId && ['currentDevice', deviceId],
    queryFn: getCurrentDevice,
    cacheTime: 5,
    enabled: !!deviceId,
  });

  const getAlarms = ({ queryKey }: { queryKey: any[] }): Promise<Alarm[]> => {
    const deviceId = queryKey[1];
    return alarmService.getAllAlarms(deviceId).then((alarms: Alarm[]) => {
      return alarms;
    });
  };

  const { isLoading, data: alarms } = useQuery(
    deviceState?.deviceIdentifier
      ? ['alarms', deviceState?.deviceIdentifier]
      : [],
    getAlarms,
    {
      enabled: !!deviceState?.deviceIdentifier,
      refetchOnMount: 'always',
      cacheTime: 5,
    },
  );

  const handleChange = (updatedPropertyObject: any) => {
    setDeviceState({
      ...deviceState,
      ...updatedPropertyObject,
    });
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    if (await validateForm(deviceState)) {
      deviceService.upsert(deviceState).then(() => {
        navigate(DEVICES);
      });
    }
  };

  const goToChildrenMeasurers = () => {
    navigate(MEASURERS, {
      state: {
        deviceIdentifier: deviceState.deviceIdentifier,
      },
    });
  };

  const breadCrumbs = generateBreadCrumbs(deviceState.location);

  const handleSynchronize = () => {
    setShowConfirmationDialog(false);
    setSynchroniseLoading(true);
    deviceService
      .synchronizeDevice(deviceState.id)
      .then(() => {
        setSynchroniseLoading(false);
        navigate(DEVICES);
      })
      .catch(() => {
        setSynchroniseLoading(false);
      });
  };

  const showExplanation = useMemo(() => {
    return currentConfiguredDeviceState !== null;
  }, [currentConfiguredDeviceState]);

  const sortModel = [{ field: 'eventDate', sort: 'desc' }];

  const formatDate = (date: string): string => {
    if (!date) {
      return '';
    }
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  };

  let columns = [
    {
      ...columnGenerator('alarm', 'deviceName'),
      valueGetter: (value: any) => {
        return value?.row?.triggerData?.deviceName;
      },
    },
    {
      ...columnGenerator('alarm', 'eventDate'),
      valueGetter: (params: any) => {
        return formatDate(params.row.eventDate);
      },
    },
    {
      ...columnGenerator('alarm', 'measurementType'),
      valueGetter: (value: any) => {
        return t(`measurementType.${value.row.measurementType.toLowerCase()}`);
      },
    },
    {
      ...columnGenerator('alarm', 'measurementValue'),
      valueGetter: ({ row }: any) => {
        return row?.triggerData?.measurementValue?.toFixed(5);
      },
    },
    {
      ...columnGenerator('alarm', 'thresholdValue'),
      valueGetter: (value: any) => {
        return value?.row?.triggerData?.thresholdValue;
      },
    },
    {
      ...columnGenerator('alarm', 'alarmState'),
      valueGetter: (value: any) => {
        return t(`alarmState.${value.row.alarmState.toLowerCase()}`);
      },
    },
  ];

  return (
    <PageContainer
      breadCrumbs={breadCrumbs}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {t('navigationMenu.deviceDetails')}
          </Typography>
        </Box>
      }
    >
      <GoBackButton />
      <Container
        sx={{
          mt: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container sx={{ mb: theme.spacing(2) }}>
          {showExplanation && (
            <Grid item xs={12}>
              <p>
                <h4>{t('device.explanation3')}</h4>
              </p>
              <p>
                <h4>{t('device.explanation4')}</h4>
              </p>
            </Grid>
          )}
          <Grid item xs={6} sx={{ display: 'flex', pr: theme.spacing(2) }}>
            <Typography color={'primary'} style={{ fontWeight: 'bold' }}>
              {t(`device.deviceIdentifier`)}
            </Typography>
            :<Typography>&nbsp;{deviceState.deviceIdentifier}</Typography>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={deviceState}
              editedProperty={'deviceName'}
              labelBaseName={'device'}
              disabled={!isAdminOrManager}
              validateFunction={validateForm}
              errorsArray={validationErrors}
              updateFunction={handleChange}
            >
              <TextFieldCustom />
            </CommonInputCustom>
          </Grid>
        </Grid>
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ display: 'flex', pr: theme.spacing(2) }}>
            <Typography color={'primary'} style={{ fontWeight: 'bold' }}>
              {t(`device.iotNetwork`)}
            </Typography>
            :<Typography>&nbsp;{deviceState.iotNetwork}</Typography>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              editedObject={deviceState}
              editedProperty={'location'}
              labelBaseName={'device'}
              updateFunction={handleChange}
              errorsArray={validationErrorsLocationLink}
              forcedTranslationKey={"locationType.structure"}
            >
              <SelectCustom<LocationLink>
                values={structures || []}
                valueToLabel={(location: LocationLink) =>
                  location.locationName
                }
                valueToId={(location: LocationLink) =>
                  isNil(location?.id) ? '' : location.id
                }
                errorProperty={'id'}
                validateFunction={validateFormLocationLink}
              />
            </CommonInputCustom>
          </Grid>
        </Grid>
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ display: 'flex' }}>
            <Typography color={'primary'} style={{ fontWeight: 'bold' }}>
              {t(`GPRS.iotAddress`)}
            </Typography>
            :<Typography>&nbsp;{deviceState.iotAddress}</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ display: 'flex' }}>
            <Typography color={'primary'} style={{ fontWeight: 'bold' }}>
              {t(`device.serialNumber`)}
            </Typography>
            :<Typography>&nbsp;{deviceState.serialNumber}</Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            mb: theme.spacing(2),
          }}
        >
          {deviceState.id && (
            <>
              <Button
                sx={{ textTransform: 'none' }}
                variant="contained"
                type="submit"
                disabled={synchroniseLoading}
                onClick={() => setShowConfirmationDialog(true)}
              >
                {t('device.synchronizeBtn')}{' '}
                <SyncIcon
                  sx={{
                    ml: theme.spacing(1),
                  }}
                />
              </Button>
              <Button
                sx={{ textTransform: 'none', ml: theme.spacing(2) }}
                variant="contained"
                type="submit"
                onClick={goToChildrenMeasurers}
              >
                {t('device.goToChildren')}
              </Button>
            </>
          )}
          {isAdminOrManager && (
            <Button
              sx={{ textTransform: 'none', ml: theme.spacing(2) }}
              variant="contained"
              type="submit"
              onClick={submitForm}
            >
              {t('navigation.validate')}
            </Button>
          )}
        </Box>
        <CommonConfirmationDialog
          open={showConfirmationDialog}
          handleValidate={() => handleSynchronize()}
          handleClose={() => setShowConfirmationDialog(false)}
        >
          {t('device.synchronizeDialogContent')}
        </CommonConfirmationDialog>

        <Typography
          color={'primary'}
          style={{ fontWeight: 'bold', marginBottom: 10 }}
        >
          {t(`navigationMenu.alarms`)}
        </Typography>
        <DataGridCustom
          fullWidth
          autoHeight
          columns={columns}
          rows={alarms || []}
          loading={isLoading}
          sortModel={sortModel}
          noDataText={t('alarm.noAlarm')}
          getRowId={(row: any) => `${row.deviceIdentifier}-${row.eventDate}`}
        />
      </Container>
    </PageContainer>
  );
}
export default DeviceDetails;
