import { IsEnum, IsNotEmpty, ValidateNested } from 'class-validator';
import { IotNetwork } from 'constants/IotNetwork';
import i18next from 'i18next';
import { GenericDTO } from 'types/GenericDTO';
import DeviceLink from './DeviceLink';
import { LocationLink } from './LocationLink';
import Measurer from './Measurer';

export default class Device extends GenericDTO {
  public id: number;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('device.deviceIdentifier'),
      }),
  })
  public deviceIdentifier: string;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('device.deviceName'),
      }),
  })
  public deviceName: string;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('device.iotNetwork'),
      }),
  })
  @IsEnum(Object.keys(IotNetwork))
  public iotNetwork: IotNetwork;

  @ValidateNested({ each: true })
  public location: LocationLink;
  public measurers: Array<Measurer>;
  public nrbAlarms: number;
  public lastMeasurementDate: Date;
  public macAddress: string;
  public iotAddress: string;
  public serialNumber: string;

  //todo: quel type ??
  public lastMeasurementDurationDetails: string;

  public toDeviceLink = (): DeviceLink => {
    let deviceLink = new DeviceLink();
    deviceLink.id = this.id;
    deviceLink.deviceName = this.deviceName;
    deviceLink.deviceIdentifier = this.deviceIdentifier;

    return deviceLink;
  };

  static newEmpty(): Device {
    let device = new Device();
    device.deviceIdentifier = '';
    device.deviceName = '';
    device.iotNetwork = IotNetwork.LORA;
    device.location = new LocationLink();

    return device;
  }
}
