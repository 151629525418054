import { Grid, TextField, useTheme } from '@mui/material';
import { isAdminSelector } from 'atoms/user';
import CommonInputCustom from 'components/common/CommonInputCustom/CommonInputCustom';
import TextFieldCustom from 'components/common/TextFieldCustom/TextFieldCustom';
import { config } from 'constants/config';
import { filter, get, isEmpty, keys } from 'lodash';
import { GeoLocation } from 'models/GeoLocation';
import { useEffect, useState } from 'react';
import { useValidation } from 'react-class-validator';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { floatToText, textToFloat } from 'utils/floatToTextConverters';

const getGoogleAddressElement = (
  array: [],
  elementName: string,
  format: string,
): string | undefined => {
  const element: any = filter(array, function (a: any) {
    return a['types'][0] === elementName;
  });
  if (!isEmpty(element)) {
    return element[0][format];
  }
};

export default function GeoLocationForm({
  location,
  setLocation,
}: any): JSX.Element {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isAdmin = useRecoilValue<Boolean>(isAdminSelector);
  const [validateForm, validationErrors] = useValidation(GeoLocation);
  const [formattedAddress, setFormattedAddress] = useState('');

  useEffect(() => {
    setFormattedAddress(location?.geoLocation?.address?.googleFormattedAddress);
  }, [location]);

  const handleChange = (event: any) => {
    setFormattedAddress(event.target.value);
    setLocation((location: any) => ({
      ...location,
      geoLocation: { address: { googleFormattedAddress: event.target.value } },
    }));
  };

  const handleChangeXY = (updatedPropertyObject: any) => {
    const name = keys(updatedPropertyObject)[0];
    const value = get(updatedPropertyObject, name);
    const parsedValue = parseFloat(value);
    updatedPropertyObject[name] = isNaN(value) ? null : parsedValue;
    const newGeoLocation = {
      ...location.geoLocation,
      ...updatedPropertyObject,
    };
    setLocation((location: any) => ({
      ...location,
      geoLocation: { ...newGeoLocation },
    }));
  };

  const { ref: materialRef } = usePlacesWidget({
    apiKey: config.GOOGLE_API_KEY,
    language: i18n.language,
    options: {
      types: ['address'],
    },
    onPlaceSelected: async (place: any) => {
      let geoLocation = {
        xLon: parseFloat(place.geometry.location.lng()),
        yLat: parseFloat(place.geometry.location.lat()),
        address: {
          street: getGoogleAddressElement(
            place.address_components,
            'route',
            'long_name',
          ),
          postalCode: getGoogleAddressElement(
            place.address_components,
            'postal_code',
            'short_name',
          ),
          city: getGoogleAddressElement(
            place.address_components,
            'locality',
            'long_name',
          ),
          countryCode: getGoogleAddressElement(
            place.address_components,
            'country',
            'short_name',
          ),
          googleFormattedAddress: place.formatted_address,
        },
      };
      setLocation((location: any) => ({
        ...location,
        geoLocation: { ...geoLocation },
      }));
      setFormattedAddress(
        location?.geoLocation?.address?.googleFormattedAddress,
      );
    },
  });

  return (
    <Grid container sx={{ mb: theme.spacing(2) }}>
      <Grid container>
        <TextField
          fullWidth
          size={'small'}
          inputRef={materialRef}
          placeholder={''}
          value={formattedAddress}
          onChange={handleChange}
          label={t('location.search')}
          InputLabelProps={{ shrink: true }}
          disabled={!isAdmin}
        />
      </Grid>
      <Grid item xs={6} sx={{ pr: theme.spacing(2), pt: theme.spacing(2) }}>
        <CommonInputCustom
          editedObject={location.geoLocation}
          editedProperty={'yLat'}
          labelBaseName={'location'}
          validateFunction={validateForm}
          errorsArray={validationErrors}
          updateFunction={handleChangeXY}
          valueToFieldFormatter={floatToText}
          fieldToValueFormatter={textToFloat}
          updateOnBlur={true}
        >
          <TextFieldCustom type="number" />
        </CommonInputCustom>
      </Grid>
      <Grid item xs={6} sx={{ pt: theme.spacing(2) }}>
        <CommonInputCustom
          editedObject={location.geoLocation}
          editedProperty={'xLon'}
          labelBaseName={'location'}
          validateFunction={validateForm}
          errorsArray={validationErrors}
          updateFunction={handleChangeXY}
          valueToFieldFormatter={floatToText}
          fieldToValueFormatter={textToFloat}
          updateOnBlur={true}
        >
          <TextFieldCustom type="number" />
        </CommonInputCustom>
      </Grid>
    </Grid>
  );
}
