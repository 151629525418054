import { Add, Close } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Button, Grid, IconButton, useTheme } from '@mui/material';
import * as React from 'react';
import { useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import FileViewer from 'react-file-viewer';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import columnGenerator from '../../components/DataGridCustom/ColumnGenerator';
import Document from '../../models/Document';
import Measurer from '../../models/Measurer';
import DocumentService from '../../services/commons/DocumentService';
import DataGridCustom from '../DataGridCustom/DataGridCustom';

interface FileViewerCustomProps {
  canEdit?: boolean;
  measurers?: Measurer[];
}

export default function FileViewerCustom({
  measurers,
  canEdit = false,
}: FileViewerCustomProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any>();
  const documentService = new DocumentService();
  const location = useLocation();
  const fullWidth = location.pathname.includes('measurers');
  const queryClient = useQueryClient();

  const measurersAll = useMemo(() => {
    return measurers?.map((measurer) => measurer.id) || [];
  }, [measurers]);

  const getDocuments = (): Promise<Document[]> => {
    const measurersIds = measurers?.map((measurer) => measurer.id) || [];
    return documentService.getDocumentByMeasurerIds(measurersIds);
  };

  const { isLoading, data: documents = [] } = useQuery(
    measurersAll.length > 0 ? ['documents', measurersAll] : [],
    getDocuments,
    {
      enabled:
        measurersAll.length > 0 && measurersAll.every((id) => id !== undefined),
      refetchOnMount: 'always',
      cacheTime: 5,
    },
  );

  const handleDownloadDocument = async (file: any) => {
    try {
      const fileUrl = await documentService.getDocumentByPublicationFileId(
        file.publicationUid,
      );
      if (fileUrl) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = file.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleFileViewerType = (mediaType: string) => {
    switch (mediaType) {
      case 'image/png':
        return 'png';
      case 'image/jpeg':
        return 'jpeg';
      case 'application/pdf':
        return 'pdf';
      default:
        return 'png';
    }
  };

  const handleRowClick = async (
    clickedRow: any,
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    try {
      const imgUrl = await documentService.getDocumentByPublicationFileId(
        clickedRow.publicationUid,
      );
      setSelectedImage({
        filePath: imgUrl,
        type: handleFileViewerType(clickedRow.mediaType),
      });
      setOpen(true);
    } catch (error) {
      console.error('Error fetching file:', error);
    }
  };

  const handleClosePopper = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  let columns = [
    columnGenerator('image', 'fileName'),
    columnGenerator('image', 'mediaType'),
    {
      field: 'download',
      headerName: t('image.download'),
      renderCell: (params: any) => (
        <IconButton
          edge="end"
          aria-label="download"
          onClick={(event) => {
            event.stopPropagation();
            handleDownloadDocument(params.row);
          }}
        >
          <DownloadIcon />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
    },
    ...(canEdit
      ? [
          {
            field: 'Action',
            headerName: t('image.delete'),
            renderCell: (params: any) => (
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteDocument(event, params.row.publicationUid);
                }}
              >
                <DeleteIcon />
              </IconButton>
            ),
            sortable: false,
            filterable: false,
          },
        ]
      : []),
  ];

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    const measurerId = measurers?.[0]?.id;

    if (file && measurerId) {
      await documentService.uploadDocument(file, measurerId);
      await queryClient.invalidateQueries(['documents', measurersAll]);
    }
  };

  const handleDeleteDocument = async (
    event: React.MouseEvent<HTMLButtonElement>,
    documentId: number,
  ) => {
    event.stopPropagation();
    if (documentId && measurersAll?.length > 0) {
      try {
        await documentService.deleteDocument(documentId);
        await queryClient.invalidateQueries(['documents', measurersAll]);
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }
  };

  const onFileViewerError = (error: Error) => {
    console.error('Error occurred while viewing the file:', error);
  };

  const getDimensions = (selectedImage: any): React.CSSProperties => {
    const defaultStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1500,
      maxHeight: '70vh',
      maxWidth: '60vw',
      overflow: 'auto',
    } as React.CSSProperties;

    if (selectedImage?.type === 'png' || selectedImage?.type === 'jpeg') {
      return {
        ...defaultStyle,
        width: 'auto',
        height: 'auto',
      } as React.CSSProperties;
    }
    return {
      ...defaultStyle,
      width: '60vw',
      height: '70vh',
    } as React.CSSProperties;
  };

  return (
    <>
      {canEdit && (
        <Grid item xs={6}>
          <Button
            variant="contained"
            component="label"
            sx={{ textTransform: 'none', mb: theme.spacing(1) }}
          >
            {t('image.upload')}
            <Add sx={{ ml: 2 }} />
            <input type="file" hidden onChange={handleFileUpload} />
          </Button>
        </Grid>
      )}

      <DataGridCustom
        autoHeight
        rows={documents}
        columns={columns}
        loading={isLoading}
        onRowClick={handleRowClick}
        {...(fullWidth && { fullWidth })}
        getRowId={(row: any) => `${row.fileName}-${row.measurerId}`}
      />
      <Box></Box>
      {open && selectedImage && (
        <Draggable
          handle=".draggable-popper-title"
          defaultPosition={{ x: -350, y: -200 }}
        >
          <Box
            sx={{
              flexDirection: 'column',
              position: 'relative',
              display: 'inline-flex',
              overflow: 'auto',
              backgroundColor: 'white',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              ...getDimensions(selectedImage),
            }}
          >
            <Box
              className="draggable-popper-title"
              sx={{
                m: 0,
                p: 2,
                top: 0,
                left: 0,
                height: 40,
                width: '100%',
                cursor: 'grab',
                zIndex: 1501,
                display: 'flex',
                position: 'sticky',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <IconButton
                aria-label="drag"
                sx={{
                  color: 'white',
                  cursor: 'grab',
                }}
              >
                <DragIndicatorIcon />
              </IconButton>
              <IconButton
                aria-label="close"
                onClick={handleClosePopper}
                sx={{
                  color: 'white',
                  ml: 'auto',
                }}
              >
                <Close />
              </IconButton>
            </Box>
            {selectedImage && (
              <Box sx={{ width: '100%', height: '100%' }}>
                <FileViewer
                  fileType={selectedImage.type}
                  filePath={selectedImage.filePath}
                  onError={onFileViewerError}
                />
              </Box>
            )}
          </Box>
        </Draggable>
      )}
    </>
  );
}
