import { AxiosInstance, AxiosResponse } from 'axios';
import { apiServiceManager } from 'services/commons/APIServiceManager';

const LOGOUT_ENDPOINT = 'logout';

export class LogoutService {
  protected apiInstance: AxiosInstance;

  constructor() {
    this.apiInstance = apiServiceManager.instance;
  }

  async logout(): Promise<AxiosResponse> {
    return this.apiInstance.post(LOGOUT_ENDPOINT);
  }

  async callLogoutUrl(logoutUrl: string): Promise<AxiosResponse> {
    return this.apiInstance.get(logoutUrl);
  }
}
