import { atom, selector } from 'recoil';

export const currentUserState = atom({
  key: 'currentUserState',
  default: {
    user: {},
    isAuthenticated: false,
    isAdmin: false,
    isManager: false,
    isAdminOrManager: false,
  },
});

export const isLoggedInState = atom({
  key: 'isLoggedInState',
  default: false,
});

export const isAdminSelector = selector({
  key: 'isAdmin',
  get: ({ get }) => {
    const userInfo = get(currentUserState);
    return userInfo.isAdmin;
  },
});

export const isManagerSelector = selector({
  key: 'isManager',
  get: ({ get }) => {
    const userInfo = get(currentUserState);
    return userInfo.isManager;
  },
});

export const isAdminOrManagerSelector = selector({
  key: 'isAdminOrManager',
  get: ({ get }) => {
    const userInfo = get(currentUserState);
    return userInfo.isAdminOrManager;
  },
});
