import MenuIcon from '@mui/icons-material/Menu';
import { Button, Drawer, List, ListItem, ListItemText } from '@mui/material';
import Typography from '@mui/material/Typography';
import { toUpper } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../../../atoms/user';
import { ALERTS, MEASUREMENT, PARCSTATUS } from '../../../constants/routes';
import logo from '../../../logoAssets/logo_solices_full.png';
import { CustomLanguageSelector } from '../../CustomLanguageSelector/CustomLanguageSelector';
import { CustomRightMenuDropDown } from '../../CustomRightMenuDropDown/CustomRightMenuDropDown';
import { CustomNavigationDropDown } from '../CustomNavigationDropDown';
import AppBarCustom from '../Header/AppBarCustom';

const listItemPage = [
  { id: 'measures', to: MEASUREMENT },
  { id: 'alerts', to: ALERTS },
  { id: 'parcStatus', to: PARCSTATUS },
  { id: 'faq', to: '/assets/faq.pdf' },
  { id: 'contact', to: 'http://www.solices-ah2d.fr/nous-contacter/' },
];

type Anchor = 'left';
export const NavDrawer = ({ content, scrollPosition }: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const currentUser: any = useRecoilValue(currentUserState);

  const userInfos = currentUser.user;

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setIsOpen(open);
    };

  const NavListItem = ({ item, location, toggleDrawer, t }: any) => {
    const isExternalLink =
      item.to.startsWith('http') || item.to.endsWith('.pdf');

    return (
      <ListItem
        component={isExternalLink ? 'a' : (NavLink as any)}
        href={isExternalLink ? item.to : undefined}
        to={!isExternalLink ? item.to : undefined}
        target={isExternalLink ? '_blank' : undefined}
        onClick={toggleDrawer('left', false)}
        className={item.to === location.pathname ? 'active-link' : ''}
      >
        <ListItemText primary={t(`navigationMenu.${item.id}`)} />
      </ListItem>
    );
  };

  return (
    <AppBarCustom scroll={scrollPosition}>
      <Button
        onClick={toggleDrawer('left', true)}
        sx={{
          position: 'relative',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <MenuIcon color={'secondary'} fontSize={'large'} />
      </Button>
      <NavLink to={'/'}>
        <img
          src={logo}
          alt="logo"
          style={{
            width: '80%',
            height: 'auto',
            maxWidth: '80px',
            margin: 0,
            marginTop: '10px',
          }}
        />
      </NavLink>
      {content}
      <Drawer
        anchor={'left'}
        open={isOpen}
        onClose={toggleDrawer('left', false)}
      >
        <List
          sx={{
            width: '200px',
            '& .MuiListItem-root:hover:not(.logo)': {
              color: 'blue',
              fontWeight: '900',
            },
          }}
        >
          <ListItem>
            <NavLink to={'/'}>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '70px',
                  marginLeft: '40px',
                }}
              />
            </NavLink>
          </ListItem>

          {listItemPage.map((item, index) => (
            <NavListItem
              key={index}
              item={item}
              location={location}
              toggleDrawer={toggleDrawer}
              t={t}
            />
          ))}
        </List>
      </Drawer>
      <CustomNavigationDropDown />
      <CustomRightMenuDropDown />
      <Typography
        sx={{
          color: '#3f51b5',
          fontWeight: 'bold',
          minWidth: '200px',
        }}
      >
        {`${userInfos.firstName} ${toUpper(userInfos.lastName)}`}
      </Typography>
      <CustomLanguageSelector />
    </AppBarCustom>
  );
};
