import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { isEmpty, map, maxBy, meanBy, minBy } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getLabelFromMeasurer } from 'utils/MeasurerUtils';
import { DateRange } from '../../types/DateRange';
interface StatisticsDialogProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  measurementsArray: any;
  dateRange: DateRange;
}

export const StatisticsDialog = ({
  isOpen,
  setOpen,
  measurementsArray,
  dateRange,
}: StatisticsDialogProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const convertedMultiMapIntoArray = map(measurementsArray, (measurement) => {
    return {
      measurerLabel: getLabelFromMeasurer(measurement.measurer, t),
      data: measurement.data,
    };
  });

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '85%',
          maxWidth: '100%',
          margin: 0,
          borderRadius: 2,
          px: 2,
          py: 6,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: '#3f51b5',
          mb: 2,
          ml: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: '1.2rem',
            textAlign: 'center',
            fontWeight: 'bold',
            margin: 'auto',
          }}
        >
          {t('statDialog.title', {
            date: `${moment(dateRange.beginDate).format(
              'DD/MM/YYYY',
            )} - ${moment(dateRange.endDate).format('DD/MM/YYYY')}`,
          })}
        </Typography>
        <CloseIcon
          onClick={handleClose}
          sx={{
            cursor: 'pointer',
            fontSize: '2rem',
          }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('statDialog.measurementType')}</TableCell>
              <TableCell>{t('statDialog.minimum')}</TableCell>
              <TableCell>{t('statDialog.maximum')}</TableCell>
              <TableCell>{t('statDialog.average')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {convertedMultiMapIntoArray &&
              map(convertedMultiMapIntoArray, (item: any, index: any) => {
                if (isEmpty(item)) return;
                const minValue = minBy(item.data, 'averageValue');
                const maxValue = maxBy(item.data, 'averageValue');

                return (
                  <TableRow key={index}>
                    <TableCell>{item.measurerLabel}</TableCell>
                    {minValue && (
                      <TableCell>
                        {(minValue as any)?.averageValue.toFixed(2)}
                      </TableCell>
                    )}
                    {maxValue && (
                      <TableCell>
                        {(maxValue as any)?.averageValue.toFixed(2)}
                      </TableCell>
                    )}

                    <TableCell>
                      {meanBy(item.data, 'averageValue').toFixed(2)}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
};
