import { Outlet } from '@mui/icons-material';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isAdminOrManagerSelector, isAdminSelector } from '../../../atoms/user';

export const ProtectedRoute = ({
  redirectPath,
  children,
  onlyAdmin = false,
}: {
  redirectPath: string;
  children: JSX.Element;
  onlyAdmin?: boolean;
}) => {
  const isAdmin = useRecoilValue<Boolean>(isAdminSelector);
  const isAdminOrManager = useRecoilValue<Boolean>(isAdminOrManagerSelector);
  // useEffect(() => {
  //   console.log('onlyAdmin && isAdmin : ', onlyAdmin && isAdmin)
  //   console.log('!onlyAdmin && (isAdmin || isManager) : ', !onlyAdmin && (isAdmin || isManager))
  // })

  if ((onlyAdmin && isAdmin) || (!onlyAdmin && isAdminOrManager)) {
    return children ? children : <Outlet />;
  }

  return <Navigate to={redirectPath} replace />;
};
