import { Box, Typography, useTheme } from '@mui/material';
import columnGenerator from 'components/DataGridCustom/ColumnGenerator';
import DataGridCustom from 'components/DataGridCustom/DataGridCustom';
import { PageContainer } from 'components/common/PageContainer';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import AlarmService from 'services/commons/AlarmService';
import { handleUnit } from '../../utils/UnitsConverter';

export default function Alerts(): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation();
  const alarmService = new AlarmService();

  const { isLoading, data: alarms } = useQuery(
    'alarms',
    () => alarmService.getAll().then((res) => res),
    { refetchOnMount: 'always' } as any,
  );

  const columns = [
    {
      ...columnGenerator('alarm', 'eventDate'),
      valueGetter: (value: any) => {
        const currentTz = moment.tz.guess();
        return value.row.eventDate
          ? moment
              .utc(value.row.eventDate)
              .tz(currentTz)
              .format('DD/MM/YYYY HH:mm')
          : null;
      },
    },
    {
      ...columnGenerator('alarm', 'deviceIdentifier'),
      valueGetter: (value: any) => {
       return value.row?.triggerData?.deviceName || ''
      },
    },

    {
      ...columnGenerator('alarm', 'measurementType'),
      valueGetter: (value: any) => {
        return t(`measurementType.${value.row.measurementType.toLowerCase()}`);
      },
    },
    {
      ...columnGenerator('alarm', 'measurementValue'),
      valueGetter: ({ row }: any) => {
        const type = row?.measurementUnit;
        return row?.triggerData?.measurementValue
          ? row?.triggerData?.measurementValue?.toFixed(5) + handleUnit(type)
          : '';
      },
    },
    {
      ...columnGenerator('alarm', 'thresholdValue'),
      valueGetter: ({ row }: any) => {
        const type = row?.measurementUnit;
        return row?.triggerData
          ? row?.triggerData.thresholdValue + handleUnit(type)
          : '';
      },
    },
    {
      ...columnGenerator('alarm', 'alarmState'),
      valueGetter: (value: any) => {
        return t(`alarmState.${value.row.alarmState.toLowerCase()}`);
      },
    },
  ];

  const sortModel = [{ field: 'measurerId', sort: 'asc' }];

  return (
    <PageContainer
      bgColor={theme.palette.background.default}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography color={'primary'} variant="h6">
            {t('navigationMenu.alerts')}
          </Typography>
        </Box>
      }
    >
      <DataGridCustom
        columns={columns}
        rows={alarms || []}
        loading={isLoading}
        sortModel={sortModel}
        onRowClick={() => {}}
        getRowId={(row: any) => row.measurerId + row.eventDate}
      />
    </PageContainer>
  );
}
