import { Box, Typography, useTheme } from '@mui/material';
import { isAdminSelector } from 'atoms/user';
import { LinkCustom } from 'components/common/LinkCustom/LinkCustom';
import { PageContainer } from 'components/common/PageContainer';
import ActionColumnGenerator from 'components/DataGridCustom/ActionColumnGenerator';
import columnGenerator from 'components/DataGridCustom/ColumnGenerator';
import DataGridCustom from 'components/DataGridCustom/DataGridCustom';
import { LocationType } from 'constants/LocationType';
import { ADD, COMPANIES, EDIT } from 'constants/routes';
import { concat } from 'lodash';
import { Company } from 'models/Company';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LocationRequest from 'services/commons/LocationRequest';
import { LocationService } from 'services/commons/LocationService';

function Companies() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const companyService = new LocationService<Company>();
  const isAdmin = useRecoilValue<Boolean>(isAdminSelector);
  const locationRequest = new LocationRequest().withLocationType(
    LocationType.COMPANY,
  );
  const {
    isLoading,
    data: companies,
    refetch: refetchCompanies,
  } = useQuery(
    'companies',
    () =>
      companyService
        .getFilteredLocationRequest<Company>(locationRequest)
        .then((res) => res),
    { refetchOnMount: 'always' },
  );

  const deleteClickHandler = async (itemId: number) => {
    await companyService.delete(itemId);
    await refetchCompanies();
  };

  let columns = [
    columnGenerator('location', 'locationName'),
    columnGenerator('company', 'companyCode'),
    columnGenerator('company', 'legalForm'),
    columnGenerator('company', 'phoneNumber'),
    columnGenerator('company', 'identificationNumber'),
    columnGenerator('company', 'email'),
    columnGenerator('company', 'website'),
  ];

  if (isAdmin) {
    const columnAction = [...ActionColumnGenerator(deleteClickHandler)];
    columns = concat(columns, columnAction as any);
  }

  const sortModel = [{ field: 'locationName', sort: 'asc' }];

  const handleRowClick = (clickedRow: any) => {
    navigate(`${COMPANIES}/${EDIT}/${clickedRow.id}`);
  };

  return (
    <PageContainer
      bgColor={theme.palette.background.default}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {t('navigationMenu.companies')}
          </Typography>
          {isAdmin && (
            <LinkCustom to={`${COMPANIES}/${ADD}`}>
              {t('company.add')}
            </LinkCustom>
          )}
        </Box>
      }
    >
      <DataGridCustom
        columns={columns}
        rows={companies || []}
        loading={isLoading}
        sortModel={sortModel}
        onRowClick={handleRowClick}
      />
    </PageContainer>
  );
}

export default Companies;
