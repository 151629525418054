import Breadcrumbs from '@mui/material/Breadcrumbs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface Breadcrumb {
  id: number;
  locationType: string;
  locationName: string;
}
interface BreadcrumbProps {
  breadCrumbs?: Breadcrumb[];
  isHomePage?: boolean;
}

export const CustomBreadcrumb: React.FC<BreadcrumbProps> = ({
  breadCrumbs,
}) => {
  const { t } = useTranslation();

  return (
    <Breadcrumbs
      sx={{
        ml: breadCrumbs ? 2 : 0,
        mb: breadCrumbs ? 2 : 0,
      }}
    >
      {breadCrumbs && (
        <NavLink
          to="/"
          style={{
            textDecoration: 'none',
            color: '#3f51b5',
          }}
        >
          {t('navigationMenu.home')}
        </NavLink>
      )}
      {breadCrumbs &&
        breadCrumbs.map((item, index) => (
          <span
            key={index}
            style={{
              textDecoration: 'none',
              color: '#3f51b5',
            }}
          >
            {item.locationName}
          </span>
        ))}
    </Breadcrumbs>
  );
};
