export const SITES = '/sites';
export const LOGIN = '/login';
export const COMPANIES = '/companies';
export const MEASUREMENT = '/measures';
export const ALARMS = '/alarms';
export const ALERTS = '/alerts';
export const DEVICES = '/devices';
export const STRUCTURES = '/structures';
export const MEASURERS = '/measurers';
export const INDEX = '/';
export const EDIT = 'edit';
export const ADD = 'add';
export const USERS = '/users';
export const PWA = '/pwa';

export const PARCSTATUS = '/parcstatus';
