import { MeasurementType } from 'constants/MeasurementType';
import { MeasurementUnit } from 'constants/MeasurementUnit';
import moment from 'moment';
import { GenericDTO } from 'types/GenericDTO';

export default class MeasurementStatistic extends GenericDTO {
  public deviceIdentifier: string;
  public measurementType: MeasurementType;
  public measurementUnit: MeasurementUnit;
  public period: Number;
  public averageValue: Number;
  public firstValue: Number;
  public lastValue: Number;
  public maxValue: Number;
  public minValue: Number;
  public nbrMeasurements: Number;

  public get periodInMonthToDateNumber() {
    const periodToString = this.period.toString();
    const dateObj = moment(periodToString, 'YYYYMM');
    return dateObj.toDate().getTime();
  }

  public get periodInWeekToDateNumber() {
    const periodToString = this.period.toString();
    const dateObj = moment(periodToString, 'YYYYww');
    return dateObj.toDate().getTime();
  }
}
