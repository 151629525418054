export const ALERT_SEVERITY = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

export const SERVER_ERRORS = {
  CANCEL_ERROR: 'CANCEL_ERROR',
  CLIENT_ERROR: 'CLIENT_ERROR',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',
  SERVER_ERROR: 'SERVER_ERROR',
  TIMEOUT_ERROR: 'TIMEOUT_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  UNAUTHORIZED_ERROR: 'UNAUTHORIZED_ERROR',
  FORBIDDEN_ERROR: 'FORBIDDEN_ERROR',
  NOTFOUND_ERROR: 'NOTFOUND_ERROR',
  CONFLICT_ERROR: 'CONFLICT_ERROR',
};
