import { notificationsAtom } from 'atoms/notificationsAtom';
import { AxiosError, AxiosResponse } from 'axios';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { ALERT_SEVERITY as AlertSeverity } from 'utils/AlertSeverity';
import { HttpEvent } from '../../utils/HttpEvent';
import { IComponentResponseInterceptor } from './IComponentResponseInterceptor';

export class NotificationInterceptor implements IComponentResponseInterceptor {
  public handleResponse(
    response: AxiosResponse,
    component: string,
    requestType: string,
  ): AxiosResponse {
    const currentNotifications = getRecoil(notificationsAtom);
    const customEvent = new HttpEvent(
      AlertSeverity.SUCCESS,
      component,
      requestType,
    );
    setRecoil(notificationsAtom, [...currentNotifications, customEvent]);
    return response;
  }

  public handleError(
    error: AxiosError,
    component: string,
    requestType: string,
  ): Promise<never> {
    const currentNotifications = getRecoil(notificationsAtom);
    const customEvent = new HttpEvent(
      AlertSeverity.ERROR,
      component,
      requestType,
      error,
    );
    if (error?.response?.status === 401) return Promise.reject(error);
    setRecoil(notificationsAtom, [...currentNotifications, customEvent]);
    return Promise.reject(error);
  }
}
