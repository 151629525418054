import { Box, Typography, useTheme } from '@mui/material';
import { isAdminOrManagerSelector } from 'atoms/user';
import { LinkCustom } from 'components/common/LinkCustom/LinkCustom';
import { PageContainer } from 'components/common/PageContainer';
import ActionColumnGenerator from 'components/DataGridCustom/ActionColumnGenerator';
import columnGenerator from 'components/DataGridCustom/ColumnGenerator';
import DataGridCustom from 'components/DataGridCustom/DataGridCustom';
import { ADD, DEVICES, EDIT } from 'constants/routes';
import { concat } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import DeviceService from 'services/commons/DeviceService';

function Devices(): JSX.Element {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const isAdminOrManager = useRecoilValue<Boolean>(isAdminOrManagerSelector);
  const deviceService = new DeviceService();
  const {
    isLoading,
    data: devices,
    refetch: refetchDevices,
  } = useQuery('devices', () => deviceService.getAll().then((res) => res), {
    refetchOnMount: 'always',
  });

  const deleteClickHandler = async (itemId: number) => {
    await deviceService.delete(itemId);
    await refetchDevices();
  };

  let columns = [
    {
      ...columnGenerator('location', 'structure'),
      valueGetter: (value: any) => {
        return value.row.location?.locationName;
      },
    },
    columnGenerator('device', 'deviceName'),
    columnGenerator('device', 'iotNetwork'),
    {
      ...columnGenerator('device', 'lastMeasurementDate'),
      valueGetter: (value: any) => {
        if (value.row.lastMeasurementDate) {
          return moment(value.row.lastMeasurementDate)
            .locale(i18n.language)
            .format('llll');
        }
      },
    },
    {
      ...columnGenerator('device', 'lastMeasurementDurationDetails'),
      valueGetter: (value: any) => {
        if (value.row.lastMeasurementDurationDetails) {
          const { days, hours, minutes, seconds } =
            value.row.lastMeasurementDurationDetails;

          return `${t('time.days', { count: days })} ${t('time.hours', {
            count: hours,
          })} ${t('time.minutes', { count: minutes })} ${t('time.seconds', {
            count: seconds,
          })}`;
        }
      },
    },
    columnGenerator('device', 'nbrAlarms'),
  ];

  if (isAdminOrManager) {
    const columnAction = [...ActionColumnGenerator(deleteClickHandler)];
    columns = concat(columns, columnAction as any);
  }

  const sortModel = [{ field: 'deviceIdentifier', sort: 'asc' }];

  const filterModel = state?.parentLocationName
    ? [
        {
          field: 'structure',
          operator: 'contains',
          value: state.parentLocationName,
        },
      ]
    : [];

  const handleRowClick = (clickedRow: any) => {
    navigate(`${DEVICES}/${EDIT}/${clickedRow.id}`);
  };

  return (
    <PageContainer
      bgColor={theme.palette.background.default}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography color={'primary'} variant="h6">
            {t('navigationMenu.devices')}
          </Typography>
          {isAdminOrManager && (
            <LinkCustom to={`${DEVICES}/${ADD}`}>{t('device.add')}</LinkCustom>
          )}
        </Box>
      }
    >
      <DataGridCustom
        columns={columns}
        rows={devices || []}
        loading={isLoading}
        sortModel={sortModel}
        onRowClick={handleRowClick}
        filterModel={filterModel}
        getRowId={(row: any) => row.deviceIdentifier}
      />
    </PageContainer>
  );
}

export default Devices;
