import { Box, Typography, useTheme } from '@mui/material';
import { isAdminSelector } from 'atoms/user';
import { LinkCustom } from 'components/common/LinkCustom/LinkCustom';
import { PageContainer } from 'components/common/PageContainer';
import ActionColumnGenerator from 'components/DataGridCustom/ActionColumnGenerator';
import columnGenerator from 'components/DataGridCustom/ColumnGenerator';
import DataGridCustom from 'components/DataGridCustom/DataGridCustom';
import { LocationType } from 'constants/LocationType';
import { ADD, EDIT, SITES } from 'constants/routes';
import { concat } from 'lodash';
import { Site } from 'models/Site';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LocationRequest from 'services/commons/LocationRequest';
import { LocationService } from 'services/commons/LocationService';

function Sites() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useRecoilValue<Boolean>(isAdminSelector);
  const siteService = new LocationService<Site>();
  let locationRequest = new LocationRequest().withLocationType(
    LocationType.SITE,
  );
  const {
    isLoading,
    data: sites,
    refetch: refetchSites,
  } = useQuery(
    'sites',
    () =>
      siteService
        .getFilteredLocationRequest<Site>(locationRequest)
        .then((res) => res),
    { refetchOnMount: 'always' } as any,
  );

  const deleteClickHandler = async (itemId: number) => {
    await siteService.delete(itemId);
    await refetchSites();
  };

  let columns = [
    columnGenerator('location', 'locationName'),
    {
      ...columnGenerator('location', 'company'),
      valueGetter: (value?: any) => {
        return value?.row?.parentLocation?.locationName;
      },
    },
  ];
  if (isAdmin) {
    const columnAction = [...ActionColumnGenerator(deleteClickHandler)];
    columns = concat(columns, columnAction as any);
  }
  const sortModel = [{ field: 'locationName', sort: 'asc' }];

  const handleRowClick = (clickedRow: any) => {
    navigate(`${SITES}/${EDIT}/${clickedRow.id}`);
  };

  return (
    <PageContainer
      bgColor={theme.palette.background.default}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography color={'primary'} variant="h6">
            {t('navigationMenu.sites')}
          </Typography>
          {isAdmin && (
            <LinkCustom to={`${SITES}/${ADD}`}>{t('site.add')}</LinkCustom>
          )}
        </Box>
      }
    >
      <DataGridCustom
        columns={columns}
        rows={sites || []}
        loading={isLoading}
        sortModel={sortModel}
        onRowClick={handleRowClick}
      />
    </PageContainer>
  );
}

export default Sites;
