import { Card, CardContent, useTheme } from '@mui/material';

export const CustomCardContainerDisplay = ({ children, sx }: any) => {
  const theme = useTheme();

  return (
    <Card
      elevation={1}
      sx={{
        flex: 1,
        p: theme.spacing(1),
        ...sx,
      }}
    >
      <CardContent sx={{ p: '0px!important' }}>{children}</CardContent>
    </Card>
  );
};
