import {
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsPhoneNumber,
  IsString,
  IsUrl,
} from 'class-validator';
import { LocationType } from 'constants/LocationType';
import i18next from 'i18next';
import { Location } from './Location';

export class Company extends Location {
  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('company.identificationNumber'),
      }),
  })
  public identificationNumber: string;

  @IsString()
  @IsOptional()
  public companyCode: string;

  @IsString()
  @IsOptional()
  public legalForm: string;

  @IsPhoneNumber('FR')
  @IsOptional()
  public phoneNumber: string;

  @IsEmail()
  @IsOptional()
  public email: string;

  @IsUrl()
  @IsOptional()
  public website: string;

  static NewEmpty(): Company {
    let company = Location.newEmpty() as Company;
    company.locationType = LocationType.COMPANY;
    company.identificationNumber = '';
    company.companyCode = '';
    return company;
  }
}
