import { LocationType } from '../../constants/LocationType';
import { PagedRequest } from './PagedRequest';

export default class LocationRequest extends PagedRequest<LocationRequest> {
  locationType: LocationType | null = null;
  parentLocationId: number | null = null;
  companyWithIdentificationNumber: string | null = null;
  withinParentLocationId: number | null = null;

  withLocationType(locationType: LocationType) {
    this.locationType = locationType;
    return this;
  }

  withParentLocationId(parentLocationId: number) {
    this.parentLocationId = parentLocationId;
    return this;
  }

  withCompanyWithIdentificationNumber(identificationNumber: string) {
    this.companyWithIdentificationNumber = identificationNumber;
    return this;
  }

  withWithinParentLocationId(parentLocationId: number) {
    this.withinParentLocationId = parentLocationId;
    return this;
  }
}
