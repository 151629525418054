import { isNil } from 'lodash';

export enum OrderByDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export class PagedRequest<TPagedRequest extends PagedRequest<any>> {
  public static PAGE_NUMBER_DEFAULT = 0;
  public static PAGE_SIZE_DEFAULT = 20;

  pageNumber: number | null = null;
  pageSize: number | null = null;
  orderByProperty: string | null = null;
  orderByDirection: OrderByDirection | null = null;

  constructor(pagedRequest?: TPagedRequest) {
    Object.assign(this, pagedRequest);
  }

  public hasPreviousPage(): boolean {
    return !isNil(this.pageNumber) && this.pageNumber > 0;
  }

  public previousOrFirstPage(): TPagedRequest {
    return this.hasPreviousPage() ? this.previousPage() : this.firstPage();
  }

  public nextPage(): TPagedRequest {
    return this.cloneRequest().forPage(this.pageNumber! + 1, this.pageSize!);
  }

  public previousPage(): TPagedRequest {
    return this.pageNumber === 0
      ? this
      : this.cloneRequest().forPage(this.pageNumber! - 1, this.pageSize!);
  }

  public firstPage(): TPagedRequest {
    return this.cloneRequest().forPage(0, this.pageSize!);
  }

  public withPage(pageNumber: number): TPagedRequest {
    this.pageNumber = pageNumber;
    return this as unknown as TPagedRequest;
  }

  public withSortAsc(orderByProperty: string): TPagedRequest {
    this.orderByDirection = OrderByDirection.ASC;
    this.orderByProperty = orderByProperty;
    return this as unknown as TPagedRequest;
  }

  public withSortDesc(orderByProperty: string): TPagedRequest {
    this.orderByDirection = OrderByDirection.DESC;
    this.orderByProperty = orderByProperty;
    return this as unknown as TPagedRequest;
  }

  public forFirstPage(pageSize?: number): TPagedRequest {
    return this.forPage(
      PagedRequest.PAGE_NUMBER_DEFAULT,
      pageSize ? pageSize : PagedRequest.PAGE_SIZE_DEFAULT,
    );
  }

  public forPage(pageNumber: number, pageSize: number): TPagedRequest {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    return this as unknown as TPagedRequest;
  }

  public unpaged(): TPagedRequest {
    this.pageNumber = null;
    this.pageSize = null;
    return this as unknown as TPagedRequest;
  }

  get isPaged() {
    return !isNil(this.pageNumber) && !isNil(this.pageSize);
  }

  get isUnpaged(): boolean {
    return !this.isPaged;
  }

  protected cloneRequest(): TPagedRequest {
    return this.constructor(this);
  }
}
