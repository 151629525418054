import {
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import i18next from 'i18next';
import { LocationType } from '../constants/LocationType';
import { GenericDTO } from '../types/GenericDTO';
import { GeoLocation } from './GeoLocation';
import { LocationLink } from './LocationLink';

export class Location extends GenericDTO {
  public id: number;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('location.locationType'),
      }),
  })
  @IsEnum(Object.keys(LocationType))
  public locationType: LocationType;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('location.locationName'),
      }),
  })
  public locationName: string;

  @ValidateIf(
    (o) =>
      o.locationType === LocationType.SITE ||
      o.locationType === LocationType.STRUCTURE,
  )
  @ValidateNested({ each: true })
  public parentLocation: LocationLink;

  @ValidateNested({ each: true })
  public geoLocation: GeoLocation;

  @IsNumber()
  @IsOptional()
  public nbrAlarms: number | undefined;

  public toLocationLink = (): LocationLink => {
    let locationLink = new LocationLink();
    locationLink.id = this.id;
    locationLink.locationType = this.locationType;
    locationLink.locationName = this.locationName;

    return locationLink;
  };

  static newEmpty(): Location {
    let location = new Location();
    location.locationType = LocationType.SITE;
    location.locationName = '';
    location.parentLocation = new LocationLink();
    location.geoLocation = new GeoLocation();
    // location.identificationNumber = '';

    return location;
  }
}
