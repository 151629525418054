import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { isAdminSelector } from 'atoms/user';
import CommonInputCustom from 'components/common/CommonInputCustom/CommonInputCustom';
import GoBackButton from 'components/common/GoBackButton/GoBackButton';
import { PageContainer } from 'components/common/PageContainer';
import TextFieldCustom from 'components/common/TextFieldCustom/TextFieldCustom';
import LocationForm from 'components/LocationForm/LocationForm';
import { LocationType } from 'constants/LocationType';
import { SITES, STRUCTURES } from 'constants/routes';
import { Company } from 'models/Company';
import { Location } from 'models/Location';
import { LocationLink } from 'models/LocationLink';
import { Site } from 'models/Site';
import { useState } from 'react';
import { useValidation } from 'react-class-validator';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LocationRequest from 'services/commons/LocationRequest';
import { LocationService } from 'services/commons/LocationService';
import { generateBreadCrumbs } from '../../../utils/generateBreadCrumbs';

export default function SiteDetails(): JSX.Element {
  const { t } = useTranslation();
  const { id: siteId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const isAdmin = useRecoilValue<Boolean>(isAdminSelector);
  const siteService = new LocationService<Site>();
  const companyService = new LocationService<Company>();
  const companyRequest = new LocationRequest().withLocationType(
    LocationType.COMPANY,
  );
  const [siteState, setSiteState] = useState<Site>(Site.NewEmpty());
  const [validateForm, validationErrors] = useValidation(Site);
  const [validateFormLocationLink, validationErrorsLocationLink] =
    useValidation(LocationLink);

  const { data: companies } = useQuery('companies', () =>
    companyService
      .getFilteredLocationRequest<Location>(companyRequest)
      .then((res) => res.map((v: Location) => v.toLocationLink())),
  );

  const getCurrentSite = ({ queryKey }: any) => {
    const siteId = queryKey[1];
    siteService.getOne(siteId).then((site: Site) => {
      setSiteState(site);
      return site;
    });
  };

  useQuery({
    queryKey: ['currentSite', siteId],
    queryFn: getCurrentSite,
    cacheTime: 5,
    enabled: !!siteId,
  });

  const handleChange = (updatedPropertyObject: any) => {
    const newSiteObject = {
      ...siteState,
      ...updatedPropertyObject,
    };
    setSiteState(newSiteObject);
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    if (await validateFormLocationLink(siteState?.parentLocation)) {
      if (await validateForm(siteState)) {
        siteService.upsert(siteState).then(() => {
          navigate(SITES);
        });
      }
    }
  };

  const goToChildrenStructures = () => {
    navigate(STRUCTURES, {
      state: {
        parentLocationName: siteState.locationName,
      },
    });
  };

  const breadCrumbs = generateBreadCrumbs(
    !!siteId
      ? siteState
      : {
          locationName: t('navigationMenu.sites'),
        },
  );

  return (
    <PageContainer
      breadCrumbs={breadCrumbs}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {!!siteId
              ? t('navigationMenu.sitesDetails')
              : t('navigationMenu.addSite')}
          </Typography>
        </Box>
      }
    >
      <GoBackButton />
      <Container
        sx={{
          mt: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <CommonInputCustom
            editedObject={siteState}
            editedProperty={'code'}
            disabled={!isAdmin}
            labelBaseName={'site'}
            validateFunction={validateForm}
            errorsArray={validationErrors}
            updateFunction={handleChange}
          >
            <TextFieldCustom />
          </CommonInputCustom>
        </Grid>
        <LocationForm<Site>
          handleChange={handleChange}
          locationState={siteState}
          validationErrors={validationErrors}
          validateForm={validateForm}
          setLocationState={setSiteState}
          locationList={companies || []}
          validateFormLocationLink={validateFormLocationLink}
          validationErrorsLocationLink={validationErrorsLocationLink}
          forcedTranslationKey={"locationType.company"}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            mb: theme.spacing(2),
          }}
        >
          {siteState.id && (
            <Button
              sx={{ textTransform: 'none' }}
              variant="contained"
              type="submit"
              onClick={goToChildrenStructures}
            >
              {t('site.goToChildren')}
            </Button>
          )}
          {isAdmin && (
            <Button
              sx={{ textTransform: 'none', ml: theme.spacing(2) }}
              variant="contained"
              type="submit"
              onClick={submitForm}
            >
              {t('navigation.validate')}
            </Button>
          )}
        </Box>
      </Container>
    </PageContainer>
  );
}
