import moment from 'moment';
import { useTranslation } from 'react-i18next';
import columnGenerator from '../components/DataGridCustom/ColumnGenerator';

interface SortModel {
  field: string;
  sort: 'asc' | 'desc';
}

interface FilterModel {
  field: string;
  operator: string;
  value: any;
}

const useMeasurerTable = (
  state: any,
  isParcStatus: boolean = false,
  renderCellMethod?: any,
) => {
  const { t } = useTranslation();

  let columns: any = [
    {
      ...columnGenerator('measurer', 'device'),
      headerName: t('measurer.device'),
      valueGetter: (value: any) => {
        return value.row.device.deviceName;
      },
    },
    {
      ...columnGenerator('measurer', 'measurerCategory'),
      headerName: t('measurer.measurerCategory'),
      valueGetter: (value: any) => {
        return t(
          `measurerCategory.${value.row.measurerCategory.toLowerCase()}`,
        );
      },
    },
    {
      ...columnGenerator('measurer', 'measurementType'),
      headerName: t('measurer.measurementType'),
      valueGetter: (value: any) => {
        return t(`measurementType.${value.row.measurementType.toLowerCase()}`);
      },
    },

    {
      ...columnGenerator('measurer', 'alarmState'),
      headerName: t('measurer.alarmState'),
      ...(isParcStatus
        ? {
            renderCell: (params: any) => renderCellMethod(params),
          }
        : {
            valueGetter: (value: any) => {
              return t(`alarmState.${value.row.alarmState.toLowerCase()}`);
            },
          }),
    },

    {
      ...columnGenerator('measurer', 'lastAlarmDate'),
      headerName: t('measurer.lastAlarmDate'),
      valueGetter: (value: any) => {
        return value.row.lastAlarmDate
          ? moment(value.row.lastAlarmDate).format('DD/MM/YYYY HH:mm')
          : null;
      },
    },
  ];

  const sortModel: SortModel[] = [{ field: 'deviceIdentifier', sort: 'asc' }];
  const filterModel: FilterModel[] = state?.deviceIdentifier
    ? [{ field: 'device', operator: 'contains', value: state.deviceIdentifier }]
    : [];

  // Other logic or side effects can be added here

  return {
    columns,
    sortModel,
    filterModel,
  };
};

export default useMeasurerTable;
