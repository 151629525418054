import moment from 'moment';
import { MeasurementType } from '../constants/MeasurementType';
import { MeasurementUnit } from '../constants/MeasurementUnit';
import { GenericDTO } from '../types/GenericDTO';

export default class Measurement extends GenericDTO {
  public measurerId: number;

  public deviceIdentifier: string;

  public measurementType: MeasurementType;

  public measurementDate: string;

  public measurerValue: Number;

  public measurementUnit: MeasurementUnit;

  get measurementDateToNumber() {
    return this.measurementMoment.toDate().getTime();
  }

  get measurementMoment() {
    return moment(this.measurementDate);
  }
}
