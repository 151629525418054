import { Box, Typography, useTheme } from '@mui/material';
import { isAdminSelector } from 'atoms/user';
import ActionColumnGenerator from 'components/DataGridCustom/ActionColumnGenerator';
import columnGenerator from 'components/DataGridCustom/ColumnGenerator';
import DataGridCustom from 'components/DataGridCustom/DataGridCustom';
import { LinkCustom } from 'components/common/LinkCustom/LinkCustom';
import { PageContainer } from 'components/common/PageContainer';
import { ADD, EDIT, USERS } from 'constants/routes';
import { concat } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import UserService from 'services/commons/UserService';

export default function Users(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userService = new UserService();

  const isAdmin = useRecoilValue<Boolean>(isAdminSelector);

  const theme = useTheme();
  const {
    isLoading,
    data: users,
    refetch: refetchUsers,
  } = useQuery('users', () => userService.getAll().then((res) => res), {
    refetchOnMount: 'always',
  } as any);

  const formatDate = (date: Moment) => {
    return date ? moment(date).format('DD/MM/YYYY') : '';
  };

  const deleteClickHandler = async (itemId: number) => {
    await userService.delete(itemId);
    await refetchUsers();
  };

  let columns = [
    columnGenerator('user', 'firstName'),
    columnGenerator('user', 'lastName'),
    columnGenerator('user', 'email'),
    {
      ...columnGenerator('user', 'company'),
      valueGetter: (value?: any) => {
        return value?.row.company?.locationName;
      },
    },
    {
      ...columnGenerator('user', 'createdDate'),
      valueGetter: (value?: any) => formatDate(value),
    },
    {
      ...columnGenerator('user', 'lastModifiedDate'),
      valueGetter: (value?: any) => formatDate(value),
    },
    {
      ...columnGenerator('user', 'activated'),
      valueGetter: (value?: any) => {
        return value?.row.activated
          ? t('user.activatedState.true')
          : t('user.activatedState.false');
      },
    },
  ];

  if (isAdmin) {
    const columnAction = [...ActionColumnGenerator(deleteClickHandler)];
    columns = concat(columns, columnAction as any);
  }

  const sortModel = [{ field: 'lastName', sort: 'asc' }];

  const handleRowClick = (clickedRow: any) => {
    navigate(`${USERS}/${EDIT}/${clickedRow.id}`);
  };

  return (
    <PageContainer
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {t('navigationMenu.users')}
          </Typography>
          <LinkCustom to={`${USERS}/${ADD}`}>{t('user.add')}</LinkCustom>
        </Box>
      }
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} />
      <DataGridCustom
        columns={columns}
        rows={users || []}
        loading={isLoading}
        sortModel={sortModel}
        onRowClick={handleRowClick}
      />
    </PageContainer>
  );
}
