import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';

export default function CommonConfirmationDialog({
  open,
  handleValidate,
  handleClose,
  children,
}: {
  open: boolean;
  handleValidate: () => void;
  handleClose: () => void;
  children: any;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleCloseDialog = (
    event: React.SyntheticEvent,
    reason: CloseReason,
  ) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  const validate = async () => {
    handleValidate();
  };

  return (
    <Dialog fullWidth open={open}>
      <DialogContent sx={{ p: theme.spacing(1) }}>
        <CardContent
          sx={{
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
            }}
            color={'primary'}
            onClick={(event) => handleCloseDialog(event, 'closeButtonClick')}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>

          <Typography
            align="center"
            sx={{ mb: theme.spacing(3), fontWeight: 'bold' }}
          >
            {children}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pr: theme.spacing(1),
              pl: theme.spacing(1),
            }}
          >
            <Grid item sx={{ pt: '8px!important' }}>
              <Button onClick={handleClose}>{t('shared.no')}</Button>
            </Grid>
            <Grid item sx={{ pt: '8px!important' }}>
              <Button onClick={validate}>{t('shared.yes')}</Button>
            </Grid>
          </Grid>
        </CardActions>
      </DialogContent>
    </Dialog>
  );
}
