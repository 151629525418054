import i18n from 'i18next';

export default function columnGenerator(
  itemName: string,
  fieldName: string,
  flex: number = 2,
  minWidth: number = 75,
  sortable: boolean = true,
) {
  return {
    flex,
    minWidth,
    field: fieldName,
    headerName: i18n.t(`${itemName}.${fieldName}`),
    description: i18n.t(`${itemName}.${fieldName}`),
    sortable: sortable,
  };
}
