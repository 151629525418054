export default class GenericData {
  fieldType: string;
  fieldValue: number | null;

  static newEmpty(): GenericData {
    let genericData = new GenericData();
    genericData.fieldType = '';
    genericData.fieldValue = null;

    return genericData;
  }
}
