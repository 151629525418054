import { ALARM_TRIGGER } from 'constants/endPoint';
import AlarmTrigger from 'models/AlarmTrigger';
import AlarmTriggerRequest from 'services/commons/AlarmTriggerRequest';
import { REQUEST_TYPE } from 'utils/RequestType';
import { CRUDService } from './CRUDService';

export default class AlarmTriggerService extends CRUDService<AlarmTrigger> {
  constructor() {
    super(ALARM_TRIGGER);
  }
  newInstance(): AlarmTrigger {
    return new AlarmTrigger();
  }

  async getAllByMeasurerId(
    alarmTriggerRequest: AlarmTriggerRequest,
  ): Promise<AlarmTrigger[]> {
    return this.castToInstanceArray(
      this.decorateWithInterceptor(
        this.apiInstance.get(`${this.endPoint}`, {
          params: alarmTriggerRequest,
        }),
      ),
    );
  }

  async createWithParameters(
    payload: AlarmTrigger,
    alarmTriggerRequest: AlarmTriggerRequest,
  ): Promise<AlarmTrigger> {
    return this.castToInstance(
      this.decorateWithInterceptor(
        this.apiInstance.post(`${this.endPoint}`, payload, {
          params: alarmTriggerRequest,
        }),
        REQUEST_TYPE.CREATE,
      ),
    );
  }

  async updateWithParameters(
    payload: AlarmTrigger,
    alarmTriggerRequest: AlarmTriggerRequest,
  ): Promise<AlarmTrigger> {
    return this.castToInstance(
      this.decorateWithInterceptor(
        this.apiInstance.put(`${this.endPoint}/${payload.id}`, payload, {
          params: alarmTriggerRequest,
        }),
        REQUEST_TYPE.UPDATE,
      ),
    );
  }

  async upsertWithParameters(
    payload: AlarmTrigger,
    alarmTriggerRequest: AlarmTriggerRequest,
  ): Promise<AlarmTrigger> {
    if (payload.id) {
      return this.updateWithParameters(payload, alarmTriggerRequest);
    } else {
      return this.createWithParameters(payload, alarmTriggerRequest);
    }
  }
}
