import {
  InputAdornment,
  InputBaseComponentProps,
  TextField,
} from '@mui/material';
import { InputProps } from '@mui/material/Input/Input';
import { get } from 'lodash';
import { useMemo } from 'react';

interface PropsTextFieldCustom {
  multiline?: boolean;
  rows?: number;
  type?: any;
  inputRef?: any;
  errors?: any;
  name?: string;
  value?: any;
  label?: string;
  onChange?: any;
  onBlur?: any;
  prefix?: any;
  step?: number;
  readOnly?: boolean;
}

export default function TextFieldCustom(
  props: PropsTextFieldCustom,
): JSX.Element {
  const { inputRef, type, errors, name, prefix, step, readOnly } = props;

  const helperText: string = useMemo(() => {
    if (name) return get(errors, name, '');
    return '';
  }, [name, errors]);

  const inputProps: Partial<InputProps> = useMemo(() => {
    let tmpInputProps: Partial<InputProps> = {
      readOnly,
      startAdornment: (
        <InputAdornment position="start">{prefix}</InputAdornment>
      ),
    };
    return tmpInputProps;
  }, [prefix]);

  const domInputProps: InputBaseComponentProps | undefined = useMemo(() => {
    return type === 'number' ? { step } : undefined;
  }, [step, type]);

  return (
    <TextField
      {...props}
      fullWidth
      size={'small'}
      variant={'outlined'}
      id="outlined-helperText"
      type={type ? type : 'string'}
      inputRef={inputRef}
      helperText={helperText}
      InputProps={inputProps}
      inputProps={domInputProps}
    />
  );
}
