import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { measurersConfigurationPending } from 'atoms/measurersConfigurationPending';
import { isAdminOrManagerSelector } from 'atoms/user';
import CommonInputCustom from 'components/common/CommonInputCustom/CommonInputCustom';
import GoBackButton from 'components/common/GoBackButton/GoBackButton';
import { PageContainer } from 'components/common/PageContainer';
import SelectCustom from 'components/common/SelectCustom/SelectCustom';
import { EDIT, MEASURERS } from 'constants/routes';
import { isEmpty } from 'lodash';
import Device from 'models/Device';
import DeviceLink from 'models/DeviceLink';
import Measurer from 'models/Measurer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DeviceRequest from 'services/commons/DeviceRequest';
import DeviceService from 'services/commons/DeviceService';
import { currentConfiguredDevice } from '../../../atoms/currentConfiguredDevice';

export default function MeasurerCreation(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isAdminOrManager = useRecoilValue<Boolean>(isAdminOrManagerSelector);
  const setMeasurersConfigurationPending = useSetRecoilState<Measurer[]>(
    measurersConfigurationPending,
  );
  const currentConfiguredDeviceState = useRecoilValue<Device | null>(
    currentConfiguredDevice,
  );
  const deviceService = new DeviceService();
  const deviceRequest = new DeviceRequest();
  const [measurerState, setMeasurerState] = useState<Measurer>(
    Measurer.newEmpty,
  );
  const [availablePackagesState, setAvailablePackagesState] = useState<
    string[]
  >([]);
  const [selectedPackageState, setSelectedPackageState] = useState<any>();

  const gotoMeasurers = useCallback(() => {
    navigate(`${MEASURERS}`);
  }, [navigate]);

  const { data: deviceList } = useQuery(
    'devices',
    () =>
      deviceService
        .getAll()
        .then((res) => res.map((v: Device) => v.toDeviceLink())),
    { refetchOnMount: 'always' },
  );

  const handleChangeDevice = (updatedPropertyObject: any) => {
    const device = updatedPropertyObject;
    const newMeasurer: Measurer = Measurer.newEmpty();
    setMeasurerState({
      ...newMeasurer,
      ...device,
    });
    setSelectedPackageState('');
    const deviceId = device.device.id;
    getAvailablePackages(deviceId);
  };

  useEffect(() => {
    if (!deviceList) return;
    if (currentConfiguredDeviceState == null) return;
    if (deviceList.length === 0) return;
    const targetDevice = deviceList.find(
      (item) => item.id === currentConfiguredDeviceState.id,
    );
    if (!targetDevice) return;
    handleChangeDevice({ device: targetDevice });
  }, [currentConfiguredDeviceState, deviceList]);

  const handleChangePackage = (updatedPropertyObject: any) => {
    const measurerPackage = updatedPropertyObject;
    setSelectedPackageState(measurerPackage);
    deviceRequest.measurersPackage = measurerPackage.package;
    const newMeasurer: Measurer = Measurer.newEmpty();
    newMeasurer.device = measurerState.device;
    setMeasurerState(newMeasurer);
    preparePackage(deviceRequest);
  };

  const getAvailablePackages = (deviceId: number) => {
    deviceService
      .getAvailableMeasurersPackages(deviceId)
      .then((availablePackages) => {
        setAvailablePackagesState(availablePackages);
      });
  };

  const preparePackage = (deviceRequest: DeviceRequest) => {
    deviceService.getPrepareMeasurersPackage(
      measurerState.device.id,
      deviceRequest,
    );
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    const deviceIdentifier = measurerState.device.id;
    const packageSelected = selectedPackageState.package;
    deviceService
      .installPackage(deviceIdentifier, packageSelected)
      .then((response) => {
        const measurers = response?.data?.measurers;
        if (!measurers) gotoMeasurers();
        else if (!measurers.length) gotoMeasurers();
        else {
          const configurableMeasurers = measurers.filter(
            (measurer: Measurer) => {
              return !isEmpty(measurer.specificParameters);
            },
          );
          if (!configurableMeasurers.length) gotoMeasurers();
          else {
            setMeasurersConfigurationPending(configurableMeasurers);
            navigate(`${MEASURERS}/${EDIT}/${configurableMeasurers[0].id}`);
          }
        }
      });
  };

  const canSelectDevice: boolean = useMemo<boolean>(() => {
    //return true;
    return currentConfiguredDeviceState === null;
  }, [currentConfiguredDeviceState]);

  return (
    <PageContainer
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography color={'primary'} variant="h6">
            {t('navigationMenu.measurers')}
          </Typography>
        </Box>
      }
    >
      <GoBackButton />
      <Container
        sx={{
          mt: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container sx={{ mb: theme.spacing(2) }}>
          {!canSelectDevice && (
            <Grid item xs={12}>
              <p>
                <h4>{t('measurer.explanation1')}</h4>
              </p>
              <p>
                <h4>{t('measurer.explanation2')}</h4>
              </p>
            </Grid>
          )}
          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <CommonInputCustom
              editedObject={measurerState}
              editedProperty={'device'}
              labelBaseName={'measurer'}
              updateFunction={handleChangeDevice}
              disabled={!isAdminOrManager || !canSelectDevice}
            >
              <SelectCustom<DeviceLink>
                values={deviceList as DeviceLink[]}
                valueToLabel={(device: DeviceLink) => device.deviceName}
                valueToId={(device: DeviceLink) => device.deviceIdentifier}
              />
            </CommonInputCustom>
          </Grid>
          <Grid item xs={6}>
            <CommonInputCustom
              disabled={!measurerState?.device?.id}
              editedObject={selectedPackageState}
              editedProperty={'package'}
              labelBaseName={'measurer'}
              updateFunction={handleChangePackage}
            >
              <SelectCustom
                values={availablePackagesState || []}
                valueToId={(item: string) => item}
                valueToLabel={(item: string) =>
                  t(`measurer.packageName.${item.toLowerCase()}`)
                }
              />
            </CommonInputCustom>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            mb: theme.spacing(2),
          }}
        >
          {isAdminOrManager && (
            <Button
              sx={{ textTransform: 'none', ml: theme.spacing(2) }}
              variant="contained"
              type="submit"
              onClick={submitForm}
            >
              {t('import')}
            </Button>
          )}
        </Box>
      </Container>
    </PageContainer>
  );
}
