import { Container, useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {
  DataGridPremium,
  esES,
  frFR,
  gridClasses,
  GridColumnVisibilityModel,
  GridEventListener,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import { SCREEN_HEIGHT } from 'constants/ScreenHeight';
import useScreenHeight from 'hooks/useScreenHeight';
import { useState,useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

export default function DataGridCustom({
  columns,
  rows,
  loading,
  sortModel,
  onRowClick,
  filterModel,
  getRowId,
  columnVisibilityModel,
  autoHeight = false,
  fullWidth = false,
  noDataText = undefined,
}: {
  columns: any;
  rows: any;
  loading?: boolean;
  sortModel?: any;
  onRowClick?: any;
  filterModel?: any;
  getRowId?: any;
  columnVisibilityModel?: any;
  fullWidth?: boolean;
  autoHeight?: boolean;
  noDataText?: string;
}): JSX.Element {
  const theme = useTheme();
  const [muiTableKey] = useState(0);
  const screenHeight = useScreenHeight();
  const { i18n } = useTranslation();
  const usedLanguage = i18n.language;

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    onRowClick(params.row);
  };

  const [customColumnVisibilityModel, setCustomColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(columnVisibilityModel);


  const handleDataGridTexts = useMemo(()=> {
    let baseLanguage = {};
    if(usedLanguage === 'fr') {
      baseLanguage = frFR.components.MuiDataGrid.defaultProps.localeText;
    } else if(usedLanguage === 'es'){
      baseLanguage = esES.components.MuiDataGrid.defaultProps.localeText;
    }
    if(noDataText){
      baseLanguage = {...baseLanguage,noRowsLabel: noDataText  }
    }
    return baseLanguage;
  }, [noDataText, usedLanguage])

  return (
    <Container
      sx={{
        maxWidth: 'unset!important',
        p: fullWidth ? '0!important' : `${theme.spacing(2)}!important`,
        pt: `0px!important`,
        height: screenHeight - SCREEN_HEIGHT.HEADER,
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
      }}
    >
      <StripedDataGrid
        localeText={
          handleDataGridTexts
        }
        componentsProps={{
          pagination: {
            labelRowsPerPage: 'rrrrr',
          },
        }}
        key={muiTableKey}
        rows={rows}
        rowHeight={30}
        columnHeaderHeight={35}
        columns={columns}
        loading={loading}
        getRowId={getRowId}
        columnVisibilityModel={customColumnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setCustomColumnVisibilityModel(newModel)
        }
        onRowClick={handleRowClick}
        initialState={{
          sorting: {
            sortModel: sortModel,
          },
          filter: {
            filterModel: {
              items: filterModel || [],
            },
          },
        }}
        components={{
          Toolbar: GridToolbar,
        }}
        getRowClassName={(params: any) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        hideFooter
        hideFooterPagination
        hideFooterSelectedRowCount
        {...(autoHeight && { autoHeight })}
      />
    </Container>
  );
}
