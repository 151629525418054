export const graphLineAlarm = 0xff0000;

export const colorPalette = [
  '#8181ff',
  '#171da9',
  '#537bff',
  '#92c3fd',
  '#0d4688',
  '#014791',
  '#a0c4e7',
  '#00a1ff',
  '#09b4ff',
  '#84c8ef',
];
