import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import { IResponseInterceptor } from './IResponseInterceptor';

class APIServiceManager {
  apiService: AxiosInstance = axios.create();

  configure(
    config: CreateAxiosDefaults,
    authInterceptor: IResponseInterceptor | null = null,
  ) {
    this.apiService = axios.create(config);
    if (authInterceptor) {
      this.apiService.interceptors.response.use(
        (response) => authInterceptor.handleResponse(response),
        (error) => authInterceptor.handleError(error),
      );
    }
  }

  get instance(): AxiosInstance {
    return this.apiService;
  }
}

export const apiServiceManager: APIServiceManager = new APIServiceManager();
