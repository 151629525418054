import { AxiosResponse } from 'axios';
import {
  ACTIVATE_SYNCHRONISE_DEVICE,
  AVAILABLE_DEVICES,
  AVAILABLE_MEASURERS_PACKAGES,
  DEVICE,
  IMPORT_DEVICE,
  INSTALL_MEASURERS_PACKAGES,
  PREPARE_MEASURERS_PACKAGES,
  REBOOT_DEVICE,
} from 'constants/endPoint';
import filter from 'lodash/filter';
import get from 'lodash/get';
import Device from 'models/Device';
import Measurer from 'models/Measurer';
import DeviceRequest from 'services/commons/DeviceRequest';
import { CRUDService } from './CRUDService';

export default class DeviceService extends CRUDService<Device> {
  constructor() {
    super(DEVICE);
  }

  newInstance(): Device {
    return new Device();
  }

  createMeasurerFromPackage(data: any): Measurer {
    const objectInstance = new Measurer();
    return Object.assign(objectInstance, data);
  }

  async castToStringArray(result: Promise<AxiosResponse>): Promise<string[]> {
    const data = await get(await result, 'data', []);
    return data;
  }

  async castToMeasurerArray(
    result: Promise<AxiosResponse>,
  ): Promise<Measurer[]> {
    const data = await get(await result, 'data.measurers', []);
    const measurers = data.map((item: Measurer) =>
      this.createMeasurerFromPackage(item),
    );
    return filter(measurers, ['id', null]);
  }

  async getAvailableMeasurersPackages(deviceId: number): Promise<string[]> {
    return this.castToStringArray(
      this.decorateWithInterceptor(
        this.apiInstance.get(
          `${this.endPoint}/${deviceId}/${AVAILABLE_MEASURERS_PACKAGES}`,
        ),
      ),
    );
  }

  async getPrepareMeasurersPackage(
    deviceId: number,
    deviceRequest: DeviceRequest,
  ): Promise<Measurer[]> {
    return this.castToMeasurerArray(
      this.decorateWithInterceptor(
        this.apiInstance.get(
          `${this.endPoint}/${deviceId}/${PREPARE_MEASURERS_PACKAGES}`,
          { params: deviceRequest },
        ),
      ),
    );
  }

  async getAvailableDevices(phoneNumberSearch: string): Promise<string[]> {
    return this.castToStringArray(
      this.decorateWithInterceptor(
        this.apiInstance.get(`${this.endPoint}/${AVAILABLE_DEVICES}`, {
          params: { likeGprsAddress: phoneNumberSearch },
        }),
      ),
    );
  }

  async installPackage(
    deviceId: number,
    measurerPackage: string,
  ): Promise<any> {
    return await this.decorateWithInterceptor(
      this.apiInstance.put(
        `${this.endPoint}/${deviceId}/${INSTALL_MEASURERS_PACKAGES}?measurersPackage=${measurerPackage}`,
      ),
    );
  }

  async rebootDevice(deviceId: number): Promise<void> {
    await this.decorateWithInterceptor(
      this.apiInstance.get(`${this.endPoint}/${deviceId}/${REBOOT_DEVICE}`),
    );
  }

  async synchronizeDevice(deviceId: number): Promise<void> {
    await this.decorateWithInterceptor(
      this.apiInstance.put(
        `${this.endPoint}/${deviceId}/${ACTIVATE_SYNCHRONISE_DEVICE}`,
      ),
    );
  }

  async importDevice(gprsAddress: string): Promise<Device> {
    return await this.castToInstance(
      this.decorateWithInterceptor(
        this.apiInstance.post(
          `${this.endPoint}/${IMPORT_DEVICE}/${gprsAddress}`,
        ),
      ),
    );
  }
}
