import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

export default function DatePickerCustom({
  value,
  label,
  onChange,
  ...props
}: any): JSX.Element {
  const { t } = useTranslation();

  return (
    <DatePicker
      {...props}
      value={value}
      label={t(label)}
      onChange={onChange}
      disableFuture={true}
      componentsProps={{
        textField: {
          size: 'small',
          placeholder: t('datePlaceholder'),
        },
      }}
    />
  );
}
