import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { AUTHORITIES, USER } from '../../constants/endPoint';
import User from '../../models/User';
import { CRUDService } from './CRUDService';

export default class UserService extends CRUDService<User> {
  constructor() {
    super(USER);
  }

  newInstance(): User {
    return new User();
  }

  async castToArray(result: Promise<AxiosResponse>): Promise<string[]> {
    let dataObjectList = await get(await result, 'data', []);
    return dataObjectList;
  }

  async getAuthorities(): Promise<any> {
    return this.castToArray(
      this.decorateWithInterceptor(
        this.apiInstance.get(`${this.endPoint}/${AUTHORITIES}`),
      ),
    );
  }
}
