import { IsEnum, IsNotEmpty } from 'class-validator';
import { MeasurementType } from 'constants/MeasurementType';
import { MeasurementUnit } from 'constants/MeasurementUnit';
import i18next from 'i18next';
import { GenericDTO } from 'types/GenericDTO';

export default class MeasurerLink extends GenericDTO {
  @IsNotEmpty({
    message: () => i18next.t('validator.selectMeasurer'),
  })
  public id: number;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('measurer.measurementType'),
      }),
  })
  @IsEnum(Object.keys(MeasurementType), {
    message: () => i18next.t('validator.enum'),
  })
  public measurementType: MeasurementType;

  public deviceIdentifier: string;

  public deviceName: string;

  @IsNotEmpty({
    message: () =>
      i18next.t('validator.empty', {
        param: i18next.t('measurer.specificParameters'),
      }),
  })
  public measurementDataType: string;

  public measurerLabel: string;

  public measurementUnit: MeasurementUnit;
}
