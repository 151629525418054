export enum Periodicity {
  FOREVER = 'FOREVER',
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  HOUR = 'HOUR',
  DAYOFWEEK = 'DAYOFWEEK',
  HOUROFDAY = 'HOUROFDAY',
}
