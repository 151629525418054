import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { SCREEN_HEIGHT } from '../../../../constants/ScreenHeight';

interface AppBarCustomProps {
  scroll?: number;
  children?: React.ReactNode;
}

export default function AppBarCustom({ scroll, children }: AppBarCustomProps) {
  return (
    <AppBar
      elevation={scroll === 0 ? 0 : 1}
      position="sticky"
      sx={{ backgroundColor: '#f5f5f5' }}
    >
      <Container
        disableGutters
        sx={{ display: 'flex', margin: 0, maxWidth: 'unset!important' }}
      >
        <Toolbar
          disableGutters
          sx={{
            width: '100%',
            minHeight: 'unset!important',
            height: `${SCREEN_HEIGHT.HEADER}px!important`,
          }}
        >
          {children}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
